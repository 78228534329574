import React, { useState, useEffect } from "react";
import Header from "./Header";
import SideMenu from "./SideMenu";
import Modal from "react-modal";
import { useNavigate, useLocation, json } from "react-router-dom";
import { format, parseISO, parse } from 'date-fns';
import Select from 'react-select';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { validate } from 'rut.js';
import './App.css';

Modal.setAppElement("#root");

function Dashboard() {
  const location = useLocation();
  const [dataLogin, setDataLogin] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [isSecondModalOpen, setSecondModalOpen] = useState(false);
  const [secondModalContent, setSecondModalContent] = useState(null);
  const [UFmensual, setUFmensual] = useState(null);
  const [indicadorUF, setIndicadorUF] = useState(null);
  const [diasFestivos, setDiasFestivos] = useState([]);
  const QRCode = require('qrcode');

  const generarQR = async (url) => {
    try {
      return await QRCode.toDataURL(url);
    } catch (err) {
      console.error('Error generando código QR', err);
    }
  }

  const insertarQR = async (pdfDoc, qrImageUrl, x, y, width, height) => {
    const qrImage = await pdfDoc.embedPng(qrImageUrl);
    const page = pdfDoc.getPage(0); // Asumiendo que quieres agregarlo en la primera página
    page.drawImage(qrImage, {
      x: x,
      y: y,
      width: width,
      height: height
    });
  }

  useEffect(() => {
    if (location.state) {
      const receivedData = location.state;
      setDataLogin(receivedData); // Aquí tienes los datos enviados desde Login

      obtenerIndicadorUF();
      obtenerDiasFestivos();
      obtenerRequerimientos();
      obtenerCotizaciones();
      obtenerEstadosPagoBH();
      obtenerEstadosPagoGH();
      obtenerEstadosPagoGT();
      obtenerEstadosPagoEstado();
    }
  }, [location]);

  //Para ocupar en inputs de fechas
  const today = new Date();

  const [requerimientosData, setRequerimientosData] = useState([]);
  const obtenerRequerimientos = async () => {
    try {
      const response = await fetch(
        `https://vittamaq.qa-gtalent.cl/api/requerimiento.php`
      );
      const data = await response.json();

      //alert(JSON.stringify(data));

      if (data/*  && data[0].id_requerimiento */) {
        setRequerimientosData(data);
      }
    } catch (error) {
      setRequerimientosData([]);
    }
  };

  const [cotizacionesData, setCotizacionesData] = useState([]);
  const obtenerCotizaciones = async () => {
    try {
      const response = await fetch(
        `https://vittamaq.qa-gtalent.cl/api/cabecera_cotizacion.php`
      );

      const data = await response.json();

      if (data && data[0].folio) {
        setCotizacionesData(data);
      }
    } catch (error) {
      setCotizacionesData([]);
    }
  };

  const [estadosPagoBHData, setEstadosPagoBHData] = useState([]);
  const obtenerEstadosPagoBH = async () => {
    try {
      const response = await fetch(
        `https://vittamaq.qa-gtalent.cl/api/estado_pago_bh.php`
      );
      const data = await response.json();

      if (data && data[0].id_estado_pago_bh) {
        setEstadosPagoBHData(data);
      }
    } catch (error) {
      setEstadosPagoBHData([]);
    }
  };

  const [estadosPagoGHData, setEstadosPagoGHData] = useState([]);
  const obtenerEstadosPagoGH = async () => {
    try {
      const response = await fetch(
        `https://vittamaq.qa-gtalent.cl/api/estado_pago_gh.php`
      );
      const data = await response.json();

      if (data && data[0].id_estado_pago_gh) {
        setEstadosPagoGHData(data);
      }
    } catch (error) {
      setEstadosPagoGHData([]);
    }
  };

  const [estadosPagoGTData, setEstadosPagoGTData] = useState([]);
  const obtenerEstadosPagoGT = async () => {
    try {
      const response = await fetch(
        `https://vittamaq.qa-gtalent.cl/api/estado_pago_gt.php`
      );
      const data = await response.json();

      if (data && data[0].id_estado_pago_gt) {
        setEstadosPagoGTData(data);
      }
    } catch (error) {
      setEstadosPagoGTData([]);
    }
  };

  const obtenerIndicadorUF = async () => {
    try {
      const response = await fetch('https://mindicador.cl/api/uf');
      const data = await response.json();
      
      if (data && data.serie && data.serie.length > 0) {
        const { serie } = data;

        setUFmensual(data);
  
        const fechaActual = new Date().toLocaleDateString('es-CL');
  
        const fechaActualIndicadorUF = serie.find(item => {
          const itemDate = new Date(item.fecha).toLocaleDateString('es-CL');
          return itemDate === fechaActual;
        });
  
        if (fechaActualIndicadorUF) {
          //console.log("Valor para la fecha actual:", fechaActualIndicadorUF.valor);
          setIndicadorUF(fechaActualIndicadorUF.valor);
        } else {
          //console.log("No se encontró el valor para la fecha actual.");
          setIndicadorUF(null);
        }
      } else {
        //console.log("No se encontraron datos válidos.");
        setIndicadorUF(null);
      }
    } catch (error) {
      //console.error("Error al obtener el indicador UF:", error);
      setIndicadorUF(null);
    }
  };

  const obtenerDiasFestivos = async () => {
    try {
      //const currentYear = new Date().getFullYear();

      const response = await fetch(`https://vittamaq.qa-gtalent.cl/api/obtieneFestivos.php`);
      const data = await response.json();
      
      if (data && data.length > 0) {
        setDiasFestivos(data);
      } else {
        setDiasFestivos([]);
      }
    } catch (error) {
      setDiasFestivos([]);
    }
  };

  //Obtener estado de estados de pago
  const [estadosPagoEstado, setEstadosPagoEstado] = useState([]);
  const obtenerEstadosPagoEstado = async () => {
    try {
      const response = await fetch(
        `https://vittamaq.qa-gtalent.cl/api/estado_pago_estado.php`
      );
      const data = await response.json();

      if (data && data[0].id_estado_pago_estado) {
        setEstadosPagoEstado(data);
      }
    } catch (error) {
      setEstadosPagoEstado([]);
    }
  };

  const handleCardClick = (columnTitle, cardData) => {
    setModalOpen(true);
    switch (columnTitle) {
      case "Requerimientos":
        setModalContent(<RequerimientoModalContent data={cardData} />);
        break;
      case "Cotización":
        setModalContent(<CotizacionModalContent data={cardData} />);
        break;
      case "Estado de pago BH":
        setModalContent(<EstadoPagoBHModalContent data={cardData} />);
        break;
      case "Estado de pago GH":
        setModalContent(<EstadoPagoGHModalContent data={cardData} />);
        break;
      default:
        setModalContent(<EstadoPagoGTModalContent data={cardData} />);
    }
  };

  const handleAddButtonClick = (columnTitle) => {
    setModalOpen(true);
    switch (columnTitle) {
      case "Requerimientos":
        setModalContent(<AgregarRequerimientoModalContent />);
        break;
      case "Cotización":
        setModalContent(<AgregarCotizacionModalContent />);
        break;
      case "Estado de Pago BH":
        setModalContent(<AgregarEstadoPagoModalContent />);
        break;
      case "Estado de Pago GH":
        setModalContent(<AgregarEstadoPagoGHModalContent />);
        break;
      default:
        setModalContent(<AgregarEstadoPagoGTModalContent />);
    }
  };

  const handleEditButtonClick = (columnTitle, data, detalle) => {
    setModalOpen(true);
    switch (columnTitle) {
      case "Requerimientos":
        const datosRequerimiento = data;
        const visitasTecnicasRequerimiento = detalle;

        setModalContent(<EditarRequerimientoModalContent datos={datosRequerimiento} visitas={visitasTecnicasRequerimiento} />);
        break;
      case "Cotización":
        const cabeceraCotizacion = data;
        const serviciosCotizacion = detalle;
       
        setModalContent(<EditarCotizacionModalContent cabecera={cabeceraCotizacion} servicios={serviciosCotizacion} />);
        break;
    }
  };

  /* Anular Requerimiento */
  const handleAnularRequerimiento = (idRequerimiento, nombreRequerimiento) => {
    setSecondModalOpen(true);
    setSecondModalContent(<ConfirmaAnularRequerimientoModalContent id_requerimiento={idRequerimiento} nombre_requerimiento={nombreRequerimiento} />);
  };

  const handleImagesButtonClick = (data, id_visita) => {
    setModalOpen(true);
    setModalContent(<ImagenesVisitaTecnicaModalContent datos={data} visita={id_visita} />);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalContent(null);
  };

  const closeSecondModal = () => {
    setSecondModalOpen(false);
    setSecondModalContent(null);
  };

  //Búsquedas
  const [filtroRequerimiento, setFiltroRequerimiento] = useState('');
  const [filtroCotizacion, setFiltroCotizacion] = useState('');
  const [filtroEstadoPago, setFiltroEstadoPago] = useState('');

  const handleFiltrosDashboard = (event, title) => {
    switch (title) {
      case 'Requerimientos':
        setFiltroRequerimiento(event.target.value);
        break;
      case 'Cotización':
        setFiltroCotizacion(event.target.value);
        break;
      default:
        setFiltroEstadoPago(event.target.value);
    }
  };

  const requerimientosFiltrados = requerimientosData.filter((item) =>
    item.nombre_cliente.toLowerCase().includes(filtroRequerimiento.toLowerCase())
  );
  const cotizacionesFiltradas = cotizacionesData.filter((item) =>
    item.nombre_cliente.toLowerCase().includes(filtroCotizacion.toLowerCase())
  );
  /* const estadosPagos = estadosPagoBHData.concat(estadosPagoGHData);
  const estadosPagoFiltrados = estadosPagos.filter((item) =>
    item.id_obra.toLowerCase().includes(filtroEstadoPago.toLowerCase())
  ); */
  //console.log(JSON.stringify(estadosPagoFiltrados,null,2))

  const data = [
    {
      title: "Requerimientos",
      color: "#e53a13",
      cards: requerimientosFiltrados
    },
    {
      title: "Cotización",
      color: "#d986f8",
      cards: cotizacionesFiltradas
    },
    {
      title: "Estado de pago",
      color: "#00a7b5",
      cards: [{
        bh: estadosPagoBHData,
        gh: estadosPagoGHData,
        gt: estadosPagoGTData
      }]
    },
  ];

  //console.log(JSON.stringify(estadosPagoGTData,null,2))

  const formatearRut = (rut) => {
    const rutFormateado = `${rut.slice(0, 2)}.${rut.slice(2, 5)}.${rut.slice(5)}`;
    return rutFormateado;
  };

  function AgregarRequerimientoModalContent() {
    const [mostrarTablaVT, setMostrarTablaVT] = useState(true);
    const [clientes, setClientes] = useState([]);
    const [clienteSelected, setClienteSelected] = useState('');
    const [obras, setObras] = useState([]);
    const [obraSelected, setObraSelected] = useState('');
    const [contactos, setContactos] = useState([]);
    const [mostrarBotonVT, setMostrarBotonVT] = useState(true);
    const navigate = useNavigate();

    const handleRadioChange = (event) => {
      setMostrarTablaVT(event.target.value === "1");

      setMostrarBotonVT(!mostrarBotonVT);

      const nuevaFila = { ...filaPredeterminada, id: 1 };
      setFilas([nuevaFila]);

      setFormRequerimientoData({
        ...formRequerimientoData,
        ["requiere_visita_tecnica"]: event.target.value,
      });
    }

    const obtenerClientes = async () => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/cliente.php`
        );
        const data = await response.json();

        //alert(JSON.stringify(data));
  
        if (data && data[0].id_cliente) {
          setClientes(data);
        }
      } catch (error) {
        setClientes([]);
      }
    };
  
    useEffect(() => {
      if (location.state) {
        const receivedData = location.state;
        setDataLogin(receivedData);
        obtenerClientes();
        //obtenerIdRequerimiento();
      }
    }, [location]);

    const handleClienteSelect = async (clienteSelected) => {
      setClienteSelected(clienteSelected.value);
      await obtenerObras(clienteSelected.value);
      await obtenerContactos(clienteSelected.value);

      let cliente = clienteSelected.value.toString();

      setFormRequerimientoData({
        ...formRequerimientoData,
        ["id_cliente"]: cliente,
      });
    };

    const handleObraSelect = async (e) => {
      setObraSelected(e.target.value);

      setFormRequerimientoData({
        ...formRequerimientoData,
        ["id_obra"]: e.target.value,
      });
    };

    const obtenerObras = async (cliente) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/cliente_obra.php?id_cliente=${cliente}`
        );
        const data = await response.json();
  
        if (data && data[0].id_obra) {
          setObras(data);
        }
      } catch (error) {
        setObras([]);
      }
    };

    const obtenerContactos = async (cliente) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/contacto_2.php?id_cliente=${cliente}`
        );
        const data = await response.json();
  
        if (data && data[0].id_contacto) {
          setContactos(data);
        }
      } catch (error) {
        setContactos([]);
      }
    };

    const optionsClientes = clientes.map((cliente) => ({
      value: cliente.id_cliente,
      label: cliente.nombre_cliente,
    }));

    useEffect(() => {
      setFilas([filaPredeterminada]);
      setFilasImagenes([filaPredeterminadaImagenes]);
    }, []);
    
    const filaPredeterminada = { 
      id: 1, 
      id_requerimiento: '',
      fecha: '', 
      texto: '', 
      id_usuario: dataLogin.id_usuario.toString()
    };
    const [filas, setFilas] = useState([filaPredeterminada]);
    
    const filaPredeterminadaImagenes = { 
      id: 1, 
      id_visita: '',
      id_requerimiento: '',
      imagenes: [],
      id_usuario: dataLogin.id_usuario.toString()
    };
    const [filasImagenes, setFilasImagenes] = useState([filaPredeterminadaImagenes]);

    const handleAgregarFila = () => {
      const nuevaFila = {
        id: filas.length + 1,
        id_requerimiento: '',
        fecha: '',
        texto: '',
        id_usuario: dataLogin.id_usuario.toString()
      };
  
      setFilas([...filas, nuevaFila]);

      const nuevaFilaImagenes = { 
        id: filasImagenes.length + 1, 
        id_visita: '',
        id_requerimiento: '',
        imagenes: [],
        id_usuario: dataLogin.id_usuario.toString()
      };

      setFilasImagenes([...filasImagenes, nuevaFilaImagenes]);
    };

    const handleInputChange = (id, campo, valor) => {
      const nuevasFilas = filas.map((fila) =>
        fila.id === id ? { ...fila, [campo]: valor } : fila
      );
  
      setFilas(nuevasFilas);
    };
    
    const [base64Strings, setBase64Strings] = useState([]);
    const handleFileChange = (event, id) => {
      const files = event.target.files;
      Promise.all(
        Array.from(files).map((file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
  
            reader.onload = () => {
              const fileType = file.type;
              if (fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'image/jpg') {
                resolve(reader.result);
              } else {
                reject(new Error('El archivo debe ser PNG o JPG.'));
              }
            };
  
            reader.onerror = (error) => {
              reject(error);
            };
          });
        })
      )
        .then((base64Array) => {
          setBase64Strings(base64Array);

          const nuevasFilasImagenes = filasImagenes.map((fila) =>
            fila.id === id ? { ...fila, imagenes: base64Array } : fila
          );
          setFilasImagenes(nuevasFilasImagenes);

        })
        .catch((error) => {
          //console.error('Error al convertir archivos a base64:', error);
          event.target.value = "";
          alert('El archivo debe ser PNG o JPG.');
        });
    };

    const handleEliminarFila = (id) => {
      // Verificar que haya más de una fila antes de eliminar
      if (filas.length > 1) {
        const nuevasFilas = filas.filter((fila) => fila.id !== id);
        setFilas(nuevasFilas);
      } else {
        alert("Debe agregar al menos una visita técnica, de lo contrario seleccione 'No'.");
      }
    };

    const [formRequerimientoData, setFormRequerimientoData] = useState({
      id_cliente: "",
      id_obra: "",
      id_contacto: "",
      fecha_requerimiento: "",
      nombre_requerimiento: "",
      detalle: "",
      requiere_visita_tecnica: "1",
      id_usuario: dataLogin.id_usuario.toString()
    });

    //console.log(JSON.stringify(formRequerimientoData,null,2))

    const handleFormRequerimientoData = (e) => {
      setFormRequerimientoData({
        ...formRequerimientoData,
        [e.target.name]: e.target.value,
      });
    };

    const handleSubmitRequerimiento = async (event) => {
      event.preventDefault();

      //console.log(JSON.stringify(filasImagenes,null,2));

      try {
        const esFormularioValido = validaFormularioRequerimiento();

        if (esFormularioValido) {
          const responseRequerimiento = await fetch(
            `https://vittamaq.qa-gtalent.cl/api/requerimiento.php`,
            {
              method: "POST",
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json', 
              },
              body: JSON.stringify(formRequerimientoData),
            }
          );
  
          const dataRequerimiento = await responseRequerimiento.json();
  
          if (dataRequerimiento.message === "Requerimiento agregado con éxito.") {

            if (mostrarBotonVT) {
              const id_requerimiento = dataRequerimiento.id_requerimiento;
              const filasModificadas = filas.map(objeto => ({
                ...objeto,
                id_requerimiento: id_requerimiento
              }));
  
              const responseVisitasTecnicas = await fetch(
                `https://vittamaq.qa-gtalent.cl/api/visita_tecnica.php`,
                {
                  method: "POST",
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json', 
                  },
                  body: JSON.stringify(filasModificadas),
                }
              );
  
              const dataVisitasTecnicas = await responseVisitasTecnicas.json();
              if (dataVisitasTecnicas.message === "Visita técnica agregada con éxito.") {
  
                filasImagenes.map(objeto => {
                  const idVisita = dataVisitasTecnicas.ids_visitas[objeto.id.toString()];
                  objeto.id_requerimiento = id_requerimiento;
                  if (idVisita) {
                      objeto.id_visita = idVisita;
                  }
                  return objeto;
                });
    
                const responseVisitasTecnicasImagenes = await fetch(
                  `https://vittamaq.qa-gtalent.cl/api/imagenes.php`,
                  {
                    method: "POST",
                    headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json', 
                    },
                    body: JSON.stringify(filasImagenes),
                  }
                );
    
                const dataVisitasTecnicasImagenes = await responseVisitasTecnicasImagenes.json();
    
                //console.log(JSON.stringify(dataVisitasTecnicasImagenes));
    
                if (dataVisitasTecnicasImagenes.message === "Imagenes de visita técnica agregadas con éxito.") {
                  setFormRequerimientoData({
                    id_requerimiento: "",
                    id_cliente: "",
                    id_obra: "",
                    id_contacto: "",
                    fecha_requerimiento: "",
                    nombre_requerimiento: "",
                    detalle: "",
                    requiere_visita_tecnica: "1",
                    id_usuario: dataLogin.id_usuario.toString()
                  });
        
                  setFilas(filaPredeterminada);
    
                  setFilasImagenes(filaPredeterminadaImagenes);
      
                  setBase64Strings(0);
        
                  alert("Requerimiento agregado con éxito.");
  
                  closeModal();
      
                  navigate("/dashboard", { state: dataLogin });
                }
              }
            } else {
              setFormRequerimientoData({
                id_requerimiento: "",
                id_cliente: "",
                id_obra: "",
                id_contacto: "",
                fecha_requerimiento: "",
                nombre_requerimiento: "",
                detalle: "",
                requiere_visita_tecnica: "1",
                id_usuario: dataLogin.id_usuario.toString()
              });
    
              setFilas(filaPredeterminada);

              setFilasImagenes(filaPredeterminadaImagenes);
  
              setBase64Strings(0);
    
              alert("Requerimiento agregado con éxito.");

              closeModal();
  
              navigate("/dashboard", { state: dataLogin });
            }
          }
        }
      } catch (error) {
        alert("Error al agregar el requerimiento" /* + JSON.stringify(error) */);
      }
    };

    const validaFormularioRequerimiento = () => {

      if (mostrarTablaVT) {
        //console.log(JSON.stringify(filas, null, 2))
        const valido = filas.map((fila) => {
          if (fila.fecha === "" || fila.texto === "") {
            //alert(`La fila con id ${fila.id} tiene campos vacíos`);
            alert("Debe ingresar la fecha y detalle de las visitas técnicas");
            return false;
          }
          return true;
        }).every(v => v);
      
        if (!valido) {
          return false;
        }
      }

      if (formRequerimientoData.id_cliente === "") {
        alert('Debe seleccionar un cliente');
        return false;
      }
  
      if (formRequerimientoData.id_obra === "") {
        alert('Debe seleccionar una obra');
        return false;
      }

      if (formRequerimientoData.fecha_requerimiento === "") {
        alert('Debe indicar la fecha del requerimiento');
        return false;
      }
  
      if (formRequerimientoData.detalle === "") {
        alert('Debe ingresar el detalle del requerimiento');
        return false;
      }
  
      return true;
    }

    return (
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Agregar Requerimiento</h4>
            <button onClick={closeModal} className="btn-close"></button>
          </div>
          <div className="modal-body py-3 px-5">
            <div className="row row-cols-1 row-cols-md-2 g-4 mb-3">
              {clientes ? (
                <div className="col">
                  <label className="form-label">Cliente</label>
                  <Select
                    name="id_cliente" 
                    value={clienteSelected.value} 
                    onChange={handleClienteSelect}
                    options={optionsClientes}
                    isSearchable
                    className="react-select"
                    placeholder="Seleccione un cliente"
                  />
                </div>
                ) : (
                <div className="col">
                  <label className="form-label">Cliente</label>
                  <select className="form-select" name="cliente_req">
                    <option value="">No se encontraron clientes</option>
                  </select>
                </div>
              )}
              {obras ? (
                <div className="col">
                  <label className="form-label">Obra</label>
                  <select className="form-select" name="id_obra" value={obraSelected || formRequerimientoData.id_obra} onChange={handleObraSelect}>
                    <option value="">Seleccione una obra</option>
                    {obras.map((obra) => (
                      <option key={obra.id_obra} value={obra.id_obra}>{obra.nombre}</option>
                    ))}
                  </select>
                </div>
                ) : (
                <div className="col">
                  <label className="form-label">Obra</label>
                  <select className="form-select" name="obra_req">
                    <option value="">Cliente sin obras asociadas</option>
                  </select>
                </div>
              )}
            </div>
            <div className="row row-cols-1 row-cols-md-2 g-4 mb-3">
              <div className="col">
                <label className="form-label">Solicitante (Contacto)</label>
                {contactos ? (
                  <select className="form-select" name="id_contacto" value={formRequerimientoData.id_contacto} onChange={handleFormRequerimientoData}>
                    <option value="">Seleccione Solicitante</option>
                    {contactos.map((contacto) => (
                      <option key={contacto.id_contacto} value={contacto.id_contacto}>{contacto.nombres + " " + contacto.apellido_paterno + " " + contacto.apellido_materno}</option>
                    ))}
                  </select>
                  ) : (
                    <select className="form-select" name="id_contacto">
                      <option value="">Cliente sin contactos asociados</option>
                    </select>
                )}
              </div>
              <div className="col">
                <label className="form-label">Fecha Requerimiento</label>
                <input
                  className="form-control"
                  type="date"
                  name="fecha_requerimiento"
                  value={formRequerimientoData.fecha_requerimiento}
                  max={today.toISOString().split('T')[0]}
                  onChange={handleFormRequerimientoData}
                />
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label">Nombre Requerimiento</label>
              <input
                className="form-control"
                type="text"
                name="nombre_requerimiento"
                value={formRequerimientoData.nombre_requerimiento}
                onChange={handleFormRequerimientoData}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Detalle Requerimiento</label>
              <textarea
                className="form-control"
                name="detalle"
                type="textarea"
                rows="2"
                value={formRequerimientoData.detalle}
                onChange={handleFormRequerimientoData}
              />
            </div>
            <label className="form-label d-block">¿Requiere visita técnica?</label>
            <div className="d-flex justify-content-md-between">
              <div>
                <input
                  type="radio" 
                  className="btn-check" 
                  name="visita_tecnica_req" 
                  id="visita-tecnica-si-req" 
                  autoComplete="off" 
                  value="1"
                  checked={mostrarTablaVT}
                  onChange={handleRadioChange}
                />
                <label className="btn" htmlFor="visita-tecnica-si-req">Si</label>
                <input 
                  type="radio" 
                  className="btn-check" 
                  name="visita_tecnica_req" 
                  id="visita-tecnica-no-req" 
                  autoComplete="off"
                  value="0"
                  checked={!mostrarTablaVT}
                  onChange={handleRadioChange}
                />
                <label className="btn" htmlFor="visita-tecnica-no-req">No</label>
              </div>
              {mostrarBotonVT && (
                <button type="button" className="btn btn-outline-dark btn-sm" onClick={handleAgregarFila}>
                  <i className="bi-plus-circle"></i> Agregar
                </button>
              )}
            </div>
            {mostrarTablaVT && (
              <div className="table-responsive border rounded mt-3">
                <table className="table mb-0">
                  <thead>
                    <tr className="table-active border-bottom">
                      <th className="col-2">Fecha</th>
                      <th className="col-5">Detalle</th>
                      <th className="col-4">Documento</th>
                      <th className="col-1"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {filas.map((fila) => (
                      <tr key={fila.id}>
                        <td className="col-2">
                          <input
                            className="form-control form-control-sm"
                            type="date"
                            value={fila.fecha}
                            onChange={(e) =>
                              handleInputChange(fila.id, 'fecha', e.target.value)
                            }
                          />
                        </td>
                        <td className="col-5">
                          <input
                            className="form-control form-control-sm"
                            type="text"
                            value={fila.texto}
                            onChange={(e) =>
                              handleInputChange(fila.id, 'texto', e.target.value)
                            }
                          />
                        </td>
                        <td className="col-4">
                          <input
                            className="form-control form-control-sm"
                            type="file"
                            multiple
                            onChange={(e) =>
                              handleFileChange(e, fila.id)
                            }
                          />
                        </td>
                        <td className="col-1">
                          <button className="btn btn-outline-danger btn-sm" onClick={() => handleEliminarFila(fila.id)}>
                            <i className="bi bi-trash3"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div className="modal-footer flex-row">
            <button className="btn btn-yellow" onClick={handleSubmitRequerimiento}><i className="bi bi-floppy"></i> Guardar</button>
          </div>
        </div>
      </div>
    );
  }

  function EditarRequerimientoModalContent({ datos, visitas }) {
    const [obras, setObras] = useState([]);
    const [contactos, setContactos] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
      obtenerObras(datos.id_cliente);
      obtenerContactos(datos.id_cliente);
    }, []);

    const obtenerObras = async (id_cliente) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/cliente_obra.php?id_cliente=${id_cliente}`
        );
        const data = await response.json();
  
        if (data && data[0].id_obra) {
          setObras(data);
        }
      } catch (error) {
        setObras([]);
      }
    };

    const obtenerContactos = async (id_cliente) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/contacto_2.php?id_cliente=${id_cliente}`
        );
        const data = await response.json();
  
        if (data && data[0].id_contacto) {
          setContactos(data);
        }
      } catch (error) {
        setContactos([]);
      }
    };

    const fechaReqBD = datos.fecha_requerimiento;
    const fechaRequerimiento = format(parse(fechaReqBD, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd');

    const [formRequerimientoEditData, setFormRequerimientoEditData] = useState({
      id_requerimiento: datos.id_requerimiento || "",
      id_cliente_edit: datos.id_cliente || "",
      id_obra_edit: datos.id_obra || "",
      id_contacto_edit: datos.id_contacto || "",
      fecha_requerimiento_edit: fechaRequerimiento || "",
      nombre_requerimiento_edit: datos.nombre_requerimiento || "",
      detalle_edit: datos.detalle || ""
    });

    const handleFormRequerimientoEditData = (e) => {
      setFormRequerimientoEditData({
        ...formRequerimientoEditData,
        [e.target.name]: e.target.value,
      });
    };

    const [filas, setFilas] = useState([]);

    const filaPredeterminadaImagenes = { 
      id: 1, 
      id_visita: '',
      id_requerimiento: datos.id_requerimiento,
      imagenes: [],
      id_usuario: dataLogin.id_usuario.toString()
    };
    const [filasImagenes, setFilasImagenes] = useState([filaPredeterminadaImagenes]);
    
    const handleAgregarFila = () => {
      const nuevaFila = {
        id: filas.length + 1,
        id_requerimiento: datos.id_requerimiento,
        fecha: '',
        texto: '',
        id_usuario: dataLogin.id_usuario.toString()
      };
    
      setFilas([...filas, nuevaFila]);

      const nuevaFilaImagenes = { 
        id: filasImagenes.length + 1, 
        id_visita: '',
        id_requerimiento: datos.id_requerimiento,
        imagenes: [],
        id_usuario: dataLogin.id_usuario.toString()
      };

      setFilasImagenes([...filasImagenes, nuevaFilaImagenes]);
    };

    const handleInputChange = (id, campo, valor) => {
      const nuevasFilas = filas.map((fila) =>
        fila.id === id ? { ...fila, [campo]: valor } : fila
      );
  
      setFilas(nuevasFilas);
    };

    const [base64Strings, setBase64Strings] = useState([]);
    const handleFileChange = (event, id) => {
      const files = event.target.files;

      const extensionesPermitidas = ['jpg', 'jpeg', 'png'];

      Promise.all(
        Array.from(files).map((file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
  
            reader.onload = () => {
              const fileType = file.type;
              if (fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'image/jpg') {
                resolve(reader.result);
              } else {
                reject(new Error('El archivo debe ser PNG o JPG.'));
              }
            };
  
            reader.onerror = (error) => {
              reject(error);
            };
          });
        })
      )
        .then((base64Array) => {
          setBase64Strings(base64Array);

          const nuevasFilasImagenes = filasImagenes.map((fila) =>
            fila.id === id ? { ...fila, imagenes: base64Array } : fila
          );
          setFilasImagenes(nuevasFilasImagenes);

        })
        .catch((error) => {
          //console.error('Error al convertir archivos a base64:', error);
          event.target.value = "";
          alert('El archivo debe ser PNG o JPG.');
        });
    };

    const handleEliminarFila = (id) => {
      const nuevasFilas = filas.filter((fila) => fila.id !== id);
      setFilas(nuevasFilas);
    };

    const handleSubmitEditRequerimiento = async (event) => {
      event.preventDefault();

      try {
        const esFormularioValido = validaFormularioRequerimiento();

        if (esFormularioValido) {
          const responseEditRequerimientoCotizacion = await fetch(
            `https://vittamaq.qa-gtalent.cl/api/requerimiento.php`,
            {
              method: "PUT",
              headers: {
                'Accept': 'application/json', // Indica que esperas una respuesta JSON
                'Content-Type': 'application/json', 
              },
              body: JSON.stringify(formRequerimientoEditData),
            }
          );
  
          const dataEditRequerimientoCotizacion = await responseEditRequerimientoCotizacion.json();
          if (dataEditRequerimientoCotizacion.message === "Requerimiento modificado con éxito.") {
            //console.log(JSON.stringify(filas,null,2));
            //console.log(JSON.stringify(filasImagenes,null,2));

            const responseVisitasTecnicas = await fetch(
              `https://vittamaq.qa-gtalent.cl/api/visita_tecnica.php`,
              {
                method: "POST",
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json', 
                },
                body: JSON.stringify(filas),
              }
            );

            const dataVisitasTecnicas = await responseVisitasTecnicas.json();
            if (dataVisitasTecnicas.message === "Visita técnica agregada con éxito.") {
              filasImagenes.map(objeto => {
                const idVisita = dataVisitasTecnicas.ids_visitas[objeto.id.toString()];
                if (idVisita) {
                    objeto.id_visita = idVisita;
                }
                return objeto;
              });

              const responseVisitasTecnicasImagenes = await fetch(
                `https://vittamaq.qa-gtalent.cl/api/imagenes.php`,
                {
                  method: "POST",
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json', 
                  },
                  body: JSON.stringify(filasImagenes),
                }
              );
  
              const dataVisitasTecnicasImagenes = await responseVisitasTecnicasImagenes.json();
              if (dataVisitasTecnicasImagenes.message === "Imagenes de visita técnica agregadas con éxito.") {
                setFormRequerimientoEditData({
                  id_requerimiento: "",
                  id_cliente_edit: "",
                  id_obra_edit: "",
                  id_contacto_edit: "",
                  fecha_requerimiento_edit: "",
                  nombre_requerimiento_edit: "",
                  detalle_edit: ""
                });

                setFilas([]);
  
                setFilasImagenes(filaPredeterminadaImagenes);
    
                setBase64Strings(0);
      
                alert("Requerimiento modificado con éxito.");
    
                closeModal();
      
                navigate("/dashboard", { state: dataLogin });
              }
            }
          } else {
            throw new Error();
          }
        }
      } catch (error) {
        alert("Error al editar el requerimiento.");
      }
    }

    const validaFormularioRequerimiento = () => {
      if (formRequerimientoEditData.id_cliente_edit === "") {
        alert('Debe seleccionar un cliente');
        return false;
      }
  
      if (formRequerimientoEditData.id_obra_edit === "") {
        alert('Debe seleccionar una obra');
        return false;
      }

      if (formRequerimientoEditData.fecha_requerimiento_edit === "") {
        alert('Debe indicar la fecha del requerimiento');
        return false;
      }
  
      if (formRequerimientoEditData.detalle_edit === "") {
        alert('Debe ingresar el detalle del requerimiento');
        return false;
      }
  
      return true;
    }

    return (
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Editar Requerimiento</h4>
            <button onClick={closeModal} className="btn-close"></button>
          </div>
          <div className="modal-body py-3 px-5">
            <div className="row row-cols-1 row-cols-md-2 mb-3">
              <div className="col">
                <span className="form-label d-block mb-1">Cliente</span>
                <select className="form-select" name="id_cliente_edit" disabled>
                  <option value={formRequerimientoEditData.id_cliente}>{datos.nombre_cliente || ""}</option>
                </select>
              </div>
              <div className="col">
                <span className="form-label d-block mb-1">Obra</span>
                <select className="form-select" name="id_obra_edit" value={formRequerimientoEditData.id_obra_edit} onChange={handleFormRequerimientoEditData}>
                  {obras ? (
                    obras.map((obra, index) => (
                      <option key={index} value={obra.id_obra}>{obra.nombre}</option>
                    ))
                  ) : (
                    <option value="">No se encontraron obras asociadas al cliente</option>
                  )}
                </select>
              </div>
            </div>
            <div className="row row-cols-1 row-cols-md-2 mb-3">
              <div className="col">
                <span className="form-label d-block mb-1">Contacto</span>
                <select className="form-select" name="id_contacto_edit" value={formRequerimientoEditData.id_contacto_edit} onChange={handleFormRequerimientoEditData}>
                  {contactos ? (
                    contactos.map((contacto, index) => (
                      <option key={index} value={contacto.id_contacto}>{contacto.nombres + " " + contacto.apellido_paterno + " " + contacto.apellido_materno}</option>
                    ))
                  ) : (
                    <option value="">No se encontraron contactos asociadas al cliente</option>
                  )}
                </select>
              </div>
              <div className="col">
                <span className="form-label d-block mb-1">Fecha</span>
                <input 
                  className="form-control" 
                  type="date" 
                  name="fecha_requerimiento_edit" 
                  value={formRequerimientoEditData.fecha_requerimiento_edit}
                  onChange={handleFormRequerimientoEditData}
                />
              </div>
            </div>
            <div className="mb-3">
              <span className="form-label d-block mb-1">Nombre Requerimiento</span>
              <input 
                className="form-control" 
                type="text" 
                name="nombre_requerimiento_edit" 
                value={formRequerimientoEditData.nombre_requerimiento_edit}
                onChange={handleFormRequerimientoEditData}
              />
            </div>
            <div className="mb-4">
              <span className="form-label d-block mb-1">Detalle Requerimiento</span>
              <textarea 
                className="form-control"
                name="detalle_edit" 
                type="textarea"
                rows="2"
                value={formRequerimientoEditData.detalle_edit}
                onChange={handleFormRequerimientoEditData}>
              </textarea>
            </div>
            <div className="d-flex justify-content-md-between mb-3">
              <h5 className="mb-0">Vistas Técnicas</h5>
              <button className="btn btn-outline-dark btn-sm" onClick={handleAgregarFila}><i className="bi bi-plus-circle"></i> Agregar</button>
            </div>
            <div className="table-responsive border rounded">
              <table className="table mb-0">
                <thead>
                  <tr className="table-active border-bottom">
                    <th className="col-2">Fecha</th>
                    <th className="col-5">Detalle</th>
                    <th className="col-4">Documento</th>
                    <th className="col-1"></th>
                  </tr>
                </thead>
                <tbody>
                  {visitas ? (
                    visitas.map((visita, index) => (
                      <tr key={visita.id_visita}>
                        <td className="col-2 ps-2">
                          <input 
                            className="form-control form-control-sm"
                            type="date"
                            value={format(parse(visita.fecha_visita, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd')}
                            disabled
                          />
                        </td>
                        <td className="col-5">
                          <input
                            className="form-control form-control-sm"
                            type="textarea"
                            rows="2"
                            value={visita.detalle}
                            disabled
                          />
                        </td>
                        <td className="col-4">
                          <button className="btn btn-outline-primary btn-sm" onClick={() => handleImagesButtonClick(datos, visita.id_visita)}><i className="bi bi-images"></i> Ver Imágenes</button>
                        </td>
                        <td></td>
                      </tr>
                    ))
                  ) : (
                      <tr>
                        <td className="text-center ps-1" colSpan={4}>No se encontraron visitas técnicas asociadas</td>
                      </tr>
                  )}
                  {filas.map((fila) => (
                    <tr key={fila.id}>
                      <td className="col-2 ps-2">
                        <input
                          className="form-control form-control-sm"
                          type="date"
                          value={fila.fecha}
                          onChange={(e) =>
                            handleInputChange(fila.id, 'fecha', e.target.value)
                          }
                        />
                      </td>
                      <td className="col-5">
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          value={fila.texto}
                          onChange={(e) =>
                            handleInputChange(fila.id, 'texto', e.target.value)
                          }
                        />
                      </td>
                      <td className="col-4">
                        <input
                          className="form-control form-control-sm"
                          type="file"
                          multiple
                          onChange={(e) =>
                            handleFileChange(e, fila.id)
                          }
                        />
                      </td>
                      <td className="col-1 pe-2">
                        <button className="btn btn-outline-danger btn-sm" onClick={() => handleEliminarFila(fila.id)}>
                          <i className="bi bi-trash3"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer flex-row">
            <button className="btn btn-yellow" onClick={handleSubmitEditRequerimiento}><i className="bi bi-floppy"></i> Guardar</button>
          </div>
        </div>
      </div>
    );
  }

  function RequerimientoModalContent({ data }) {
    const [visitasTecnicas, setvisitasTecnicas] = useState([]);

    useEffect(() => {
      if (location.state) {
        const receivedData = location.state;
        setDataLogin(receivedData); 
        obtenerVisitasTecnicas(data.id_requerimiento);
      }
    }, [location]);

    const obtenerVisitasTecnicas = async (id_requerimiento) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/visita_tecnica.php?id_requerimiento=${id_requerimiento}`
        );
        const data = await response.json();
  
        if (data && data[0].id_requerimiento) {
          setvisitasTecnicas(data);
        }
      } catch (error) {
        setvisitasTecnicas([]);
      }
    };

    return (
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Detalle Requerimiento</h4>
            <button onClick={closeModal} className="btn-close"></button>
          </div>
          <div className="modal-body">
            <div className="row row-cols-lg-2">
              <div className="col">
                <ul className="list-group mb-4">
                  <li className="list-group-item d-flex justify-content-between align-items-center gap-2">
                    <span className="form-label mb-0">Nombre Requerimiento</span>
                    <span className="label text-end">{data.nombre_requerimiento || ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center gap-2">
                    <span className="form-label mb-0">Cliente</span>
                    <span className="label text-end">{data.nombre_cliente || ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center gap-2">
                    <span className="form-label mb-0">Rut</span>
                    <span className="label text-end">{formatearRut(data.rut_cliente) || ""}</span>
                  </li>
                </ul>
              </div>
              <div className="col">
                <ul className="list-group mb-4">
                  <li className="list-group-item d-flex justify-content-between align-items-center gap-2">
                    <span className="form-label mb-0">Obra</span>
                    <span className="label text-end">{data.nombre_obra || ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center gap-2">
                    <span className="form-label mb-0">Contacto</span>
                    <span className="label text-end">{data.nombre_contacto || ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center gap-2">
                    <span className="form-label mb-0">Fecha</span>
                    <span className="label text-end">{data.fecha_requerimiento || ""}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="mb-4">
              <h5 className="mb-3">Detalle</h5>
              <div className="bg-white border rounded fw-bold py-2 px-3">{data.detalle || ""}</div>
            </div>
            <h5 className="mb-3">Visitas Tecnicas</h5>
            <div className="table-responsive border rounded">
              <table className="table table-hover mb-0">
                <thead>
                  <tr className="table-active border-bottom">
                    <th scope="col" className="ps-3">Fecha</th>
                    <th scope="col">Detalle</th>
                    <th scope="col" className="pe-3">Imagen</th>
                  </tr>
                </thead>
                <tbody>
                  {visitasTecnicas.length > 0 ? (
                    visitasTecnicas.map((visitaTecnica, index) => (
                      <tr key={index}>
                        <td className="ps-3">{visitaTecnica.fecha_visita || ""}</td>
                        <td>{visitaTecnica.detalle}</td>
                        <td className="pe-3">
                          <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => handleImagesButtonClick(data, visitaTecnica.id_visita)}><i className="bi bi-images"></i> Ver Imágenes</button>
                        </td>
                      </tr>
                    ))
                  ) : (
                      <tr>
                        <td className="text-center ps-1" colSpan={3}>No se encontraron visitas técnicas asociadas</td>
                      </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-outline-danger" onClick={(e) => handleAnularRequerimiento(data.id_requerimiento, data.nombre_requerimiento)}><i className="bi bi-dash-circle"></i> Anular</button>
            <button className="btn btn-dark" onClick={() => handleEditButtonClick("Requerimientos", data, visitasTecnicas)}><i className="bi bi-pencil-square"></i> Editar</button>
          </div>
        </div>
      </div>
    );
  }

  function ImagenesVisitaTecnicaModalContent({ datos, visita }) {
    const [imagenesVisita, setImagenesVisita] = useState([]);

    useEffect(() => {
      obtenerImagenesVisita(visita);
    }, []);

    const obtenerImagenesVisita = async (visita) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/imagenes.php?visita=${visita}`
        );
        const data = await response.json();
  
        if (data && data[0].id_visita) {
          setImagenesVisita(data);
        }
      } catch (error) {
        setImagenesVisita([]);
      }
    };

    const [activeIndex, setActiveIndex] = useState(0);

    const handleSlideChange = (selectedIndex) => {
      setActiveIndex(selectedIndex);
    };

    return (
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Visita Técnica: {datos.detalle}</h4>
            <button onClick={closeModal} className="btn-close"></button>
          </div>
          <div className="modal-body">
            <div id="myCarousel" className="carousel carousel-dark slide carousel-fade" data-bs-ride="carousel">
              <div className="carousel-inner">
                {imagenesVisita ? (
                  imagenesVisita.map((imagenBase64, index) => (
                    <div key={index} className={`carousel-item ${activeIndex === index ? 'active' : ''}`}>
                      <img src={imagenBase64.imagen} className="d-block w-100" alt={`Slide ${index + 1}`} />
                    </div>
                  ))
                ) : (
                  <div className="alert alert-warning" role="alert">
                    <i className="bi bi-exclamation-triangle me-2"></i>
                    No se encontraron imágenes asociadas a la visita técnica
                  </div>
                )}
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#myCarousel"
                data-bs-slide="prev"
                onClick={() => handleSlideChange((activeIndex - 1 + imagenesVisita.length) % imagenesVisita.length)}
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Anterior</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#myCarousel"
                data-bs-slide="next"
                onClick={() => handleSlideChange((activeIndex + 1) % imagenesVisita.length)}
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Siguiente</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function ConfirmaAnularRequerimientoModalContent ({ id_requerimiento, nombre_requerimiento }) {
    return (
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Anular Requerimiento</h4>
            <button onClick={closeSecondModal} className="btn-close"></button>
          </div>
          <div className="modal-body">
            <p className="fs-5">¿Está seguro que desea anular el requerimiento: <span className="text-red">{nombre_requerimiento}</span>?</p>
          </div>
          <div className="modal-footer">
            <button className="btn btn-danger" onClick={(e) => handleSubmitAnularRequerimiento(e, id_requerimiento)}><i className="bi bi-dash-circle"></i> Anular</button>
          </div>
        </div>
      </div>
    );
  }

  const handleSubmitAnularRequerimiento = async (event, idRequerimiento) => {
    event.preventDefault();

    try {
      const formAnularRequerimiento = {
        id_requerimiento: idRequerimiento
      };

      const responseAnularRequerimiento = await fetch(
        `https://vittamaq.qa-gtalent.cl/api/requerimiento.php?anula=1`,
        {
          method: "PUT",
          headers: {
            'Accept': 'application/json', // Indica que esperas una respuesta JSON
            'Content-Type': 'application/json', 
          },
          body: JSON.stringify(formAnularRequerimiento),
        }
      );

      const dataAnularRequerimiento = await responseAnularRequerimiento.json();
      if (dataAnularRequerimiento.message === "Requerimiento anulado con éxito.") {
        alert("Requerimiento anulado con éxito.");
        closeSecondModal();
        closeModal();
        obtenerRequerimientos();
        navigate("/dashboard", { state: dataLogin });
      }
    } catch (error) {
      alert("Error al anular el requerimiento");
    }
  }

  /* Cotizaciones */
  function AgregarCotizacionModalContent() {
    const [clientes, setClientes] = useState([]);
    const [clienteSelected, setClienteSelected] = useState('');
    const [obras, setObras] = useState([]);
    const [datosCliente, setDatosCliente] = useState([]);
    const [obraSelected, setObraSelected] = useState('');
    const [maquinaria, setMaquinaria] = useState([]);
    const [idFolio, setIdFolio] = useState(null);
    const [requerimientos, setRequerimientos] = useState([]);
    const [servicios, setServicios] = useState([]);
    const [equipos, setEquipos] = useState([]);

    useEffect(() => {
      obtenerClientes();
      obtenerMaquinaria();
      obtenerIdFolio();
      obtenerRequerimientos();
      obtenerServicios();
      obtenerEquipos();
    }, []);

    const obtenerClientes = async () => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/cliente.php`
        );
        const data = await response.json();
  
        if (data && data[0].id_cliente) {
          setClientes(data);
        }
      } catch (error) {
        setClientes([]);
      }
    };

    const handleClienteSelect = async (clienteSelected) => {
      setClienteSelected(clienteSelected.value);
      await obtenerObras(clienteSelected.value);
      await obtenerDatosCliente(clienteSelected.value);

      var cliente = clienteSelected.value.toString();

      setFormCotizacionData({
        ...formCotizacionData,
        ["cliente_add"]: cliente
      });
    };

    const handleClienteFinalSelect = async (clienteSelected) => {
      var cliente = clienteSelected.value.toString();

      setFormCotizacionData({
        ...formCotizacionData,
        ["cliente_final_add"]: cliente
      });
    };

    const obtenerObras = async (cliente) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/cliente_obra.php?id_cliente=${cliente}`
        );
        const data = await response.json();
  
        if (data && data[0].id_obra) {
          setObras(data);  
        }
      } catch (error) {
        setObras([]);
      }
    };

    const obtenerDatosCliente = async (cliente) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/cliente.php?id_cliente=${cliente}`
        );
        const data = await response.json();
  
        if (data && data[0].id_cliente) {
          setDatosCliente(data);

          formCorreo.razon_social = data[0].razon_social;
          formCotizacionData.nombre_solicitante_add = data[0].nombre_representante;
          formCotizacionData.rut_solicitante_add = data[0].rut_representante;
          formCotizacionData.email_solicitante_add = data[0].email_representante;
        }
      } catch (error) {
        setDatosCliente([]);
      }
    };

    const obtenerMaquinaria = async () => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/maquinaria.php`
        );
        const data = await response.json();
  
        if (data && data[0].id_maquinaria) {
          setMaquinaria(data);
        }
      } catch (error) {
        setMaquinaria([]);
      }
    };

    const optionsClientes = clientes.map((cliente) => ({
      value: cliente.id_cliente,
      label: cliente.nombre_cliente,
    }));

    const obtenerServicios = async (idMaquinaria) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/servicio.php?id_maquinaria=${idMaquinaria}`
        );
        const data = await response.json();
        
        if (data && data[0].id_servicio) {
          setServicios(data);
        }
      } catch (error) {
        setServicios([]);
      }
    };

    const obtenerEquipos = async (idMaquinaria) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/equipo.php?id_maquinaria=${idMaquinaria}`
        );
        const data = await response.json();
        
        if (data && data[0].id_equipo) {
          setEquipos(data);
        }
      } catch (error) {
        setEquipos([]);
      }
    };
    
    const obtenerIdFolio = async () => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/folio.php`
        );
        
        const data = await response.json();
  
        if (data && data[0].id_folio) {
          const id_folio = data[0].id_folio + 1;
          setIdFolio(id_folio);
        }
      } catch (error) {
        setIdFolio(null);
      }
    };

    const obtenerRequerimientos = async () => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/requerimiento.php`
        );
        const data = await response.json();
  
        if (data) {
          setRequerimientos(data);
        }
      } catch (error) {
        setRequerimientos([]);
      }
    };

    const [formCotizacionData, setFormCotizacionData] = useState({
      folio: "",
      cliente_add: "",
      obra_add: "",
      maquinaria_add: "",
      nombre_maquinaria_add: "",
      requerimiento_add: "",
      nombre_requerimiento_add: "",
      cliente_final_add: "",
      equipo_add: "",
      nombre_equipo_add: "",
      nombre_solicitante_add: "",
      observaciones_add: "",
      rut_solicitante_add: "",
      email_solicitante_add: "",
      fecha_estimada_inicio_add: "",
      fecha_estimada_fin_add: "",
      id_contacto: "",
      id_usuario: dataLogin.id_usuario,
      imagen: ""
    });

    const handleFormCotizacionData = (e) => {
      const textOnly = /^[A-Za-z\s]+$/;
      const { name, value, selectedIndex, options } = e.target;

      if (name === "obra_add") {
        setObraSelected(value);

        const idObra = parseInt(value, 10);
        const idContacto = obtenerIdContacto(idObra);

        formCotizacionData.id_contacto = idContacto;
      }

      if (name === "requerimiento_add") {
        const nombreRequerimiento = options[selectedIndex].text;
        
        formCotizacionData.nombre_requerimiento_add = nombreRequerimiento;
      }

      if (name === "equipo_add") {
        const nombreEquipo = options[selectedIndex].text;
        
        formCotizacionData.nombre_equipo_add = nombreEquipo;
      }

      if (name === "email_solicitante_add") {
        formCorreo.para = e.target.value;
      }

      if (e.target.name === "nombre_solicitante_add") {
        if (textOnly.test(value) || value === '') {
          setFormCotizacionData({
            ...formCotizacionData,
            [name]: value,
          });
        }
      } else {
        setFormCotizacionData({
          ...formCotizacionData,
          [name]: value,
        });
      }
    };

    const obtenerIdContacto = (idObra) => {
      const obra = obras.find(obra => obra.id_obra === idObra);
      return obra ? obra.id_contacto : null;
    };

    const [base64String, setBase64String] = useState('');
    const handleFileChange = (file) => {
      if (file) {
        // Verificar el largo del nombre del archivo cargado
        if (file.name.length > 50) {
          alert("El nombre del archivo no debe exceder los 50 caracteres");
          document.getElementById("formFile").value = "";
          return;
        }

        // Verificar si el nombre del archivo contiene caracteres especiales
        const regex = /^[a-zA-Z0-9_.-\s]+$/; // Solo permite letras, números, guiones bajos, puntos, guiones y espacios
        if (!regex.test(file.name)) {
          alert("El nombre del archivo contiene caracteres no permitidos");
          document.getElementById("formFile").value = "";
          return;
        }

        // Verificar si el archivo es un PDF
        if (file.type !== "application/pdf") {
          alert("Solo se permiten archivos PDF");
          // Restablecer el input de archivo
          document.getElementById("formFile").value = "";
          return;
        }
    
        const reader = new FileReader();
    
        reader.onloadend = () => {
          // Convertir el contenido del archivo a base64
          const base64String = btoa(reader.result);
          setBase64String(base64String);
    
          setFormCotizacionData({
            ...formCotizacionData,
            ["imagen"]: base64String,
          });
        };
    
        reader.readAsBinaryString(file);
      }
    };
   
    const [dataServiciosCotizacion, setServiciosCotizacionData] = useState({});
    const handleServiciosCotizacionChange = (e, servicio) => {
      const { name, value } = e.target;

      const idServicio = servicio.id_servicio;
      const idUnidadMedida = servicio.id_unidad_medida;
      const IdMaquinariaNombre = "id_maquinaria_" + idServicio;
      const nombreMaquinariaNombre = "nombre_maquinaria_" + idServicio;
      const tipoMaquinariaNombre = "tipo_maquinaria_" + idServicio;
      const tipoArriendoNombre = "tipo_arriendo_" + idServicio;
      const glosaServicioNombre = "glosa_servicio_" + idServicio;
      const inputFolioNombre = "folio_" + idServicio;
      const inputUnidadMedidaNombre = "id_unidad_medida_" + idServicio;
      const unidadMedidaNombre = "unidad_medida_" + idServicio;
      const itemOrdenNombre = "item_orden_" + idServicio;

      if (/^\d*([\.]\d*)?$/.test(value)) {
        setServiciosCotizacionData(prevData => ({
          ...prevData,
          [idServicio]: {
            ...prevData[idServicio],
            [inputFolioNombre]: folioCotizacion, 
            [IdMaquinariaNombre]: servicio.id_maquinaria,
            [nombreMaquinariaNombre]: servicio.nombre_maquinaria,
            [tipoMaquinariaNombre]: servicio.tipo_maquinaria,
            [tipoArriendoNombre]: servicio.tipo_arriendo,
            [glosaServicioNombre]: servicio.glosa_servicio,
            [name]: value,
            [inputUnidadMedidaNombre]: idUnidadMedida,
            [unidadMedidaNombre]: servicio.unidad_medida,
            [itemOrdenNombre]: servicio.item_orden
          },
        }));
      }
    };

    const modificaLlavesServiCot = (objetoOriginal) => {
      const nuevoObjeto = {};

      Object.keys(objetoOriginal).forEach((clave) => {
        const nuevoNombreFolio = `folio`;
        const nuevoNombreIdMaquinaria = `id_maquinaria`;
        const nuevoNombreMaquinaria = `nombre_maquinaria`;
        const nuevoNombreTipoMaquinaria = `tipo_maquinaria`;
        const nuevoNombreTipoArriendo = `tipo_arriendo`;
        const nuevoNombreGlosaServicio = `glosa_servicio`;
        const nuevoNombreCantidad = `cantidad_minima`;
        const nuevoNombreIdUnidadMedida = `id_unidad_medida`;
        const nuevoNombreValor = `valor_unitario_neto`;
        const nuevoNombreValorPM = `valor_unitario_neto_pm`;
        const nuevoNombreItemOrden = `item_orden`;
        const nuevoNombreUnidadMedida = `unidad_medida`;
        const nuevoNombreIdUsuario = `id_usuario`;
  
        nuevoObjeto[clave] = {
          [nuevoNombreFolio]: objetoOriginal[clave][`folio_${clave}`],
          [nuevoNombreIdMaquinaria]: objetoOriginal[clave][`id_maquinaria_${clave}`],
          [nuevoNombreMaquinaria]: objetoOriginal[clave][`nombre_maquinaria_${clave}`],
          [nuevoNombreTipoMaquinaria]: objetoOriginal[clave][`tipo_maquinaria_${clave}`],
          [nuevoNombreTipoArriendo]: objetoOriginal[clave][`tipo_arriendo_${clave}`],
          [nuevoNombreGlosaServicio]: objetoOriginal[clave][`glosa_servicio_${clave}`],
          [nuevoNombreCantidad]: objetoOriginal[clave][`cantidad_minima_${clave}`],
          [nuevoNombreIdUnidadMedida]: objetoOriginal[clave][`id_unidad_medida_${clave}`],
          [nuevoNombreUnidadMedida]: objetoOriginal[clave][`unidad_medida_${clave}`],
          [nuevoNombreValor]: objetoOriginal[clave][`valor_unitario_neto_${clave}`],
          [nuevoNombreValorPM]: objetoOriginal[clave][`valor_unitario_neto_pm_${clave}`],
          [nuevoNombreItemOrden]: objetoOriginal[clave][`item_orden_${clave}`],
          [nuevoNombreIdUsuario]: objetoOriginal[clave][`id_usuario_${clave}`],
        };
      });
  
      return nuevoObjeto;
    };

    const [folioCotizacion, setFolioCotizacionData] = useState('');
    const [checkboxStates, setCheckboxStates] = useState({});
    const toggleCheckbox = (filaId, servicio) => {
      setCheckboxStates((prevState) => ({
        ...prevState,
        [filaId]: !prevState[filaId],
      }));

      //Agrega folio
      const currentYear = new Date().getFullYear();

      const idMaquinaria = Number(maquinariaFiltro);
      const maquinariaEncontrada = maquinaria.find(maquinaria => maquinaria.id_maquinaria === idMaquinaria);
      let inicialesMaquinaria = maquinariaEncontrada.iniciales;

      const folio = currentYear + "-" + idFolio + "-" + inicialesMaquinaria + "-" + dataLogin.iniciales;

      setFolioCotizacionData(folio);
  
      setFormCotizacionData({
        ...formCotizacionData,
        ["folio"]: folio,
      });
      
      //Valores iniciales de cantidad minima y valor unitario neto
      const idServicio = servicio.id_servicio;
      const idUnidadMedida = servicio.id_unidad_medida;
      const unidadMedidaNombre = "unidad_medida_" + idServicio;
      const IdMaquinariaNombre = "id_maquinaria_" + idServicio;
      const nombreMaquinariaNombre = "nombre_maquinaria_" + idServicio;
      const tipoMaquinariaNombre = "tipo_maquinaria_" + idServicio;
      const tipoArriendoNombre = "tipo_arriendo_" + idServicio;
      const glosaServicioNombre = "glosa_servicio_" + idServicio;
      const inputFolioNombre = "folio_" + idServicio;
      const inputUnidadMedidaNombre = "id_unidad_medida_" + idServicio;
      const inputCantidadMinimaNombre = "cantidad_minima_" + idServicio;
      const inputCantidadMinima = document.getElementById(inputCantidadMinimaNombre);
      const inputValorUnitarioNombre = "valor_unitario_neto_" + idServicio;
      const inputValorUnitario = document.getElementById(inputValorUnitarioNombre);
      const inputValorUnitarioPMNombre = "valor_unitario_neto_pm_" + idServicio;
      const inputValorUnitarioPM = document.getElementById(inputValorUnitarioPMNombre);
      const inputItemOrdenNombre = "item_orden_" + idServicio;
      const idUsuario = "id_usuario_" + idServicio;

      // Comprobar si el atributo "disabled" está presente
      if (inputCantidadMinima && inputCantidadMinima.disabled) {
        //Agrega valores iniciales si el input se habilita
        setServiciosCotizacionData(prevData => ({
          ...prevData,
          [idServicio]: {
            ...prevData[idServicio],
            [inputFolioNombre]: folio,
            [IdMaquinariaNombre]: servicio.id_maquinaria,
            [nombreMaquinariaNombre]: servicio.nombre_maquinaria,
            [tipoMaquinariaNombre]: servicio.tipo_maquinaria,
            [tipoArriendoNombre]: servicio.tipo_arriendo,
            [glosaServicioNombre]: servicio.glosa_servicio,
            [inputCantidadMinima.name]: inputCantidadMinima.value,
            [inputValorUnitario.name]: inputValorUnitario.value,
            [inputValorUnitarioPMNombre]: inputValorUnitarioPM ? inputValorUnitarioPM.value : "0",
            [inputUnidadMedidaNombre]: idUnidadMedida,
            [unidadMedidaNombre]: servicio.unidad_medida,
            [inputItemOrdenNombre]: servicio.item_orden,
            [idUsuario]: dataLogin.id_usuario
          },
        }));
      } else {
        //Elimina valores si el input se deshabilita
        const newData = { ...dataServiciosCotizacion };
        delete newData[idServicio];
        setServiciosCotizacionData(newData);
      }
    };

    const navigate = useNavigate();
    const handleSubmitCotizacion = async (event, mostrarTablaCC, filas) => {
      event.preventDefault();

      try {
        //Valida los email ingresados
        const validaEmail = filas.every(item => validarEmail(item.email));

        if (mostrarTablaCC && !validaEmail) {
          alert('Debe ingresar el o los email y comprobar si están correctos');
        } else {
          //Inserción de folio
          const folioData = {
            id_folio: idFolio,
            folio: folioCotizacion
          };

          const responseFolioCotizacion = await fetch(
            `https://vittamaq.qa-gtalent.cl/api/folio.php`,
            {
              method: "POST",
              headers: {
                'Accept': 'application/json', // Indica que esperas una respuesta JSON
                'Content-Type': 'application/json', 
              },
              body: JSON.stringify(folioData),
            }
          );

          const dataFolioCotizacion = await responseFolioCotizacion.json();
          if (dataFolioCotizacion.message === "Folio agregado con éxito.") {

            const responseCabeceraCotizacion = await fetch(
              `https://vittamaq.qa-gtalent.cl/api/cabecera_cotizacion.php`,
              {
                method: "POST",
                headers: {
                  'Accept': 'application/json', // Indica que esperas una respuesta JSON
                  'Content-Type': 'application/json', 
                },
                body: JSON.stringify(formCotizacionData),
              }
            );

            const dataCabeceraCotizacion = await responseCabeceraCotizacion.json();
            if (dataCabeceraCotizacion.message === "Cabecera de cotización agregada con éxito.") {
              const objetoTransformado = modificaLlavesServiCot(dataServiciosCotizacion);
              const responseServiciosCotizacion = await fetch(
                `https://vittamaq.qa-gtalent.cl/api/detalle_cotizacion.php`,
                {
                  method: "POST",
                  headers: {
                    'Accept': 'application/json', // Indica que esperas una respuesta JSON
                    'Content-Type': 'application/json', 
                  },
                  body: JSON.stringify(objetoTransformado),
                }
              );

              const dataServCotizacion = await responseServiciosCotizacion.json();
              if (dataServCotizacion.message === "Detalle de cotización agregado con éxito.") {
                closeSecondModal();
                closeModal();

                crearDescargarPDFCotizacion(filas, mostrarTablaCC);

                setFormCotizacionData({
                  folio: "",
                  cliente_add: "",
                  obra_add: "",
                  maquinaria_add: "",
                  nombre_maquinaria_add: "",
                  requerimiento_add: "",
                  nombre_requerimiento_add: "",
                  cliente_final_add: "",
                  equipo_add: "",
                  nombre_equipo_add: "",
                  nombre_solicitante_add: "",
                  observaciones_add: "",
                  rut_solicitante_add: "",
                  email_solicitante_add: "",
                  fecha_estimada_inicio_add: "",
                  fecha_estimada_fin_add: "",
                  id_contacto: "",
                  id_usuario: "",
                  imagen: ""
                });

                setServiciosCotizacionData([]);

                navigate("/dashboard", { state: dataLogin });
              }
            }
          } else {
            throw new Error();
          }
        }
      } catch (error) {
        //alert(error);
        console.log("Error");
      }
    }

    const validaFormulario = () => {
      if (formCotizacionData.cliente_add === "") {
        alert('Debe seleccionar un cliente');
        return false;
      }
      
      if (formCotizacionData.obra_add === "") {
        alert('Debe seleccionar una obra');
        return false;
      }

      if (formCotizacionData.cliente_final_add === "") {
        alert('Debe seleccionar un cliente final');
        return false;
      }

      if (formCotizacionData.maquinaria_add === "") {
        alert('Debe seleccionar una maquinaria');
        return false;
      }

      if (formCotizacionData.equipo_add === "") {
        setFormCotizacionData({
          ...formCotizacionData,
          ["equipo_add"]: 0,
        });
        /* alert('Debe seleccionar un equipo');
        return false; */
      }
  
      if (formCotizacionData.nombre_solicitante_add === "") {
        alert('Debe ingresar un nombre de solicitante');
        return false;
      }

      if (formCotizacionData.rut_solicitante_add === "") {
        alert('Debe ingresar un rut de solicitante');
        return false;
      }

      if (formCotizacionData.email_solicitante_add === "" || !validarEmail(formCotizacionData.email_solicitante_add)) {
        alert('Debe ingresar un email de solicitante y compruebe si está correcto');
        return false;
      }

      if (formCotizacionData.email_solicitante_add === "") {
        alert('Debe ingresar un email de solicitante');
        return false;
      }

      if (formCotizacionData.fecha_estimada_inicio_add === "") {
        alert('Debe ingresar una fecha estimada de inicio');
        return false;
      }

      if (formCotizacionData.fecha_estimada_fin_add === "") {
        alert('Debe ingresar una fecha estimada de fin');
        return false;
      }
  
      return true;
    }

    //Filtro Por Servicio
    const [maquinariaFiltro, setMaquinariaFiltro] = useState('');
    const handleFiltroMaquinariaData = (event) => {
      const { value, selectedIndex, options } = event.target;

      setCheckboxStates({});
      setServiciosCotizacionData({});
      setMaquinariaFiltro(value);
      obtenerServicios(value);
      obtenerEquipos(value);

      setFormCotizacionData({
        ...formCotizacionData,
        ["maquinaria_add"]: value,
        ["nombre_maquinaria_add"]: options[selectedIndex].text,
      });
    };

    //Filtro Servicios Cotización
    const [filtroServiciosCotizacion, setFiltroServiciosCotizacion] = useState('');
    const manejarCambioFiltro = (event) => {
      setFiltroServiciosCotizacion(event.target.value);
    };
    
    const serviciosFiltrados = servicios.filter((item) =>
      item.glosa_servicio.toLowerCase().includes(filtroServiciosCotizacion.toLowerCase())
    );

    //Valida Rut
    const validarRut = (rut) => {
      return validate(rut);
    }

    //Valida Email
    const validarEmail = (email) => {
      // Expresión regular para validar el formato del correo electrónico
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    //Datos PDF
    const [datosPDF, setDatosPDF] = useState(null);
    useEffect(() => {
      const idCliente = parseInt(clienteSelected);
      const clienteEncontrado = clientes.find(c => c.id_cliente === idCliente);

      const idObra = parseInt(obraSelected);
      const obraEncontrada = obras.find(o => o.id_obra === idObra);

      obtenerContacto(clienteSelected);

      setDatosPDF({
        cliente: clienteEncontrado,
        obra: obraEncontrada,
        contacto: {}
      });

      /* if (clienteEncontrado != undefined) {
        formCorreo.cliente = clienteEncontrado.nombre_cliente;
      } */
    }, [clienteSelected, obraSelected]);

    const obtenerContacto = async (cliente) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/contacto_2.php?id_cliente=${cliente}`
        );
        const data = await response.json();
  
        if (data && data[0].id_contacto) {
          setDatosPDF(prevState => ({
            ...prevState,
            contacto: data[0]
          }));
        }
      } catch (error) {
        setDatosPDF(prevState => ({
          ...prevState,
          contacto: {}
        }));
      }
    };

    const [pdfBytes, setPdfBytes] = useState(null);
    const crearDescargarPDFCotizacion = async (filas, mostrarTablaCC) => {
      //Agrega Datos Email
      formCorreo.para = formCotizacionData.email_solicitante_add; 
      formCorreo.cliente = formCotizacionData.nombre_solicitante_add;

      if (!mostrarTablaCC) {
        formCorreo.cc = [];
      } else {
        formCorreo.cc = filas;
      }
      
      formCorreo.asunto = formCorreo.asunto + formCotizacionData.folio;

      const existingPdfBytes = await fetch('https://vittamaq.qa-gtalent.cl/api/obtienePlantillaCotizacion.php').then((res) => res.arrayBuffer());
      const pdfDoc = await PDFDocument.load(existingPdfBytes);

      // Generar código QR
      // const urlQR = 'www.google.cl';
      const urlQR = 'https://vittamaq.cl/';
      const qrImageUrl = await generarQR(urlQR);

      // Insertar código QR en el PDF
      await insertarQR(pdfDoc, qrImageUrl, 300, 680, 50, 50);
  
      // Obtener el formulario del PDF
      const form = pdfDoc.getForm();

      // Fecha Cotización
      let fechaActual = new Date();
      const formatDate = (date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();
        return `${day}-${month}-${year}`;
      };
      const fechaCotizacion = formatDate(fechaActual);

      // Cabecera Cotización
      form.getTextField('Folio').setText(formCotizacionData.folio);
      form.getTextField('Nombre_Cliente').setText(datosPDF.cliente.nombre_cliente);
      form.getTextField('Rut_Cliente').setText(formatearRut(datosPDF.cliente.rut));
      form.getTextField('Razon_Social').setText(datosPDF.cliente.razon_social);
      form.getTextField('Giro_Cliente').setText(datosPDF.cliente.giro);
      form.getTextField('Direccion_Cliente').setText(datosPDF.cliente.direccion);
      form.getTextField('Obra_Cliente').setText(datosPDF.obra.nombre);
      form.getTextField('Direccion_Obra').setText(datosPDF.obra.direccion);

      form.getTextField('Fecha_Cotizacion').setText(fechaCotizacion);
      form.getTextField('Contacto').setText(datosPDF.contacto.nombres + " " + datosPDF.contacto.apellido_paterno + " " + datosPDF.contacto.apellido_materno);
      form.getTextField('Cargo_Contacto').setText(datosPDF.contacto.cargo);
      form.getTextField('Telefono_Contacto').setText(datosPDF.contacto.telefono);
      form.getTextField('Email_Contacto').setText(datosPDF.contacto.email);
      form.getTextField('Observaciones').setText(formCotizacionData.observaciones_add ? formCotizacionData.observaciones_add : "");

      form.getTextField('Equipo').setText(
        `${formCotizacionData.nombre_maquinaria_add} - ${formCotizacionData.nombre_equipo_add} (${formCotizacionData.nombre_requerimiento_add})`
      );

      // Servicios Cotización
      const objetoTransformado = modificaLlavesServiCot(dataServiciosCotizacion);
      
      let pmInserted = false;

      Object.values(objetoTransformado).forEach((detalle, index) => {
        form.getTextField(`Item_Orden_${index + 1}`).setText(detalle.item_orden.split('-')[1]);
        if (detalle.glosa_servicio !== "") {
          form.getTextField(`Descripcion_${index + 1}`).setText(detalle.glosa_servicio);
        } else {
          form.getTextField(`Descripcion_${index + 1}`).setText(`${detalle.nombre_maquinaria} ${detalle.tipo_maquinaria} ${detalle.tipo_arriendo}`);
          /* form.getTextField('Tipo_Maquinaria').setText(
            `${detalle.nombre_maquinaria} ${detalle.tipo_maquinaria === "null" || detalle.tipo_maquinaria === "Ninguno" ? "" : detalle.tipo_maquinaria}`
          ); */
        }
        form.getTextField(`Cantidad_Minima_${index + 1}`).setText(detalle.cantidad_minima);
        form.getTextField(`Unidad_Medida_${index + 1}`).setText(detalle.unidad_medida);

        if (detalle.id_maquinaria === 1) {
          form.getTextField(`Valor_Unitario_Neto_${index + 1}`).setText(detalle.valor_unitario_neto + " UF");
        } else if (detalle.id_maquinaria === 2) {
          form.getTextField(`Valor_Unitario_Neto_${index + 1}`).setText(String("$ " + parseInt(detalle.valor_unitario_neto).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.')));
        } else {
          form.getTextField(`Valor_Unitario_Neto_${index + 1}`).setText(String(parseFloat(detalle.valor_unitario_neto).toFixed(2) + " UF"));
        }

        if (detalle.id_maquinaria === 1 && !pmInserted) {
          form.getTextField(`Valor_Unitario_Neto_PM_${index + 1}`).setText(detalle.valor_unitario_neto_pm + " UF (PM)");
          pmInserted = true;
        }
      });

      // Guardar el PDF modificado
      const modifiedPdfBytes = await pdfDoc.save();

      // Convierte los bytes en base64
      const arrayBufferToBase64 = (buffer) => {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
      };

      // Convertir a base64 el PDF modificado y añadirlo al Email como adjunto
      const pdfBase64 = arrayBufferToBase64(modifiedPdfBytes);
      //formCorreo.adjunto[0].Base64 = pdfBase64;

      const adjuntoCotizacion = {
        NombreAdjunto: "Cotización Vittamaq",
        TipoDocumento: "pdf",
        Base64: pdfBase64
      };

      //Condiciones Generales
      const existingPdfBytesCG = await fetch(`https://vittamaq.qa-gtalent.cl/api/obtienePlantillaCondicionesGenerales.php?id_maquinaria=${formCotizacionData.maquinaria_add}`).then((res) => res.arrayBuffer());

      const pdfDocCG = await PDFDocument.load(existingPdfBytesCG);
  
      // Obtener el formulario del PDF
      const formCG = pdfDocCG.getForm();
  
      formCG.getTextField('Folio').setText(formCotizacionData.folio);

      const modifiedPdfBytesCG = await pdfDocCG.save();

      const pdfBase64CG = arrayBufferToBase64(modifiedPdfBytesCG);

      const adjuntoCondGen = {
        NombreAdjunto: "Condiciones Generales",
        TipoDocumento: "pdf",
        Base64: pdfBase64CG
      };

      if (formCotizacionData.imagen) {
        const adjuntoDocumento = {
          NombreAdjunto: "Documento Vittamaq",
          TipoDocumento: "pdf",
          Base64: formCotizacionData.imagen
        };

        formCorreo.adjuntos = [adjuntoCotizacion, adjuntoCondGen, adjuntoDocumento];
      } else {
        formCorreo.adjuntos = [adjuntoCotizacion, adjuntoCondGen];
      }
  
      // Actualizar el estado para que React renderice el PDF modificado
      setPdfBytes(modifiedPdfBytes);

      // Crear un objeto Blob con los bytes del PDF modificado
      const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });

      guardarPDF(blob, formCotizacionData.folio);

      // Crear una URL para el Blob
      /* const urlBlob = URL.createObjectURL(blob);

      // Crear un enlace <a> para descargar el PDF
      const link = document.createElement('a');
      link.href = urlBlob;

      link.download = formCotizacionData.folio + '.pdf';

      // Agregar el enlace al documento
      document.body.appendChild(link);

      // Simular un clic en el enlace para iniciar la descarga
      link.click();

      // Eliminar el enlace después de la descarga
      document.body.removeChild(link); */

      handleEnviarCorreo();
    };

    const guardarPDF = async (blob, folio) => {
      const formData = new FormData();
      formData.append('pdf', blob, folio);
    
      try {
        const response = await fetch('https://vittamaq.qa-gtalent.cl/api/guardar_pdf.php', {
          method: 'POST',
          body: formData,
        });
    
        if (response.ok) {
          const responseData = await response.text();
          //console.log('Respuesta del servidor:', responseData);
        } else {
          console.error('Error al enviar el PDF al servidor:', response.status);
        }
      } catch (error) {
        console.error('Error de red:', error);
      }
    };

    /* Envío de Correo: Cotización */
    const [formCorreo, setFormCorreo] = useState({
      para: "",
      asunto: "Cotización: ",
      cliente: "",
      razon_social: "",
      texto_cuerpo: "A continuación, adjuntamos la cotización detallada que hemos preparado para proporcionarles una oferta que se ajuste a sus necesidades y expectativas. Por favor, revisar y no dude en ponerse en contacto con nosotros si tiene alguna pregunta o necesita más información.",
      cc: {},
    });

    const handleEnviarCorreo = async () => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/envio_correos.php`,
          {
            method: "POST",
            headers: {
              'Accept': 'application/json', // Indica que esperas una respuesta JSON
              'Content-Type': 'application/json', 
            },
            body: JSON.stringify(formCorreo),
          }
        );
  
        const data = await response.json();
        //console.log("Respuesta: " + JSON.stringify(data,null,2));
  
        if (data.message === "El correo electrónico ha sido enviado correctamente.") {
          const estadoData = [{folio: formCotizacionData.folio, id_estado_cotizacion: 2}];
          const responseCambioEstado = await fetch(
            `https://vittamaq.qa-gtalent.cl/api/cabecera_cotizacion.php`,
            {
              method: "PUT",
              headers: {
                'Accept': 'application/json', // Indica que esperas una respuesta JSON
                'Content-Type': 'application/json', 
              },
              body: JSON.stringify(estadoData),
            }
          );

          const dataResponseCambioEstado = await responseCambioEstado.json();
          if (dataResponseCambioEstado.message === "Estado de cotización modificado con éxito.") {
            alert("Se ha registrado exitosamente la cotización y ha sido enviada por correo electrónico.");
            obtenerCotizaciones();
          }
          
        }
      } catch (error) {
        alert("Hubo un error al enviar el correo electrónico: " + error);
      }
    };

    // Valida que se registre al menos 1 servicio combinado (También que solo debe haber 1 servicio combinado) por cotización
    function validarTiposMaquinarias(data) {
      let countTipoMaquinaria = 0;
    
      for (const key in data) {
        if (data.hasOwnProperty(key) && data[key].hasOwnProperty("tipo_maquinaria_" + key)) {
          const tipoMaquinariaValue = data[key]["tipo_maquinaria_" + key];
          const idMaquinariaValue = data[key]["id_maquinaria_" + key];
          
          // Verificar si idTipoMaquinariaValue es 3
          if (idMaquinariaValue === 3) {
            return true;
          }
    
          if (tipoMaquinariaValue !== null && tipoMaquinariaValue !== "") {
            countTipoMaquinaria++;
            if (countTipoMaquinaria > 1) {
              //return false;
              return true;
            }
          }
        }
      }
    
      return countTipoMaquinaria === 1; // Retorna true solo si hay exactamente un tipo de maquinaria asignado
    }
    
    /* Confirmación de cotización y envío de correo */
    const handleConfirmaCotizacion = (e, folio, email) => {
      const esFormularioValido = validaFormulario();

      if (Object.keys(dataServiciosCotizacion).length === 0) {
        alert('Debe agregar al menos un servicio');
      } else if (!validarTiposMaquinarias(dataServiciosCotizacion)) {
        alert('Se debe seleccionar al menos un servicio combinado (Solo 1 por cotización).');
      } else if (esFormularioValido) {
        const esRutValido = validarRut(formCotizacionData.rut_solicitante_add);
        if (!esRutValido) {
          alert("Rut No Válido");
        } else {
          setSecondModalOpen(true);
          setSecondModalContent(<ConfirmaCotizacionModalContent folio={folio} email={email} />);
        }
      }
    };

    function ConfirmaCotizacionModalContent ({ folio, email }) {
      /* Agregar CC */
      const [mostrarTablaCC, setMostrarTablaCC] = useState(true);
      const [mostrarBotonCC, setMostrarBotonCC] = useState(true);

      const handleRadioChange = (event) => {
        setMostrarTablaCC(event.target.value === "1");

        setMostrarBotonCC(!mostrarBotonCC);

        const nuevaFila = { ...filaPredeterminada, id: 1 };
        setFilas([nuevaFila]);
      }
      
      const filaPredeterminada = { 
        id: 1, 
        email: ''
      };

      const [filas, setFilas] = useState([filaPredeterminada]);

      const handleAgregarFila = () => {
        let siguienteId = 1;

        while (filas.some(fila => fila.id === siguienteId)) {
          siguienteId++;
        }

        const nuevaFila = {
          id: siguienteId,
          email: ''
        };

        setFilas([...filas, nuevaFila]);
      };

      const handleInputChange = (id, campo, valor) => {
        const nuevasFilas = filas.map((fila) =>
          fila.id === id ? { ...fila, [campo]: valor } : fila
        );

        setFilas(nuevasFilas);
        //console.log(JSON.stringify(filas, null, 2));
      };

      const handleEliminarFila = (id) => {
        // Verificar que haya más de una fila antes de eliminar
        if (filas.length > 1) {
          const nuevasFilas = filas.filter((fila) => fila.id !== id);
          setFilas(nuevasFilas);
        } else {
          alert("Debe agregar al menos un email, de lo contrario seleccione 'NO'.");
        }
      };

      return (
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="mb-0">Confirmar Cotización</h4>
              <button onClick={closeSecondModal} className="btn-close"></button>
            </div>
            <div className="modal-body">
              <p className="fs-5 mb-2">¿Está seguro que desea ingresar la cotización: <span className="text-orange">{folio}</span>?</p>
              <p>Se enviará mediante correo electrónico a la dirección indicada: <span className="text-orange">{email}.</span></p>
              <p>¿Requiere copiar cotización a otras direcciones de correo electrónico (CC)?</p>
              <div className="d-flex justify-content-md-between">
                <div>
                  <input
                    type="radio" 
                    className="btn-check" 
                    name="email_cc" 
                    id="email-cc-si" 
                    autoComplete="off" 
                    value="1"
                    checked={mostrarTablaCC}
                    onChange={handleRadioChange}
                  />
                  <label className="btn" htmlFor="email-cc-si">Si</label>
                  <input 
                    type="radio" 
                    className="btn-check" 
                    name="email_cc" 
                    id="email-cc-no" 
                    autoComplete="off"
                    value="0"
                    checked={!mostrarTablaCC}
                    onChange={handleRadioChange}
                  />
                  <label className="btn" htmlFor="email-cc-no">No</label>
                </div>
                {mostrarBotonCC && (
                  <button type="button" className="btn btn-dark btn-sm" onClick={handleAgregarFila}>
                    <i className="bi-plus-circle"></i> Agregar
                  </button>
                )}
              </div>
              {mostrarTablaCC && (
                <div className="mt-3">
                  <table className="table mb-0">
                    <thead>
                      <tr className="table-active">
                        <th>Email</th>
                        <th>Eliminar</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filas.map((fila, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              className="form-control form-control-sm"
                              type="text"
                              id={"email_" + index}
                              value={fila.email}
                              onChange={(e) =>
                                handleInputChange(fila.id, 'email', e.target.value)
                              }
                            />
                          </td>
                          <td>
                            <button className="btn btn-outline-danger btn-sm" onClick={() => handleEliminarFila(fila.id)}>
                              <i className="bi bi-trash3"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button className="btn btn-yellow" onClick={(e) => handleSubmitCotizacion(e, mostrarBotonCC, filas)}><i className="bi bi-floppy"></i> Guardar</button>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="modal-dialog modal-dialog-scrollable modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Agregar Cotización</h4>
            <button onClick={closeModal} className="btn-close"></button>
          </div>
          <div className="modal-body py-3 px-5">
            <div className="row row-cols-1 row-cols-md-2 mb-3">
              {clientes ? (
                <div className="col">
                  <label className="form-label">Cliente</label>
                  <Select
                    name="cliente_add" 
                    value={clienteSelected.value} 
                    onChange={handleClienteSelect}
                    options={optionsClientes}
                    isSearchable
                    className="react-select"
                    placeholder="Seleccione un cliente"
                  />
                </div>
                ) : (
                <div className="col">
                  <label className="form-label">Cliente</label>
                  <select className="form-select" name="cliente_add">
                    <option value="">No se encontraron clientes</option>
                  </select>
                </div>
              )}
              {obras ? (
                <div className="col">
                  <label className="form-label">Obra</label>
                  <select className="form-select" name="obra_add" value={obraSelected || formCotizacionData.obra_add} onChange={handleFormCotizacionData}>
                    <option value="">Seleccione una obra</option>
                    {obras.map((obra) => (
                      <option key={obra.id_obra} value={obra.id_obra}>{obra.nombre}</option>
                    ))}
                  </select>
                </div>
                ) : (
                <div className="col">
                  <label className="form-label">Obra</label>
                  <select className="form-select" name="obra_add">
                    <option value="">Cliente sin obras asociadas</option>
                  </select>
                </div>
              )}
            </div>
            <div className="row row-cols-md-2 mb-3">
              {requerimientos ? (
                <div className="col-md-6">
                  <label className="form-label">Requerimiento</label>
                  <select className="form-select" name="requerimiento_add" value={formCotizacionData.requerimiento_add} onChange={handleFormCotizacionData}>
                    <option value="">Seleccione un requerimiento</option>
                    {requerimientos.map((requerimiento) => (
                      <option key={requerimiento.id_requerimiento} value={requerimiento.id_requerimiento}>{requerimiento.nombre_requerimiento}</option>
                    ))}
                  </select>
                </div>
                ) : (
                <div className="col-md-6">
                  <label className="form-label">Requerimiento</label>
                  <select className="form-select" name="requerimiento_add">
                    <option value="">No se encontraron requerimientos</option>
                  </select>
                </div>
              )}
              {clientes ? (
                <div className="col">
                  <label className="form-label">Cliente Final</label>
                  <Select
                    name="cliente_final_add" 
                    value={clienteSelected.value} 
                    onChange={handleClienteFinalSelect}
                    options={optionsClientes}
                    isSearchable
                    className="react-select"
                    placeholder="Seleccione un cliente final"
                  />
                </div>
                ) : (
                <div className="col">
                  <label className="form-label">Cliente Final</label>
                  <select className="form-select" name="cliente_add">
                    <option value="">No se encontraron clientes</option>
                  </select>
                </div>
              )}
            </div>
            <div className="row row-cols-md-2 mb-4">
              <div className="col">
                <label className="form-label">Maquinaria</label>
                <select className="form-select" name="id_maquinaria" value={maquinariaFiltro} onChange={handleFiltroMaquinariaData}>
                  <option value="">Seleccione una maquinaria</option>
                  {maquinaria.map((maquinaria) => (
                    <option key={maquinaria.id_maquinaria} value={maquinaria.id_maquinaria}>{maquinaria.maquinaria}</option>
                  ))}
                </select>
              </div>
              <div className="col">
                <label className="form-label">Equipo</label>
                <select className="form-select" name="equipo_add" value={formCotizacionData.equipo_add} onChange={handleFormCotizacionData}>
                  <option value="">Seleccione un equipo</option>
                  {equipos.map((equipo, index) => (
                    <option key={index} value={equipo.id_equipo}>{equipo.marca + " " + equipo.modelo}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mb-4">
              <div className="d-flex align-items-end justify-content-between mb-3">
                <h5 className="mb-0">Servicios</h5>
                <div className="d-flex flex-md-row gap-md-2">
                  <div className="input-group input-group-sm">
                    <span className="input-group-text" id="search-servicios">
                      <i className="bi bi-search"></i>
                    </span>
                    <input type="text" className="form-control" placeholder="Servicio..." aria-describedby="search-servicios" onChange={manejarCambioFiltro}/>
                  </div>
                </div>
              </div>
              <div className="table-responsive border rounded">
                <table className="table table-sm table-hover mb-0">
                  <thead>
                    <tr className="table-active border-bottom">
                      <th className="ps-2"></th>
                      <th>Item</th>
                      <th>Nombre Servicio</th>
                      <th>Cantidad Mínima</th>
                      <th>Unidad Medida</th>
                      <th>Valor Unitario Neto + IVA</th>
                      {maquinariaFiltro === "1" && (
                        <th>Valor Unitario Neto PM</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {serviciosFiltrados.length > 0 ? (
                      serviciosFiltrados.map((servicio, index) => (
                        <tr key={index}>
                          <td className="ps-2">
                            <div className="form-check">
                              <input 
                                className="form-check-input" 
                                type="checkbox" 
                                checked={checkboxStates[index] || false}
                                onChange={() => toggleCheckbox(index, servicio)}
                              />
                            </div>
                          </td>
                          <td>
                            {servicio.item_orden.split('-')[1]}
                          </td>
                          <td>
                            {
                              servicio.glosa_servicio !== null && servicio.glosa_servicio !== '' ? (
                                servicio.glosa_servicio
                              ) : (
                                servicio.id_maquinaria !== 1 && servicio.id_maquinaria !== 2 && servicio.id_tipo_maquinaria === 0 ? (
                                  servicio.nombre_maquinaria + " - " + servicio.tipo_arriendo
                                ) : (
                                  servicio.nombre_maquinaria + (servicio.tipo_maquinaria === "Ninguno" ? " - " + servicio.tipo_arriendo : " - " + servicio.tipo_maquinaria + " - " + servicio.tipo_arriendo)
                                )
                              )
                            }
                          </td>
                          <td>
                            <input
                              className="form-control form-control-sm w-auto"
                              id={"cantidad_minima_" + servicio.id_servicio}
                              name={"cantidad_minima_" + servicio.id_servicio}
                              type="text"
                              value={
                                dataServiciosCotizacion[servicio.id_servicio]?.["cantidad_minima_" + servicio.id_servicio] !== undefined
                                  ? dataServiciosCotizacion[servicio.id_servicio]?.["cantidad_minima_" + servicio.id_servicio]
                                  : Math.trunc(servicio.cantidad_minima)
                              }
                              onChange={(e) => handleServiciosCotizacionChange(e, servicio)}
                              disabled={!checkboxStates[index]}
                            />
                          </td>
                          <td>
                            {servicio.unidad_medida}
                          </td>
                          <td>
                            <input
                              className="form-control form-control-sm w-auto"
                              id={"valor_unitario_neto_" + servicio.id_servicio}
                              name={"valor_unitario_neto_" + servicio.id_servicio}
                              type="text"
                              value={
                                formCotizacionData.maquinaria_add === "1" ? (
                                  dataServiciosCotizacion[servicio.id_servicio]?.["valor_unitario_neto_" + servicio.id_servicio] !== undefined
                                  ? dataServiciosCotizacion[servicio.id_servicio]?.["valor_unitario_neto_" + servicio.id_servicio]
                                  : servicio.valor_unitario_neto
                                ) : formCotizacionData.maquinaria_add === "2" ? (
                                  dataServiciosCotizacion[servicio.id_servicio]?.["valor_unitario_neto_" + servicio.id_servicio] !== undefined
                                  ? dataServiciosCotizacion[servicio.id_servicio]?.["valor_unitario_neto_" + servicio.id_servicio]
                                  : parseInt(servicio.valor_unitario_neto)
                                ) : (
                                  dataServiciosCotizacion[servicio.id_servicio]?.["valor_unitario_neto_" + servicio.id_servicio] !== undefined
                                  ? dataServiciosCotizacion[servicio.id_servicio]?.["valor_unitario_neto_" + servicio.id_servicio]
                                  : parseFloat(servicio.valor_unitario_neto).toFixed(2)
                                )
                              }
                              onChange={(e) => handleServiciosCotizacionChange(e, servicio)}
                              disabled={!checkboxStates[index]}
                            />
                          </td>
                          {servicio.id_maquinaria === 1 && servicio.id_tipo_maquinaria != 0 && (servicio.id_unidad_medida === 1 || servicio.id_unidad_medida === 7) ? (
                            <td>
                              <input
                                className="form-control form-control-sm w-auto"
                                id={"valor_unitario_neto_pm_" + servicio.id_servicio}
                                name={"valor_unitario_neto_pm_" + servicio.id_servicio}
                                type="text"
                                value={
                                  dataServiciosCotizacion[servicio.id_servicio]?.["valor_unitario_neto_pm_" + servicio.id_servicio] !== undefined
                                    ? dataServiciosCotizacion[servicio.id_servicio]?.["valor_unitario_neto_pm_" + servicio.id_servicio]
                                    : servicio.valor_unitario_neto_pm
                                }
                                onChange={(e) => handleServiciosCotizacionChange(e, servicio)}
                                disabled={!checkboxStates[index]}
                              />
                            </td>
                          ) : servicio.id_maquinaria != 1 ? (
                            null
                          ) : (
                            <td>
                              <input
                                className="form-control form-control-sm w-auto"
                                id={"valor_unitario_neto_pm_" + servicio.id_servicio}
                                name={"valor_unitario_neto_pm_" + servicio.id_servicio}
                                type="text"
                                value={0}
                                hidden
                                readOnly
                              />
                            </td>
                          )}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">No se encontraron servicios</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <h5 className="mb-3">Información de Solicitante</h5>
            <div className="row row-cols-1 row-cols-md-2 g-4 mb-3">
              <div className="col">
                <label className="form-label">Nombre</label>
                <input
                  className="form-control"
                  name="nombre_solicitante_add"
                  type="text"
                  value={formCotizacionData.nombre_solicitante_add}
                  onChange={handleFormCotizacionData}
                />
              </div>
              <div className="col">
                <label className="form-label">Rut</label>
                <input
                  className="form-control"
                  name="rut_solicitante_add"
                  type="text"
                  value={formCotizacionData.rut_solicitante_add}
                  onChange={handleFormCotizacionData}
                />
              </div>
            </div>
            <div className="row row-cols-1 row-cols-md-2 g-4 mb-4">
              <div className="col">
                <label className="form-label">E-Mail</label>
                <input
                  className="form-control"
                  name="email_solicitante_add"
                  type="text"
                  value={formCotizacionData.email_solicitante_add}
                  onChange={handleFormCotizacionData}
                />
              </div>
              <div className="col">
                <label className="form-label">Observaciones</label>
                <input
                  className="form-control"
                  name="observaciones_add"
                  type="text"
                  value={formCotizacionData.observaciones_add}
                  onChange={handleFormCotizacionData}
                />
              </div>
            </div>
            <h5 className="mb-3">Detalle</h5>
            <div className="row row-cols-1 row-cols-md-2 g-4 mb-3">
              <div className="col">
                <label className="form-label">Fecha Estimada Inicio</label>
                <input
                  className="form-control"
                  name="fecha_estimada_inicio_add"
                  type="date"
                  value={formCotizacionData.fecha_estimada_inicio_add}
                  onChange={handleFormCotizacionData}
                />
              </div>
              <div className="col">
                <label className="form-label">Fecha Estimada Fin</label>
                <input
                  className="form-control"
                  name="fecha_estimada_fin_add"
                  type="date"
                  value={formCotizacionData.fecha_estimada_fin_add}
                  onChange={handleFormCotizacionData}
                />
              </div>
            </div>
            <div className="row row-cols-1">
              <div className="col">
                <label className="form-label" htmlFor="formFile">Documento PDF</label>
                <input
                  className="form-control"
                  name="imagen"
                  type="file"
                  id="formFile"
                  accept=".pdf"
                  onChange={(e) =>
                    handleFileChange(e.target.files[0])
                  }
                />
              </div>
            </div>
          </div>
          <div className="modal-footer gap-2">
            <a className="btn btn-outline-dark mNegativo" href="https://vittamaq.qa-gtalent.cl/api/plantillas_pdf/ANEXO.pdf" target="_blank"><i className="bi bi-file-earmark-text"></i> Ver Anexo</a>
            <button className="btn btn-yellow" onClick={(e) => handleConfirmaCotizacion(e, formCotizacionData.folio, formCotizacionData.email_solicitante_add)}><i className="bi bi-check-circle"></i> Confirmar</button>
          </div>
        </div>
      </div>
    );
  }

  function EditarCotizacionModalContent({ cabecera, servicios }) {
    const fechaInicioBD = cabecera.fecha_inicio;
    const fechaInicio = format(parse(fechaInicioBD, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd');
    const fechaFinBD = cabecera.fecha_fin;
    const fechaFin = format(parse(fechaFinBD, 'dd-MM-yyyy', new Date()), 'yyyy-MM-dd');

    const [serviciosData, setServiciosData] = useState([]);
    const navigate = useNavigate();
    
    useEffect(() => {
      obtenerServicios(cabecera.id_maquinaria);
    }, []);

    const obtenerServicios = async (idMaquinaria) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/servicio.php?id_maquinaria=${idMaquinaria}`
        );
        const data = await response.json();
  
        if (data && data[0].id_servicio) {
          const serviciosConGlosa = data.filter(servicio => 
            servicio.id_tipo_maquinaria === null && servicio.id_tipo_arriendo === null
          );
          setServiciosData(serviciosConGlosa);
        }
      } catch (error) {
        setServiciosData([]);
      }
    };

    const [formCotizacionEditData, setFormCotizacionEditData] = useState({
      folio: cabecera.folio,
      nombre_solicitante_edit: cabecera.nombre_solicitante || "",
      observaciones_edit: cabecera.observaciones || "",
      rut_solicitante_edit: cabecera.rut_solicitante || "",
      email_solicitante_edit: cabecera.email_solicitante || "",
      fecha_estimada_inicio_edit: fechaInicio || "",
      fecha_estimada_fin_edit: fechaFin || ""
    });

    const handleFormCotizacionEditData = (e) => {
      setFormCotizacionEditData({
        ...formCotizacionEditData,
        [e.target.name]: e.target.value,
      });
    };

    const [dataServiciosCotizacion, setServiciosCotizacionData] = useState({});
    const handleFormCotizacionServiciosData = (e, id_servicio, id_unidad_medida) => {
      const { name, value } = e.target;
      const inputFolioNombre = "folio_edit_" + id_servicio;
      const inputUnidadMedidaNombre = "id_unidad_medida_edit_" + id_servicio;

      setServiciosCotizacionData(prevData => ({
        ...prevData,
        [id_servicio]: {
          ...prevData[id_servicio],
          [inputFolioNombre]: cabecera.folio, 
          [name]: value,
          [inputUnidadMedidaNombre]: id_unidad_medida
        }
      }));
    };

    /* const optionsServicios = serviciosData.map((servicio) => ({
      value: servicio.id_servicio,
      label: servicio.glosa_servicio ? servicio.glosa_servicio : servicio.nombre_maquinaria + " - " + servicio.descripcion + " - " + servicio.tipo_arriendo
    })); */


    const [dataServiciosNuevosCotizacion, setServiciosNuevosCotizacionData] = useState({});
    const handleFormCotizacionServiciosNuevosData = (e, id_servicio, id_unidad_medida) => {
      const { name, value } = e.target;
      const inputFolioNombre = "folio_edit_" + id_servicio;
      const inputUnidadMedidaNombre = "id_unidad_medida_edit_" + id_servicio;

      setServiciosNuevosCotizacionData(prevData => ({
        ...prevData,
        [id_servicio]: {
          ...prevData[id_servicio],
          [inputFolioNombre]: cabecera.folio, 
          [name]: value,
          [inputUnidadMedidaNombre]: id_unidad_medida
        }
      }));
    };

    const [checkboxStates, setCheckboxStates] = useState({});
    const toggleCheckbox = (filaId, id_servicio, id_unidad_medida) => {
      setCheckboxStates((prevState) => ({
        ...prevState,
        [filaId]: !prevState[filaId],
      }));
      
      //Valores iniciales de cantidad minima y valor unitario neto
      const inputFolioNombre = "folio_edit_" + id_servicio;
      const inputUnidadMedidaNombre = "id_unidad_medida_edit_" + id_servicio;
      const inputCantidadMinimaNombre = "cantidad_minima_edit_" + id_servicio;
      const inputCantidadMinima = document.getElementById(inputCantidadMinimaNombre);
      const inputValorUnitarioNombre = "valor_unitario_neto_edit_" + id_servicio;
      const inputValorUnitario = document.getElementById(inputValorUnitarioNombre);
      const inputValorUnitarioPMNombre = "valor_unitario_neto_pm_edit_" + id_servicio;
      const inputValorUnitarioPM = document.getElementById(inputValorUnitarioPMNombre);
      const idUsuario = "id_usuario_" + id_servicio;

      // Comprobar si el atributo "disabled" está presente
      if (inputCantidadMinima && inputCantidadMinima.disabled) {
        //Agrega valores iniciales si el input se habilita
        setServiciosNuevosCotizacionData(prevData => ({
          ...prevData,
          [id_servicio]: {
            ...prevData[id_servicio],
            [inputFolioNombre]: cabecera.folio,
            [inputCantidadMinima.name]: inputCantidadMinima.value,
            [inputValorUnitario.name]: inputValorUnitario.value,
            [inputValorUnitarioPM.name]: inputValorUnitarioPM.value,
            [inputUnidadMedidaNombre]: id_unidad_medida,
            [idUsuario]: dataLogin.id_usuario
          }
        }));
      } else {
        //Elimina valores si el input se deshabilita
        const newData = { ...dataServiciosCotizacion };
        delete newData[id_servicio];
        setServiciosNuevosCotizacionData(newData);
      }
    };

    let serviciosAsignados;

    if (servicios && servicios.length > 0) {
      serviciosAsignados = servicios.map(servicio => ({
        folio: servicio.folio,
        id_servicio: servicio.id_servicio,
        descripcion: servicio.descripcion,
        id_maquinaria: servicio.id_maquinaria,
        nombre_maquinaria: servicio.nombre_maquinaria,
        id_tipo_maquinaria: servicio.id_tipo_maquinaria,
        tipo_maquinaria: servicio.tipo_maquinaria,
        tipo_arriendo: servicio.tipo_arriendo,
        cantidad_minima: servicio.cantidad_minima,
        id_unidad_medida: servicio.id_unidad_medida,
        unidad_medida: servicio.unidad_medida,
        valor_unitario_neto: servicio.valor_unitario_neto,
        valor_unitario_neto_pm: servicio.valor_unitario_neto_pm
      }));
    } else {
      serviciosAsignados = []; // Definir serviciosAsignados como un arreglo vacío
    }

    useEffect(() => {
      serviciosAsignados.forEach(servicio => {
        const {
          id_servicio,
          folio,
          cantidad_minima,
          valor_unitario_neto,
          valor_unitario_neto_pm,
          id_unidad_medida
        } = servicio;

        const inputFolioNombre = "folio_edit_" + id_servicio;
        const inputUnidadMedidaNombre = "id_unidad_medida_edit_" + id_servicio;
        const inputCantidadMinimaNombre = "cantidad_minima_edit_" + id_servicio;
        const inputValorUnitarioNombre = "valor_unitario_neto_edit_" + id_servicio;
        const inputValorUnitarioPMNombre = "valor_unitario_neto_pm_edit_" + id_servicio;
      
        setServiciosCotizacionData(prevData => ({
          ...prevData,
          [id_servicio]: {
            ...prevData[id_servicio],
            [inputFolioNombre]: folio,
            [inputCantidadMinimaNombre]: cantidad_minima,
            [inputValorUnitarioNombre]: valor_unitario_neto,
            [inputValorUnitarioPMNombre]: valor_unitario_neto_pm,
            [inputUnidadMedidaNombre]: id_unidad_medida
          }
        }));
      });
    }, []);


    const serviciosFiltrados = serviciosData.filter(
      (servicio) => !serviciosAsignados.find((asignado) => asignado.id_servicio === servicio.id_servicio)
    );
  
    // Combinar servicios asignados y servicios filtrados
    //const tablaServicios = [...serviciosAsignados, ...serviciosFiltrados];
    //console.log(JSON.stringify(tablaServicios, null, 2));

    const modificaLlavesServiCot = (objetoOriginal) => {
      const nuevoObjeto = {};

      Object.keys(objetoOriginal).forEach((clave) => {
        const nuevoNombreFolio = `folio`;
        const nuevoNombreCantidad = `cantidad_minima`;
        const nuevoNombreUnidadMedida = `id_unidad_medida`;
        const nuevoNombreValor = `valor_unitario_neto`;
        const nuevoNombreValorPM = `valor_unitario_neto_pm`;
        const nuevoNombreIdUsuario = `id_usuario`;
  
        nuevoObjeto[clave] = {
          [nuevoNombreFolio]: objetoOriginal[clave][`folio_edit_${clave}`],
          [nuevoNombreCantidad]: objetoOriginal[clave][`cantidad_minima_edit_${clave}`],
          [nuevoNombreUnidadMedida]: objetoOriginal[clave][`id_unidad_medida_edit_${clave}`],
          [nuevoNombreValor]: objetoOriginal[clave][`valor_unitario_neto_edit_${clave}`],
          [nuevoNombreValorPM]: objetoOriginal[clave][`valor_unitario_neto_pm_edit_${clave}`],
          [nuevoNombreIdUsuario]: objetoOriginal[clave][`id_usuario_${clave}`],
        };
      });

      return nuevoObjeto;
    };

    const handleSubmitEditCotizacion = async (event) => {
      event.preventDefault();

      try {
        const esFormularioValido = validaFormulario();

        if (esFormularioValido) {
          const esRutValido = validarRut(formCotizacionEditData.rut_solicitante_edit);
          const esEmailValido = validarEmail(formCotizacionEditData.email_solicitante_edit);

          if (!esRutValido) {
            alert("Rut No Válido");
          } else if (!esEmailValido) {
            alert("Email No Válido");
          } else {
            const responseEditCabeceraCotizacion = await fetch(
              `https://vittamaq.qa-gtalent.cl/api/cabecera_cotizacion.php`,
              {
                method: "PUT",
                headers: {
                  'Accept': 'application/json', // Indica que esperas una respuesta JSON
                  'Content-Type': 'application/json', 
                },
                body: JSON.stringify(formCotizacionEditData),
              }
            );
    
            const dataEditCabeceraCotizacion = await responseEditCabeceraCotizacion.json();
            //console.log(JSON.stringify(dataEditCabeceraCotizacion,null,2))
            if (dataEditCabeceraCotizacion.message === "Cabecera de cotización modificada con éxito.") {
              const objetoTransformado = modificaLlavesServiCot(dataServiciosCotizacion);
              const responseServiciosCotizacion = await fetch(
                `https://vittamaq.qa-gtalent.cl/api/detalle_cotizacion.php`,
                {
                  method: "PUT",
                  headers: {
                    'Accept': 'application/json', // Indica que esperas una respuesta JSON
                    'Content-Type': 'application/json', 
                  },
                  body: JSON.stringify(objetoTransformado),
                }
              );
    
              const dataEditServiciosCotizacion = await responseServiciosCotizacion.json();
              if (dataEditServiciosCotizacion.message === "Servicios de cotización modificados con éxito.") {
                const objetoTransformadoNuevo = modificaLlavesServiCot(dataServiciosNuevosCotizacion);
                const responseServiciosNuevosCotizacion = await fetch(
                  `https://vittamaq.qa-gtalent.cl/api/detalle_cotizacion.php`,
                  {
                    method: "POST",
                    headers: {
                      'Accept': 'application/json', // Indica que esperas una respuesta JSON
                      'Content-Type': 'application/json', 
                    },
                    body: JSON.stringify(objetoTransformadoNuevo),
                  }
                );
    
                const dataEditServiciosNuevosCotizacion = await responseServiciosNuevosCotizacion.json();
                if (dataEditServiciosNuevosCotizacion.message === "Detalle de cotización agregado con éxito.") {
                  setFormCotizacionEditData({
                    folio: "",
                    nombre_solicitante_edit: "",
                    rut_solicitante_edit: "",
                    email_solicitante_edit: "",
                    fecha_estimada_inicio_edit: "",
                    fecha_estimada_fin_edit: ""
                  });
        
                  alert("Cotización modificada con éxito.");
  
                  closeModal();
        
                  navigate("/dashboard", { state: dataLogin });
                }
              }
            } else {
              throw new Error();
            }
          }
        } 
      } catch (error) {
        console.log(error);
      }
    }

    const validaFormulario = () => {
      if (formCotizacionEditData.nombre_solicitante_edit === "") {
        alert('Debe ingresar un nombre de solicitante');
        return false;
      }

      if (formCotizacionEditData.rut_solicitante_edit === "") {
        alert('Debe ingresar un rut de solicitante');
        return false;
      }

      if (formCotizacionEditData.email_solicitante_edit === "") {
        alert('Debe ingresar un email de solicitante');
        return false;
      }

      if (formCotizacionEditData.fecha_estimada_inicio_edit === "") {
        alert('Debe ingresar una fecha estimada de inicio');
        return false;
      }
  
      return true;
    }
    
    //Filtro Servicios Cotización
    const [filtroServiciosCotizacion, setFiltroServiciosCotizacion] = useState('');
    const manejarCambioFiltro = (event) => {
      setFiltroServiciosCotizacion(event.target.value);
    };

    const validarRut = (rut) => {
      return validate(rut);
    }

    const validarEmail = (email) => {
      // Expresión regular para validar el formato del correo electrónico
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    return (
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Editar Cotización{/* ({cabecera.nombre_maquinaria}) */}: {cabecera.folio || ""} ({cabecera.fecha_cotizacion || ""})</h4>
            <button onClick={closeModal} className="btn-close"></button>
          </div>
          <div className="modal-body py-3 px-5">
            <div className="row row-cols-1 row-cols-md-2 g-4 mb-3">
              <div className="col">
                <span className="form-label d-block mb-1">Cliente</span>
                <select className="form-select" disabled>
                  <option value={cabecera.id_cliente}>{cabecera.nombre_cliente || ""}</option>
                </select>
              </div>
              <div className="col">
                <span className="form-label d-block mb-1">Obra</span>
                <select className="form-select" disabled>
                  <option value={cabecera.id_obra}>{cabecera.obra || ""}</option>
                </select>
              </div>
            </div>
            <div className="row row-cols-1 row-cols-md-2 g-4 mb-4">
              <div className="col">
                <span className="form-label d-block mb-1">Requerimiento</span>
                <select className="form-select" disabled>
                  <option value={cabecera.id_requerimiento}>{cabecera.nombre_requerimiento || ""}</option>
                </select>
              </div>
              <div className="col">
                <span className="form-label d-block mb-1">Cliente Final</span>
                <select className="form-select" disabled>
                  <option value={cabecera.id_cliente_final}>{cabecera.nombre_cliente_final || ""}</option>
                </select>
              </div>
            </div>
            <div className="row row-cols-1 row-cols-md-2 g-4 mb-4">
              <div className="col">
                <span className="form-label d-block mb-1">Maquinaria</span>
                <select className="form-select" disabled>
                  <option value={cabecera.id_maquinaria}>{cabecera.nombre_maquinaria || ""}</option>
                </select>
              </div>
              <div className="col">
                <span className="form-label d-block mb-1">Equipo</span>
                <select className="form-select" disabled>
                  <option value={cabecera.id_equipo}>{cabecera.equipo || ""}</option>
                </select>
              </div>
            </div>
            <div className="d-flex align-items-end justify-content-between mb-3">
              <h5 className="mb-0">Servicios</h5>
              <div className="input-group input-group-sm" style={{maxWidth: '210px'}}>
                <span className="input-group-text" id="search-servicios">
                  <i className="bi bi-search"></i>
                </span>
                <input type="text" className="form-control" placeholder="Servicio..." aria-describedby="search-servicios" onChange={manejarCambioFiltro}/>
              </div>
            </div>
            <div className="table-responsive border rounded mb-4">
              <table className="table table-hover table-sm mb-0">
                <thead>
                  <tr className="table-active border-bottom">
                    <th className="ps-2"></th>
                    <th>Nombre Servicio</th>
                    <th>Cantidad Mínima</th>
                    <th>Unidad Medida</th>
                    <th>Valor Unitario Neto + IVA</th>
                    {cabecera.id_maquinaria === 1 && (
                      <th scope="col" className="pe-3">Valor Unitario Neto PM</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {serviciosAsignados.length > 0 ? (
                    serviciosAsignados.map((servicio, index) => (
                      <tr key={servicio.id_servicio}>
                      <td className="ps-2"></td>
                      <td>
                      {/*  {
                          servicio.descripcion !== null && servicio.descripcion !== '' ? (
                            servicio.descripcion
                          ) : (
                            servicio.nombre_maquinaria + " - " + servicio.tipo_maquinaria + " - " + servicio.tipo_arriendo 
                          )
                        } */}
                        {
                          servicio.descripcion !== null && servicio.descripcion !== '' ? (
                            servicio.descripcion
                          ) : (
                            servicio.id_maquinaria !== 1 && servicio.id_maquinaria !== 2 && servicio.id_tipo_maquinaria === 0 ? (
                              servicio.nombre_maquinaria + " - " + servicio.tipo_arriendo
                            ) : (
                              servicio.nombre_maquinaria + (servicio.tipo_maquinaria === "Ninguno" ? " - " + servicio.tipo_arriendo : " - " + servicio.tipo_maquinaria + " - " + servicio.tipo_arriendo)
                            )
                          )
                        }
                      </td>
                      <td>
                        <input 
                          className="form-control form-control-sm"
                          type="text"
                          name={"cantidad_minima_edit_" + servicio.id_servicio}
                          id={"cantidad_minima_edit_" + servicio.id_servicio}
                          value={
                            dataServiciosCotizacion[servicio.id_servicio]?.["cantidad_minima_edit_" + servicio.id_servicio] !== undefined
                              ? dataServiciosCotizacion[servicio.id_servicio]?.["cantidad_minima_edit_" + servicio.id_servicio]
                              : Math.trunc(servicio.cantidad_minima)
                          }
                          onChange={(e) => handleFormCotizacionServiciosData(e, servicio.id_servicio.toString(), servicio.id_unidad_medida.toString())}
                        />
                      </td>
                      <td>
                        {servicio.unidad_medida}
                      </td>
                      <td>
                        <input 
                          className="form-control form-control-sm"
                          type="text"
                          name={"valor_unitario_neto_edit_" + servicio.id_servicio}
                          id={"valor_unitario_neto_edit_" + servicio.id_servicio}
                          value={
                            dataServiciosCotizacion[servicio.id_servicio]?.["valor_unitario_neto_edit_" + servicio.id_servicio] !== undefined
                              ? dataServiciosCotizacion[servicio.id_servicio]?.["valor_unitario_neto_edit_" + servicio.id_servicio]
                              : servicio.valor_unitario_neto
                          }
                          onChange={(e) => handleFormCotizacionServiciosData(e, servicio.id_servicio.toString(), servicio.id_unidad_medida.toString())}
                        />
                      </td>
                      {servicio.id_maquinaria === 1 && servicio.id_tipo_maquinaria != null && (servicio.id_unidad_medida === 1 || servicio.id_unidad_medida === 7) ? (
                        <td>
                          <input 
                            className="form-control form-control-sm"
                            type="text"
                            name={"valor_unitario_neto_pm_edit_" + servicio.id_servicio}
                            id={"valor_unitario_neto_pm_edit_" + servicio.id_servicio}
                            value={
                              dataServiciosCotizacion[servicio.id_servicio]?.["valor_unitario_neto_pm_edit_" + servicio.id_servicio] !== undefined
                                ? dataServiciosCotizacion[servicio.id_servicio]?.["valor_unitario_neto_pm_edit_" + servicio.id_servicio]
                                : servicio.valor_unitario_neto_pm
                            }
                            onChange={(e) => handleFormCotizacionServiciosData(e, servicio.id_servicio.toString(), servicio.id_unidad_medida.toString())}
                          />
                        </td>
                      ) : servicio.id_maquinaria != 1 ? (
                        null
                      ) : (
                        <td>
                          <input
                            className="form-control form-control-sm"
                            id={"valor_unitario_neto_pm_edit_" + servicio.id_servicio}
                            name={"valor_unitario_neto_pm_edit_" + servicio.id_servicio}
                            type="text"
                            value={0}
                            hidden
                            readOnly
                          />
                        </td>
                      )}
                    </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="p-0 border-bottom-0" colSpan={5}></td>
                    </tr>
                  )}
                  {serviciosFiltrados.map((servicio, index) => (
                    <tr key={servicio.id_servicio}>
                        <td className="ps-2">
                          <div className="form-check">
                            <input 
                              className="form-check-input" 
                              type="checkbox" 
                              checked={checkboxStates[index] || false}
                              onChange={() => toggleCheckbox(index, servicio.id_servicio, servicio.id_unidad_medida.toString())}
                            />
                          </div>
                        </td>
                        <td>
                          {
                            servicio.glosa_servicio !== null && servicio.glosa_servicio !== '' ? (
                              servicio.glosa_servicio
                            ) : (
                              servicio.nombre_maquinaria + " - " + servicio.descripcion + " - " + servicio.tipo_arriendo 
                            )
                          }
                        </td>
                        <td>
                          <input 
                            className="form-control form-control-sm"
                            type="text"
                            name={"cantidad_minima_edit_" + servicio.id_servicio}
                            id={"cantidad_minima_edit_" + servicio.id_servicio}
                            value={
                              dataServiciosNuevosCotizacion[servicio.id_servicio]?.["cantidad_minima_edit_" + servicio.id_servicio] !== undefined
                                ? dataServiciosNuevosCotizacion[servicio.id_servicio]?.["cantidad_minima_edit_" + servicio.id_servicio]
                                : Math.trunc(servicio.cantidad_minima)
                            }
                            onChange={(e) => handleFormCotizacionServiciosNuevosData(e, servicio.id_servicio.toString(), servicio.id_unidad_medida.toString())}
                            disabled={!checkboxStates[index]}
                          />
                        </td>
                        <td>
                          {servicio.unidad_medida}
                        </td>
                        <td>
                          <input 
                            className="form-control form-control-sm"
                            type="text"
                            name={"valor_unitario_neto_edit_" + servicio.id_servicio}
                            id={"valor_unitario_neto_edit_" + servicio.id_servicio}
                            value={
                              dataServiciosNuevosCotizacion[servicio.id_servicio]?.["valor_unitario_neto_edit_" + servicio.id_servicio] !== undefined
                                ? dataServiciosNuevosCotizacion[servicio.id_servicio]?.["valor_unitario_neto_edit_" + servicio.id_servicio]
                                : servicio.valor_unitario_neto
                            }
                            onChange={(e) => handleFormCotizacionServiciosNuevosData(e, servicio.id_servicio.toString(), servicio.id_unidad_medida.toString())}
                            disabled={!checkboxStates[index]}
                          />
                        </td>
                        {servicio.id_maquinaria === 1 && servicio.id_tipo_maquinaria != null && servicio.id_unidad_medida === 1 ? (
                          <td>
                            <input
                              className="form-control form-control-sm"
                              id={"valor_unitario_neto_pm_edit_" + servicio.id_servicio}
                              name={"valor_unitario_neto_pm_edit_" + servicio.id_servicio}
                              type="text"
                              value={
                                dataServiciosNuevosCotizacion[servicio.id_servicio]?.["valor_unitario_neto_pm_edit_" + servicio.id_servicio] !== undefined
                                  ? dataServiciosNuevosCotizacion[servicio.id_servicio]?.["valor_unitario_neto_pm_edit_" + servicio.id_servicio]
                                  : servicio.valor_unitario_neto_pm
                              }
                              onChange={(e) => handleFormCotizacionServiciosNuevosData(e, servicio.id_servicio.toString(), servicio.id_unidad_medida.toString())}
                              disabled={!checkboxStates[index]}
                            />
                          </td>
                        ) : (
                          <td>
                            <input
                              className="form-control form-control-sm"
                              id={"valor_unitario_neto_pm_edit_" + servicio.id_servicio}
                              name={"valor_unitario_neto_pm_edit_" + servicio.id_servicio}
                              type="text"
                              value={0}
                              hidden
                              readOnly
                            />
                          </td>
                        )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <h5 className="mb-3">Información de Solicitante</h5>
            <div className="row row-cols-1 row-cols-md-2 g-4 mb-3">
              <div className="col">
                <label className="form-label">Nombre</label>
                <input 
                  className="form-control" 
                  type="text" 
                  name="nombre_solicitante_edit" 
                  value={formCotizacionEditData.nombre_solicitante_edit}
                  onChange={handleFormCotizacionEditData}
                />
              </div>
              <div className="col">
                <label className="form-label">Rut</label>
                <input 
                  className="form-control" 
                  type="text" 
                  name="rut_solicitante_edit" 
                  value={formCotizacionEditData.rut_solicitante_edit}
                  onChange={handleFormCotizacionEditData}
                />
              </div>
            </div>
            <div className="row row-cols-1 row-cols-md-2 g-4 mb-4">
              <div className="col">
                <label className="form-label">E-Mail</label>
                <input 
                  className="form-control" 
                  type="text" 
                  name="email_solicitante_edit" 
                  value={formCotizacionEditData.email_solicitante_edit}
                  onChange={handleFormCotizacionEditData}
                />
              </div>
              <div className="col">
                <label className="form-label">Observaciones</label>
                <input 
                  className="form-control" 
                  type="text" 
                  name="observaciones_edit" 
                  value={formCotizacionEditData.observaciones_edit}
                  onChange={handleFormCotizacionEditData}
                />
              </div>
            </div>
            <h5 className="mb-3">Detalle</h5>
            <div className="row row-cols-1 row-cols-md-2 g-4 mb-3">
              <div className="col">
                <label className="form-label">Fecha Estimada Inicio</label>
                <input 
                  className="form-control" 
                  type="date" 
                  name="fecha_estimada_inicio_edit" 
                  value={formCotizacionEditData.fecha_estimada_inicio_edit}
                  onChange={handleFormCotizacionEditData}
                />
              </div>
              <div className="col">
                <label className="form-label">Fecha Estimada Fin</label>
                <input 
                  className="form-control" 
                  type="date" 
                  name="fecha_estimada_fin_edit" 
                  value={formCotizacionEditData.fecha_estimada_fin_edit}
                  onChange={handleFormCotizacionEditData}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer flex-row">
            <button className="btn btn-yellow" onClick={handleSubmitEditCotizacion}><i className="bi bi-floppy"></i> Guardar</button>
          </div>
        </div>
      </div>
    );
  }

  function CotizacionModalContent({ data }) {
    const [estadosCotizacion, setEstadosCotizacion] = useState([]);
    const [estadoCotizacion, setEstadoCotizacion] = useState(data.id_estado_cotizacion);
    const [detalleCotizacion, setdetalleCotizacion] = useState([]);

    useEffect(() => {
      obtenerEstadosCotizacion();
      obtenerDetalleCotizacion(data.folio);
    }, []);

    const obtenerEstadosCotizacion = async () => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/estado_cotizacion.php`
        );
        const data = await response.json();
        
        if (data && data[0].id_estado_cotizacion) {
          setEstadosCotizacion(data);
        }
      } catch (error) {
        setEstadosCotizacion([]);
      }
    };

    const obtenerDetalleCotizacion = async (folio) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/detalle_cotizacion.php?folio=${folio}`
        );
        const data = await response.json();

        if (data && data[0].folio) {
          setdetalleCotizacion(data);
        }
      } catch (error) {
        setdetalleCotizacion([]);
      }
    };

    const [pdfBytes, setPdfBytes] = useState(null);
    const crearDescargarPDFCotizacion = async () => {
      const existingPdfBytes = await fetch('https://vittamaq.qa-gtalent.cl/api/obtienePlantillaCotizacion.php').then((res) => res.arrayBuffer());
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
  
      // Obtener el formulario del PDF
      const form = pdfDoc.getForm();
  
      // Cabecera Cotización
      form.getTextField('Folio').setText(data.folio);
      form.getTextField('Nombre_Cliente').setText(data.nombre_cliente);
      form.getTextField('Rut_Cliente').setText(formatearRut(data.rut_cliente));
      form.getTextField('Razon_Social').setText(data.razon_social);
      form.getTextField('Giro_Cliente').setText(data.giro);
      form.getTextField('Direccion_Cliente').setText(data.direccion);
      form.getTextField('Obra_Cliente').setText(data.obra);
      form.getTextField('Direccion_Obra').setText(data.direccion_obra);

      form.getTextField('Fecha_Cotizacion').setText(data.fecha_cotizacion);
      form.getTextField('Contacto').setText(data.nombre_contacto);
      form.getTextField('Cargo_Contacto').setText(data.cargo_contacto);
      form.getTextField('Telefono_Contacto').setText(data.telefono_contacto);
      form.getTextField('Email_Contacto').setText(data.email_contacto);
      form.getTextField('Observaciones').setText(data.observaciones ? data.observaciones : "");

      form.getTextField('Equipo').setText(
        `${data.nombre_maquinaria} - ${data.equipo} (${data.nombre_requerimiento})`
      );

      let pmInserted = false;

      //const primerServicio = detalleCotizacion[0];
      // Servicios Cotización
      detalleCotizacion.forEach((detalle, index) => {
        form.getTextField(`Item_Orden_${index + 1}`).setText(detalle.item_orden.split('-')[1]);
        if(detalle.descripcion !== "") {
          form.getTextField(`Descripcion_${index + 1}`).setText(detalle.descripcion);
        } else {
          form.getTextField(`Descripcion_${index + 1}`).setText(detalle.nombre_maquinaria + " " + detalle.tipo_maquinaria + " " + detalle.tipo_arriendo);
          /* form.getTextField('Tipo_Maquinaria').setText(
            `${detalle.nombre_maquinaria} ${primerServicio.tipo_maquinaria === "null" || primerServicio.tipo_maquinaria === "Ninguno" ? "" : primerServicio.tipo_maquinaria}`
          ); */
        }
        form.getTextField(`Cantidad_Minima_${index + 1}`).setText(detalle.cantidad_minima);
        form.getTextField(`Unidad_Medida_${index + 1}`).setText(detalle.unidad_medida);

        if (detalle.id_maquinaria === 1) {
          form.getTextField(`Valor_Unitario_Neto_${index + 1}`).setText(detalle.valor_unitario_neto + " UF");
        } else if (detalle.id_maquinaria === 2) {
          form.getTextField(`Valor_Unitario_Neto_${index + 1}`).setText(String("$ " + parseInt(detalle.valor_unitario_neto).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.')));
        } else {
          form.getTextField(`Valor_Unitario_Neto_${index + 1}`).setText(String(parseFloat(detalle.valor_unitario_neto).toFixed(2) + " UF"));
        }

        if (detalle.id_maquinaria === 1 && !pmInserted) {
          form.getTextField(`Valor_Unitario_Neto_PM_${index + 1}`).setText(detalle.valor_unitario_neto_pm + " UF (PM)");
          pmInserted = true;
        }
      });

      // Guardar el PDF modificado
      const modifiedPdfBytes = await pdfDoc.save();
  
      // Actualizar el estado para que React renderice el PDF modificado
      setPdfBytes(modifiedPdfBytes);

      guardarPDF(modifiedPdfBytes, data.folio);

      // Crear un objeto Blob con los bytes del PDF modificado
      const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });

      // Crear una URL para el Blob
      const urlBlob = URL.createObjectURL(blob);

      // Crear un enlace <a> para descargar el PDF
      const link = document.createElement('a');
      link.href = urlBlob;

      link.download = data.folio + '.pdf';

      // Agregar el enlace al documento
      document.body.appendChild(link);

      // Simular un clic en el enlace para iniciar la descarga
      link.click();

      // Eliminar el enlace después de la descarga
      document.body.removeChild(link);
    };

    const crearDescargarPDFAdjunto = async () => {
      function base64ToArrayBuffer(base64) {
        const binaryString = window.atob(base64); // Decodificar la cadena base64
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
      }

      // Convertir el base64 a un ArrayBuffer
      const arrayBuffer = base64ToArrayBuffer(data.imagen);

      // Crear un objeto Blob con los bytes del PDF modificado
      const blob = new Blob([arrayBuffer], { type: 'application/pdf' });

      // Crear una URL para el Blob
      const urlBlob = URL.createObjectURL(blob);

      // Crear un enlace <a> para descargar el PDF
      const link = document.createElement('a');
      link.href = urlBlob;

      link.download = data.folio + '_Adjunto.pdf';

      // Agregar el enlace al documento
      document.body.appendChild(link);

      // Simular un clic en el enlace para iniciar la descarga
      link.click();

      // Eliminar el enlace después de la descarga
      document.body.removeChild(link);
    };

    const guardarPDF = async (modifiedPdfBytes, folio) => {
      const formData = new FormData();
      formData.append('pdf', new Blob([modifiedPdfBytes], { type: 'application/pdf' }), folio);
    
      try {
        const response = await fetch('https://vittamaq.qa-gtalent.cl/api/guardar_pdf.php', {
          method: 'POST',
          body: formData,
        });
    
        if (response.ok) {
          const responseData = await response.text();
          //console.log('Respuesta del servidor:', responseData);
        } else {
          console.error('Error al enviar el PDF al servidor:', response.status);
        }
      } catch (error) {
        console.error('Error de red:', error);
      }
    };

    const obtenerEstadosPago = async (folio, idMaquinaria) => {
      try {
        let response;
        switch (idMaquinaria) {
          case 1:
            response = await fetch(
              `https://vittamaq.qa-gtalent.cl/api/estado_pago_bh.php?folio_ep=${folio}&id_maquinaria${idMaquinaria}`
            );
            break;
          case 2:
            response = await fetch(
              `https://vittamaq.qa-gtalent.cl/api/estado_pago_gh.php?folio_ep=${folio}&id_maquinaria${idMaquinaria}`
            );
            break;
          case 3:
            response = await fetch(
              `https://vittamaq.qa-gtalent.cl/api/estado_pago_gt.php?folio_ep=${folio}&id_maquinaria${idMaquinaria}`
            );
            break;
          default:
            alert("Tipo de maquinaria no reconocido.");
            return;
        }

        const data = await response.json();
    
        if (data && data[0].folio) {  
          return data;
        } else {
          return [];
        }
      } catch (error) {
        return [];
      }
    }

    const handleModificaEstado = async (data_cotizacion, id_estado_cotizacion) => {
      setEstadoCotizacion(id_estado_cotizacion);

      if (id_estado_cotizacion === '7') {
        const estadosPago = await obtenerEstadosPago(data_cotizacion.folio, data_cotizacion.id_maquinaria);
        const estadosPagofinalizados = estadosPago.every(estado_pago => estado_pago.id_estado_pago_estado === 3);

        if (estadosPago.length > 0 && estadosPagofinalizados) {
          setSecondModalOpen(true);
          setSecondModalContent(<ConfirmaModificaEstadoModalContent dataCotizacion={data_cotizacion} detalleCotizacion={detalleCotizacion} idEstadoCotizacion={id_estado_cotizacion} dataEstadosPago={estadosPago} idRequerimiento={data.id_requerimiento} />);
        } else {
          alert('Para finalizar una cotización se deben finalizar sus estados de pago asociados.');
        }
      } else {
        setSecondModalOpen(true);
        setSecondModalContent(<ConfirmaModificaEstadoModalContent dataCotizacion={data_cotizacion} detalleCotizacion={detalleCotizacion} idEstadoCotizacion={id_estado_cotizacion} />);
      }
    };

    function ConfirmaModificaEstadoModalContent ({ dataCotizacion, detalleCotizacion, idEstadoCotizacion, dataEstadosPago, idRequerimiento }) {
      const obtenerEstadoPorId = (idEstadoCotizacion) => {
        const estadoEncontrado = estadosCotizacion.find(estado => estado.id_estado_cotizacion === idEstadoCotizacion);
        return estadoEncontrado ? estadoEncontrado.estado : 'Estado no encontrado';
      };

      const estado = obtenerEstadoPorId(Number(idEstadoCotizacion));

      return (
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="mb-0">Modificar Estado: {dataCotizacion.folio}</h4>
              <button onClick={closeSecondModal} className="btn-close"></button>
            </div>
            <div className="modal-body">
              <p className="fs-5">¿Está seguro que desea modificar el estado de la cotización a: <span className="text-green">{estado}</span>?</p>
            </div>
            <div className="modal-footer">
              <button className="btn btn-success" onClick={(e) => handleSubmitModificarEstado(e, dataCotizacion.folio, idEstadoCotizacion, idRequerimiento)}><i className="bi bi-dash-circle"></i> Modificar Estado</button>
            </div>
          </div>
        </div>
      );
    }

    const handleSubmitModificarEstado = async (event, folio, idEstadoCotizacion, idRequerimiento) => {
      event.preventDefault();
  
      try {
        const formModificarEstado = { folio: folio, id_estado_cotizacion: idEstadoCotizacion };

        const responseModificarEstado = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/cabecera_cotizacion.php`,
          {
            method: "PUT",
            headers: {
              'Accept': 'application/json', // Indica que esperas una respuesta JSON
              'Content-Type': 'application/json', 
            },
            body: JSON.stringify(formModificarEstado),
          }
        );

        const dataModificarEstado = await responseModificarEstado.json();
        //console.log(JSON.stringify(dataModificarEstado,null,2))
        if (dataModificarEstado.message === "Estado de cotización modificado con éxito.") {

          if(idEstadoCotizacion  === '7') {
            const formFinalizarRequerimiento = {
              id_requerimiento: idRequerimiento
            };
      
            const responseFinalizarRequerimiento = await fetch(
              `https://vittamaq.qa-gtalent.cl/api/requerimiento.php?finaliza=1`,
              {
                method: "PUT",
                headers: {
                  'Accept': 'application/json', // Indica que esperas una respuesta JSON
                  'Content-Type': 'application/json', 
                },
                body: JSON.stringify(formFinalizarRequerimiento),
              }
            );

            const dataFinalizarRequerimiento = await responseFinalizarRequerimiento.json();
            if (dataFinalizarRequerimiento.message === "Requerimiento finalizado con éxito.") {
              obtenerRequerimientos();
            }
          }

          alert("Estado de cotización modificado con éxito.");
          
          obtenerCotizaciones();

          closeSecondModal();
          closeModal();

          navigate("/dashboard", { state: dataLogin });
        } else {
          throw new Error();
        }
      } catch (error) {
        obtenerCotizaciones();
      }
    }

    /* Confirmación de reenvío de cotización */
    const handleConfirmaReenviaCotizacion = (e, folio, email) => {
      setSecondModalOpen(true);
      setSecondModalContent(<ConfirmaReenviaCotizacionModalContent folio={folio} email={email} />);
    };

    function ConfirmaReenviaCotizacionModalContent ({ folio, email }) {
      /* Agregar CC */
      const [mostrarTablaCC, setMostrarTablaCC] = useState(true);
      const [mostrarBotonCC, setMostrarBotonCC] = useState(true);

      const handleRadioChange = (event) => {
        setMostrarTablaCC(event.target.value === "1");

        setMostrarBotonCC(!mostrarBotonCC);

        const nuevaFila = { ...filaPredeterminada, id: 1 };
        setFilas([nuevaFila]);
      }
      
      const filaPredeterminada = { 
        id: 1, 
        email: ''
      };

      const [filas, setFilas] = useState([filaPredeterminada]);

      const handleAgregarFila = () => {
        let siguienteId = 1;

        while (filas.some(fila => fila.id === siguienteId)) {
          siguienteId++;
        }

        const nuevaFila = {
          id: siguienteId,
          email: ''
        };

        setFilas([...filas, nuevaFila]);
      };

      const handleInputChange = (id, campo, valor) => {
        const nuevasFilas = filas.map((fila) =>
          fila.id === id ? { ...fila, [campo]: valor } : fila
        );

        setFilas(nuevasFilas);
        //console.log(JSON.stringify(filas, null, 2));
      };

      const handleEliminarFila = (id) => {
        // Verificar que haya más de una fila antes de eliminar
        if (filas.length > 1) {
          const nuevasFilas = filas.filter((fila) => fila.id !== id);
          setFilas(nuevasFilas);
        } else {
          alert("Debe agregar al menos un email, de lo contrario seleccione 'NO'.");
        }
      };

      return (
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="mb-0">Reenviar Cotización</h4>
              <button onClick={closeSecondModal} className="btn-close"></button>
            </div>
            <div className="modal-body">
              <p className="fs-5 mb-2">¿Está seguro que desea reenviar la cotización: <span className="text-orange">{folio}</span>?</p>
              <p>Se modificará su estado a "Enviada" y se enviará mediante correo electrónico a la dirección indicada: <span className="text-orange">{email}.</span></p>
              <p>¿Requiere copiar cotización a otras direcciones de correo electrónico (CC)?</p>
              <div className="d-flex justify-content-md-between">
                <div>
                  <input
                    type="radio" 
                    className="btn-check" 
                    name="email_cc" 
                    id="email-cc-si" 
                    autoComplete="off" 
                    value="1"
                    checked={mostrarTablaCC}
                    onChange={handleRadioChange}
                  />
                  <label className="btn" htmlFor="email-cc-si">Si</label>
                  <input 
                    type="radio" 
                    className="btn-check" 
                    name="email_cc" 
                    id="email-cc-no" 
                    autoComplete="off"
                    value="0"
                    checked={!mostrarTablaCC}
                    onChange={handleRadioChange}
                  />
                  <label className="btn" htmlFor="email-cc-no">No</label>
                </div>
                {mostrarBotonCC && (
                  <button type="button" className="btn btn-dark btn-sm" onClick={handleAgregarFila}>
                    <i className="bi-plus-circle"></i> Agregar
                  </button>
                )}
              </div>
              {mostrarTablaCC && (
                <div className="mt-3">
                  <table className="table mb-0">
                    <thead>
                      <tr className="table-active">
                        <th>Email</th>
                        <th>Eliminar</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filas.map((fila, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              className="form-control form-control-sm"
                              type="text"
                              id={"email_" + index}
                              value={fila.email}
                              onChange={(e) =>
                                handleInputChange(fila.id, 'email', e.target.value)
                              }
                            />
                          </td>
                          <td>
                            <button className="btn btn-outline-danger btn-sm" onClick={() => handleEliminarFila(fila.id)}>
                              <i className="bi bi-trash3"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button className="btn btn-yellow" onClick={(e) => handleSubmitReenviaCotizacion(e, mostrarBotonCC, filas)}><i className="bi bi-send"></i> Reenviar</button>
            </div>
          </div>
        </div>
      );
    }

    const [pdfBytesReenviar, setPdfBytesReenviar] = useState(null);
    const crearReenviarPDFCotizacion = async () => {
      const existingPdfBytes = await fetch('https://vittamaq.qa-gtalent.cl/api/obtienePlantillaCotizacion.php').then((res) => res.arrayBuffer());
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
  
      // Obtener el formulario del PDF
      const form = pdfDoc.getForm();
  
      // Cabecera Cotización
      form.getTextField('Folio').setText(data.folio);
      form.getTextField('Nombre_Cliente').setText(data.nombre_cliente);
      form.getTextField('Rut_Cliente').setText(formatearRut(data.rut_cliente));
      form.getTextField('Razon_Social').setText(data.razon_social);
      form.getTextField('Giro_Cliente').setText(data.giro);
      form.getTextField('Direccion_Cliente').setText(data.direccion);
      form.getTextField('Obra_Cliente').setText(data.obra);
      form.getTextField('Direccion_Obra').setText(data.direccion_obra);

      form.getTextField('Fecha_Cotizacion').setText(data.fecha_cotizacion);
      form.getTextField('Contacto').setText(data.nombre_contacto);
      form.getTextField('Cargo_Contacto').setText(data.cargo_contacto);
      form.getTextField('Telefono_Contacto').setText(data.telefono_contacto);
      form.getTextField('Email_Contacto').setText(data.email_contacto);
      form.getTextField('Observaciones').setText(data.observaciones ? data.observaciones : "");

      form.getTextField('Equipo').setText(
        `${data.nombre_maquinaria} - ${data.equipo} (${data.nombre_requerimiento})`
      );

      let pmInserted = false;
      // Servicios Cotización
      detalleCotizacion.forEach((detalle, index) => {
        form.getTextField(`Item_Orden_${index + 1}`).setText(detalle.item_orden.split('-')[1]);
        if(detalle.descripcion !== "") {
          form.getTextField(`Descripcion_${index + 1}`).setText(detalle.descripcion);
        } else {
          form.getTextField(`Descripcion_${index + 1}`).setText(detalle.nombre_maquinaria + " " + detalle.tipo_maquinaria + " " + detalle.tipo_arriendo);
        }
        form.getTextField(`Cantidad_Minima_${index + 1}`).setText(detalle.cantidad_minima);
        form.getTextField(`Unidad_Medida_${index + 1}`).setText(detalle.unidad_medida);

        if (detalle.id_maquinaria === 1) {
          form.getTextField(`Valor_Unitario_Neto_${index + 1}`).setText(detalle.valor_unitario_neto + " UF");
        } else if (detalle.id_maquinaria === 2) {
          form.getTextField(`Valor_Unitario_Neto_${index + 1}`).setText(String("$ " + parseInt(detalle.valor_unitario_neto).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.')));
        } else {
          form.getTextField(`Valor_Unitario_Neto_${index + 1}`).setText(String(parseFloat(detalle.valor_unitario_neto).toFixed(2) + " UF"));
        }

        if (detalle.id_maquinaria === 1 && !pmInserted) {
          form.getTextField(`Valor_Unitario_Neto_PM_${index + 1}`).setText(detalle.valor_unitario_neto_pm + " UF (PM)");
          pmInserted = true;
        }
      });

      // Guardar el PDF modificado
      const modifiedPdfBytes = await pdfDoc.save();

      // Convierte los bytes en base64
      const arrayBufferToBase64 = (buffer) => {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
      };

      // Convertir a base64 el PDF modificado y añadirlo al Email como adjunto
      const pdfBase64 = arrayBufferToBase64(modifiedPdfBytes);
      //formCorreo.adjunto[0].Base64 = pdfBase64;

      const adjuntoCotizacion = {
        NombreAdjunto: "Cotización Vittamaq",
        TipoDocumento: "pdf",
        Base64: pdfBase64
      };

      //Condiciones Generales
      const existingPdfBytesCG = await fetch(`https://vittamaq.qa-gtalent.cl/api/obtienePlantillaCondicionesGenerales.php?id_maquinaria=${data.id_maquinaria}`).then((res) => res.arrayBuffer());

      const pdfDocCG = await PDFDocument.load(existingPdfBytesCG);
  
      // Obtener el formulario del PDF
      const formCG = pdfDocCG.getForm();
  
      formCG.getTextField('Folio').setText(data.folio);

      const modifiedPdfBytesCG = await pdfDocCG.save();

      const pdfBase64CG = arrayBufferToBase64(modifiedPdfBytesCG);

      const adjuntoCondGen = {
        NombreAdjunto: "Condiciones Generales",
        TipoDocumento: "pdf",
        Base64: pdfBase64CG
      };

      if (data.imagen) {
        const adjuntoDocumento = {
          NombreAdjunto: "Documento Vittamaq",
          TipoDocumento: "pdf",
          Base64: data.imagen
        };

        formCorreo.adjuntos = [adjuntoCotizacion, adjuntoCondGen, adjuntoDocumento];
      } else {
        formCorreo.adjuntos = [adjuntoCotizacion, adjuntoCondGen];
      }
  
      // Actualizar el estado para que React renderice el PDF modificado
      setPdfBytesReenviar(modifiedPdfBytes);

      // Crear un objeto Blob con los bytes del PDF modificado
      const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
      guardarPDF(blob, data.folio);

      handleEnviarCorreo();
    };

    /* Envío de Correo: Cotización */
    const [formCorreo, setFormCorreo] = useState({
      para: data.email_solicitante,
      asunto: "Cotización: " + data.folio,
      cliente: data.nombre_solicitante,
      razon_social: data.razon_social,
      texto_cuerpo: "A continuación, adjuntamos la cotización detallada que hemos preparado para proporcionarles una oferta que se ajuste a sus necesidades y expectativas. Por favor, revisar y no dude en ponerse en contacto con nosotros si tiene alguna pregunta o necesita más información.",
      cc: {},
    });

    const handleEnviarCorreo = async () => {
      try {
        closeSecondModal();
        closeModal();

        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/envio_correos.php`,
          {
            method: "POST",
            headers: {
              'Accept': 'application/json', // Indica que esperas una respuesta JSON
              'Content-Type': 'application/json', 
            },
            body: JSON.stringify(formCorreo),
          }
        );
  
        const dataResponse = await response.json();
        if (dataResponse.message === "El correo electrónico ha sido enviado correctamente.") {
          const estadoData = [{folio: data.folio, id_estado_cotizacion: 2}];
          const responseCambioEstado = await fetch(
            `https://vittamaq.qa-gtalent.cl/api/cabecera_cotizacion.php`,
            {
              method: "PUT",
              headers: {
                'Accept': 'application/json', // Indica que esperas una respuesta JSON
                'Content-Type': 'application/json', 
              },
              body: JSON.stringify(estadoData),
            }
          );

          const dataResponseCambioEstado = await responseCambioEstado.json();
          if (dataResponseCambioEstado.message === "Estado de cotización modificado con éxito.") {
            alert("Se ha reenviado exitosamente la cotización por correo electrónico.");

            navigate("/dashboard", { state: dataLogin });
          }
          
        }
      } catch (error) {
        alert("Hubo un error al enviar el correo electrónico");
      }
    };

    //Valida Email
    const validarEmail = (email) => {
      // Expresión regular para validar el formato del correo electrónico
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    const handleSubmitReenviaCotizacion = async (event, mostrarTablaCC, filas) => {
      event.preventDefault();

      try {
        const validaEmail = filas.every(item => validarEmail(item.email));

        if (mostrarTablaCC && !validaEmail) {
          alert('Debe ingresar el o los email y comprobar si están correctos');
        } else if (!mostrarTablaCC) {
          formCorreo.cc = [];
          crearReenviarPDFCotizacion();
        } else {
          formCorreo.cc = filas;
          crearReenviarPDFCotizacion();
        }
      } catch (error) {
        //alert(error);
        console.log("Error");
      }
    }
    
    return (
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Detalle Cotización{/*  ({data.nombre_maquinaria}) */}</h4>
            <button onClick={closeModal} className="btn-close"></button>
          </div>
          <div className="modal-body">
            <div className="row row-cols-md-2 mb-4">
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Folio</span>
                    <span className="label">{data.folio || ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Fecha Cotización</span>
                    <span className="label">{data.fecha_cotizacion || ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Obra</span>
                    <span className="label">{data.obra || ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Maquinaria</span>
                    <span className="label">{data.nombre_maquinaria || ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Equipo</span>
                    <span className="label">{data.equipo === "null null" ? "" : data.equipo}</span>
                  </li>
                </ul>
              </div>
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Cliente</span>
                    <span className="label">{data.nombre_cliente || ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Rut</span>
                    <span className="label">{formatearRut(data.rut_cliente) || ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Cliente Final</span>
                    <span className="label">{data.nombre_cliente_final || ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Contacto</span>
                    <span className="label">{data.email_contacto || ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Fecha Inicio | Fin</span>
                    <span className="label">{data.fecha_inicio || ""} | {data.fecha_fin || ""}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row row-cols-lg-2">
              <div className="col">
                <h5 className="mb-3">Solicitante</h5>
                <ul className="list-group mb-4">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Nombre</span>
                    <span className="label">{data.nombre_solicitante || ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Rut</span>
                    <span className="label">{formatearRut(data.rut_solicitante) || ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Email</span>
                    <span className="label">{data.email_solicitante || ""}</span>
                  </li>
                </ul>
              </div>
              <div className="col">
                <h5 className="mb-3">Requerimiento</h5>
                <ul className="list-group mb-3">
                  <li className="list-group-item">
                    <span className="label">{data.nombre_requerimiento || ""}</span>
                  </li>
                </ul>
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0 me-3">Observaciones</span>
                    <span className="label text-end">{data.observaciones || ""}</span>
                  </li>
                </ul>
              </div>
            </div>
            <h5 className="mb-3">Servicios</h5>
            <div className="table-responsive border rounded">
              <table className="table table-hover mb-0">
                <thead>
                  <tr className="table-active border-bottom">
                    <th scope="col" className="ps-3">Item</th>
                    <th scope="col">Descripción</th>
                    <th scope="col">Cantidad Mínima</th>
                    <th scope="col">Unidad Medida</th>
                    <th scope="col">
                      Valor Unitario Neto más IVA
                      {data.id_maquinaria === 2 ? " ($)" : " (UF)"}
                    </th>
                    {data.id_maquinaria === 1 && (
                      <th scope="col" className="pe-3">Valor Unitario Neto PM (UF)</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {detalleCotizacion ? (
                    detalleCotizacion.map((detalle, index) => (
                      <tr key={index}>
                        <td className="ps-3">
                          {detalle.item_orden.split('-')[1]}
                        </td>
                        <td>
                          {
                            detalle.descripcion !== null && detalle.descripcion !== '' ? (
                              detalle.descripcion
                            ) : (
                              detalle.id_maquinaria !== 1 && detalle.id_maquinaria !== 2 && detalle.id_tipo_maquinaria === 0 ? (
                                detalle.nombre_maquinaria + " - " + detalle.tipo_arriendo
                              ) : (
                                detalle.nombre_maquinaria + (detalle.tipo_maquinaria === "Ninguno" ? " - " + detalle.tipo_arriendo : " - " + detalle.tipo_maquinaria + " - " + detalle.tipo_arriendo)
                              )
                            )
                          }
                        </td>
                        <td>{Math.trunc(detalle.cantidad_minima)}</td>
                        <td>{detalle.unidad_medida}</td>
                        <td>
                          {
                            detalle.id_maquinaria === 1 ? (
                              detalle.valor_unitario_neto
                            ) : detalle.id_maquinaria === 2 ? (
                              parseInt(detalle.valor_unitario_neto).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.')
                            ) : (
                              parseFloat(detalle.valor_unitario_neto).toFixed(2)
                            )
                          }
                        </td>
                        {detalle.id_maquinaria === 1 && detalle.id_tipo_maquinaria != null && (detalle.id_unidad_medida === 1 || detalle.id_unidad_medida === 7) ? (
                          <td className="pe-3">{detalle.valor_unitario_neto_pm}</td>
                        ) : detalle.id_maquinaria != 1 ?(
                          null
                        ) : <td></td> }
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="text-center" colSpan={4}>No se encontraron servicios asociados</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer justify-content-between">
            <div className="d-flex gap-2">
              <button className="btn btn-outline-primary" onClick={crearDescargarPDFCotizacion}><i className="bi bi-download"></i> Descargar Cotización</button>
              {data.imagen && (
                <button className="btn btn-outline-primary" onClick={crearDescargarPDFAdjunto}><i className="bi bi-download"></i> Descargar Adjunto</button>
              )}
            </div>
            <div className="d-flex gap-2">
              {dataLogin.id_perfil === 1 && (
                <select className="form-select form-select-width" name="id_estado_cotizacion" value={estadoCotizacion} onChange={(event) => handleModificaEstado(data, event.target.value)}>
                  {estadosCotizacion.map((estado) => (
                    <option key={estado.id_estado_cotizacion} value={estado.id_estado_cotizacion}>{estado.estado}</option>
                  ))}
                </select>
              )}
              <button className="btn btn-outline-dark" onClick={(e) => handleConfirmaReenviaCotizacion(e, data.folio, data.email_solicitante)}><i className="bi bi-send"></i> Reenviar</button>
              <button className="btn btn-dark" onClick={() => handleEditButtonClick("Cotización", data, detalleCotizacion)}><i className="bi bi-pencil-square"></i> Editar</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function AgregarEstadoPagoModalContent() {
    const [clientes, setClientes] = useState([]);
    useEffect(() => {
      obtenerClientes();
    }, []);

    const obtenerClientes = async () => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/cliente.php`
        );
        const data = await response.json();

        if (data && data[0].id_cliente) {
          setClientes(data);
        }
      } catch (error) {
        setClientes([]);
      }
    };

    const [dataEstadoPago, setEstadoPagoData] = useState({
      id_folio_ep: "",
      id_obra_ep: "",
      id_cliente_ep: "",
      id_cliente_final_ep: "",
      m3_bombeado_uf: "",
      m3_bombeado_valor: "",
      valor_m3: "",
      aditivo_uf: "",
      aditivo_valor: "",
      valor_traslado_bomba_uf: "",
      valor_traslado_bomba: "",
      sobre_estadia_uf: "",
      sobre_estadia_valor: "",
      otros_descuentos_ep: 0,
      detalle_otros_descuentos_ep: "",
      total_neto: "",
      iva: "",
      total: "",
      id_usuario: ""
    });

    const handleFormEstadoPagoData = (e) => {
      const { name, value } = e.target;

      if (name === "orden_compra_ep") {
        if (/^\d*$/.test(value)) {
          setEstadoPagoData({
            ...dataEstadoPago,
            [name]: value,
          });
        }
      } else {
        setEstadoPagoData({
          ...dataEstadoPago,
          [name]: value,
        });
      }

      if (name === "id_folio_ep") {
        setDataConsultaReports({
          ...dataConsultaReports,
          ["folio"]: value,
        });

        obtenerServicios(value);
      }

      //console.log(JSON.stringify(dataDetalleReportGH));
    };

    const [esServicioMensual, setEsServicioMensual] = useState(false);
    const obtenerServicios = async (folio) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/detalle_cotizacion.php?folio=${folio}`
        );
        const data = await response.json();
        
        if (data && data[0].id_servicio) {
          const serviciosMensuales = [10, 11, 12, 13];
          const esMensual = serviciosMensuales.includes(data[0].id_servicio);
          setEsServicioMensual(esMensual);
        }
      } catch (error) {
        setEsServicioMensual('');
      }
    };

    const [clienteSelected, setClienteSelected] = useState('');
    const handleClienteSelect = async (clienteSelected) => {
      setClienteSelected(clienteSelected.value);
      await obtenerClienteObra(clienteSelected.value);

      setEstadoPagoData({
        ...dataEstadoPago,
        ["id_cliente_ep"]: clienteSelected.value,
        ["id_cliente_final_ep"]: clienteSelected.value,
        ["id_usuario"]: dataLogin.id_usuario
      });
    };

    const optionsClientes = clientes.map((cliente) => ({
      value: cliente.id_cliente,
      label: cliente.nombre_cliente,
    }));
    
    const handleObraSelect = async (obraSelected) => {
      await obtenerFolios(obraSelected);

      setDataConsultaReports({
        ...dataConsultaReports,
        ["id_obra_ep"]: obraSelected,
      });

      setEstadoPagoData({
        ...dataEstadoPago,
        ["id_obra_ep"]: obraSelected,
      });
    };

    const [clienteObra, setclienteObra] = useState([]);
    const obtenerClienteObra = async (cliente) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/cliente_obra.php?id_cliente=${cliente}`
        );
        const data = await response.json();
  
        if (data && data[0].id_obra) {
          setclienteObra(data);
          /* dataEstadoPago.id_cliente_ep = data[0].id_cliente;
          dataEstadoPago.id_cliente_final_ep = data[0].id_cliente_final; */
        }
      } catch (error) {
        setclienteObra([]);
      }
    };

    const [folios, setFolios] = useState([]);
    const obtenerFolios = async (obra) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/cabecera_cotizacion.php?id_obra=${obra}&id_maquinaria=1`
        );
        const data = await response.json();
  
        if (data && data[0].folio) {
          setFolios(data);
        }
      } catch (error) {
        setFolios([]);
      }
    };

    const [dataConsultaReports, setDataConsultaReports] = useState({
      id_obra_ep: "",
      folio: "",
      fecha_desde_ep: "",
      fecha_hasta_ep: ""
    });

    const [dataReports, setDataReports] = useState([]);
    const handleSubmitBuscarReports = async (event) => {
      event.preventDefault();

      try {
        const esFormularioValido = validaFormulario();

        if (esFormularioValido) {
          const idObra = dataConsultaReports.id_obra_ep;
          const folio = dataConsultaReports.folio;
          const fechaDesde = dataConsultaReports.fecha_desde_ep;
          const fechaHasta = dataConsultaReports.fecha_hasta_ep;
  
          const response = await fetch(
            `https://vittamaq.qa-gtalent.cl/api/estado_pago_bh.php?id_obra=${idObra}&folio=${folio}&fecha_desde=${fechaDesde}&fecha_hasta=${fechaHasta}`
          );
          const data = await response.json();
          if (data && data[0].id_report_bh) {
            //Suma M3
            const sumaPorId = {};
  
            data.forEach((item) => {
              const { id_report_bh, m3 } = item;
              sumaPorId[id_report_bh] = (sumaPorId[id_report_bh] || 0) + parseFloat(m3);
            });

            const resultadoFormateado = Object.keys(sumaPorId).map((id_report_bh) => {
              const registros = data.filter((item) => item.id_report_bh === parseInt(id_report_bh, 10));
              const m3 = registros.reduce((total, registro) => total + (parseFloat(registro.m3) || 0), 0);
            
              // Diferencia en horas, minutos y segundos
              const tiempoSolicitado = new Date(`1970-01-01 ${registros[0].hora_solicitada_bombeo}`);
              const tiempoInicio = new Date(`1970-01-01 ${registros[0].hora_inicio_bombeo}`);
              const diferenciaEnMilisegundos = tiempoInicio - tiempoSolicitado;
            
              const diferenciaEnHoras = Math.floor(diferenciaEnMilisegundos / (1000 * 60 * 60));
              const diferenciaEnMinutos = Math.floor((diferenciaEnMilisegundos % (1000 * 60 * 60)) / (1000 * 60));
              const diferenciaEnSegundos = Math.floor((diferenciaEnMilisegundos % (1000 * 60)) / 1000);
            
              // Formatear la diferencia en horas, minutos y segundos
              const diferenciaFormateada = `${String(diferenciaEnHoras).padStart(2, '0')}:${String(diferenciaEnMinutos).padStart(2, '0')}:${String(diferenciaEnSegundos).padStart(2, '0')}`;

              const horarioArray = diferenciaFormateada.split(':');
              const horas = parseInt(horarioArray[0], 10);
              const minutos = parseInt(horarioArray[1], 10);
              const segundos = parseInt(horarioArray[2], 10);
            
              const minutosTotales = horas * 60 + minutos + segundos / 60;

              const sobreEstadiaUF = Math.floor(minutosTotales / 120);

              //Obtener día y mes report
              const partesFecha = registros[0].fecha_report.split('-');
              const date = new Date(`${partesFecha[2]}-${partesFecha[1]}-${partesFecha[0]}`);
          
              const diasSemana = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
              const nombreMeses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
          
              const diaSemana = diasSemana[date.getDay()];
              const nombreMes = nombreMeses[date.getMonth()];
            
              return {
                id_report_bh: parseInt(id_report_bh, 10),
                bomba: registros[0].bomba,
                fecha_report: registros[0].fecha_report,
                dia: diaSemana,
                mes: nombreMes,
                m3: isNaN(m3) || m3 === 0 ? 30 : m3,
                valor_uf: registros[0].valor_uf,
                valor_m3_uf: registros[0].valor_m3_uf,
                aditivo: registros[0].aditivo,
                valor_traslado_bomba_uf: registros[0].valor_traslado_bomba,
                sobre_estadia_hora: diferenciaFormateada /* > "02:00:00" ? diferenciaFormateada : "00:00:00" */,
                sobre_estadia_uf: sobreEstadiaUF
              };
            });

            const ufHoy = Number((indicadorUF / 1000).toFixed(5));
            //console.log(ufHoy);
            
            const resultadoFinal = resultadoFormateado.map(item => ({
              id_report_bh: item.id_report_bh,
              bomba: item.bomba,
              fecha_report: item.fecha_report,
              dia: item.dia,
              mes: item.mes,
              //m3_bombeados: item.m3.toFixed(2),
              m3_bombeados: Number(item.m3.toFixed(2)) < Number(30).toFixed(2) ? Number(30).toFixed(2) : item.m3.toFixed(2), 
              valor_m3_uf: item.valor_m3_uf,
              //valor_total_uf: (item.m3.toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.') * item.valor_m3_uf).toFixed(2),
              valor_total_uf: esServicioMensual ? 0 : (Number(Number(item.m3.toFixed(2)) < Number(30).toFixed(2) ? Number(30).toFixed(2) : item.m3.toFixed(2)).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.') * item.valor_m3_uf).toFixed(2),
              valor_uf: item.valor_uf,
              //valor_total_bombeo_neto: ((item.m3 * item.valor_m3_uf) * Number((item.valor_uf / 1000).toFixed(5))).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 3 }).replace(/,/g, '.'),
              valor_total_bombeo_neto: esServicioMensual ? 0 : ((Number(Number(item.m3.toFixed(2)) < Number(30).toFixed(2) ? Number(30).toFixed(2) : item.m3.toFixed(2)) * item.valor_m3_uf) * Number((item.valor_uf / 1000).toFixed(5))).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 3 }).replace(/,/g, '.'),
              aditivo: item.aditivo,
              valor_traslado_bomba_uf: item.valor_traslado_bomba_uf,
              sobre_estadia_hora: item.sobre_estadia_hora,
              sobre_estadia_uf: item.sobre_estadia_uf
            }));

            //console.log(JSON.stringify(resultadoFinal,null,2))
            
            setDataReports(resultadoFinal);
          } else {
            setDataReports([]);
          }
        }
      } catch (error) {
        setDataReports([]);
        alert("No se encontró información para los filtros indicados.");
      }
    }

    const [checkboxStates, setCheckboxStates] = useState({});
    const [formReportsData, setFormReportsData] = useState({});

    const [dataTotales, setDataTotales] = useState({
      m3_bombeados_suma: 0,
      valor_total_bombeo_neto_suma: 0,
      aditivo: 0,
      aditivo_valor: 0,
      horometro_uf: 0,
      horometro_valor: 0,
      sobre_estadia_uf: 0,
      sobre_estadia_valor: 0,
      total_neto: 0,
      iva: 0,
      total: 0
    });

    const [selectAll, setSelectAll] = useState(false);
    const toggleCheckbox = (index, id_report_bh, m3_bombeados, valor_m3_uf, valor_uf, aditivo, valor_traslado_bomba_uf, sobre_estadia_hora, sobre_estadia_uf, valor_total_bombeo_neto) => {
      setTotalNetoInicial(0);

      setCheckboxStates((prevState) => {
        const newState = {
          ...prevState,
          [index]: !prevState[index],
        };
    
        setFormReportsData((prevData) => {
          // Si el checkbox está desmarcado, elimina el elemento del estado
          if (!newState[index]) {
            setDataReports(prevReports =>
              prevReports.map(report => 
                report.id_report_bh === id_report_bh
                  ? { ...report, aditivo: aditivo, valor_traslado_bomba_uf: valor_traslado_bomba_uf, sobre_estadia_hora: sobre_estadia_hora, sobre_estadia_uf: sobre_estadia_uf, id_usuario: dataLogin.id_usuario }
                  : report
              )
            );

            const { [id_report_bh]: _, ...restData } = prevData;
            return restData;
          }
    
          // Si el checkbox está marcado o se está volviendo a marcar, actualiza el estado
          return {
            ...prevData,
            [id_report_bh]: {
              ...prevData[id_report_bh],
              ["id_report_bh"]: id_report_bh,
              ["m3_bombeados"]: m3_bombeados,
              ["valor_m3"]: Number(valor_m3_uf).toFixed(3),
              ["valor_uf_total"]: esServicioMensual ? 0 : (m3_bombeados * Number(valor_m3_uf)).toFixed(2),
              ["valor_uf"]: valor_uf,
              ["valor_total_bombeo_neto"]: esServicioMensual ? 0 : valor_total_bombeo_neto,
              ["aditivo"]: aditivo,
              ["valor_traslado_bomba_uf"]: valor_traslado_bomba_uf,
              ["sobre_estadia_hora"]: sobre_estadia_hora,
              ["sobre_estadia_uf"]: sobre_estadia_uf,
              ["id_estado_pago_bh"]: 0,
              ["id_usuario"]: dataLogin.id_usuario
            },
          };
        });
    
        return newState;
      });
    };    

    //Checkbox seleccionar todos los reports BH
    const handleSelectAll = () => {
      const newSelectAll = !selectAll;
      setSelectAll(newSelectAll);
  
      dataReports.forEach((report, index) => {
        if (checkboxStates[index] !== newSelectAll) {
          toggleCheckbox(
            index,
            report.id_report_bh,
            report.m3_bombeados,
            report.valor_m3_uf,
            report.valor_uf,
            report.aditivo,
            report.valor_traslado_bomba_uf,
            report.sobre_estadia_hora,
            report.sobre_estadia_uf,
            report.valor_total_bombeo_neto
          );
        }
      });
    };

    useEffect(() => {
      //console.log(JSON.stringify(formReportsData,null,2));
      //Cálculo de valores con fecha del último día de arriendo (Servicio Mensual)
      let UFultimoDiaMes = 0;
      if(dataConsultaReports.fecha_hasta_ep != '') {
        const [year, month, day] = dataConsultaReports.fecha_hasta_ep.split('-');
        const fechaUltimoDiaEP = `${day}-${month}-${year}`;

        const { serie } = UFmensual;
  
        const fechaActualIndicadorUF = serie.find(item => {
          const itemDate = new Date(item.fecha).toLocaleDateString('es-CL');
          return itemDate === fechaUltimoDiaEP;
        });

        UFultimoDiaMes = Number(fechaActualIndicadorUF.valor / 1000);
      }

      const suma = Object.values(formReportsData).reduce(
        (accumulator, item) => {
          let valorTotalBombeoNeto = esServicioMensual ? 0 : item.valor_total_bombeo_neto.replace(/\./g, '');

          accumulator.m3_bombeados += esServicioMensual ? parseFloat(item.valor_m3) : parseFloat(item.m3_bombeados);
          accumulator.valor_m3 = esServicioMensual ? 0 : parseFloat(item.valor_m3);
          accumulator.valor_total_bombeo_neto += esServicioMensual ? parseFloat(UFultimoDiaMes * item.valor_m3).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '') : parseFloat(valorTotalBombeoNeto);
          accumulator.valor_uf = parseFloat(item.valor_uf);
          accumulator.aditivo += parseFloat(item.aditivo);
          accumulator.valor_traslado_bomba_uf += parseFloat(item.valor_traslado_bomba_uf);
          accumulator.sobre_estadia_uf += parseFloat(item.sobre_estadia_uf);
          return accumulator;
        },
        { m3_bombeados: 0, valor_m3: 0, valor_total_bombeo_neto: 0, valor_uf: 0, aditivo: 0, valor_traslado_bomba_uf: 0, sobre_estadia_uf: 0 }
      );

      const ufHoy = Number((indicadorUF / 1000).toFixed(5));

      const nuevoArreglo = Object.entries(formReportsData).map(([id, item]) => ({
        valor_uf: Number((item.valor_uf / 1000).toFixed(5)),
        m3_bombeados_suma: esServicioMensual ? Number(item.valor_m3).toFixed(2) : suma.m3_bombeados.toFixed(2),
        valor_total_bombeo_neto_suma: esServicioMensual ? Number(Number(item.valor_m3) * UFultimoDiaMes).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.') : suma.valor_total_bombeo_neto.toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.'),
        aditivo: suma.aditivo.toFixed(2),
        aditivo_valor: Number(suma.aditivo * 5500).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.'),
        valor_traslado_bomba_uf: suma.valor_traslado_bomba_uf === "" ? (0).toFixed(4) : parseFloat(suma.valor_traslado_bomba_uf).toFixed(4),
        valor_traslado_bomba: Number(suma.valor_traslado_bomba_uf * Number((item.valor_uf / 1000).toFixed(5))).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.'),
        sobre_estadia_uf: isNaN(suma.sobre_estadia_uf) || suma.sobre_estadia_uf === "" ? (0).toFixed(4) : parseFloat(suma.sobre_estadia_uf).toFixed(4),
        sobre_estadia_valor: parseFloat(suma.sobre_estadia_uf * (suma.valor_m3 * Number((item.valor_uf / 1000).toFixed(5)))).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.'),
        total_neto: esServicioMensual ? 
          Number(Number(Number(UFultimoDiaMes * item.valor_m3).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '')) + Number((suma.aditivo * 5500).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '')) + Number((suma.valor_traslado_bomba_uf * Number((item.valor_uf / 1000).toFixed(5))).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '')) + Number((suma.sobre_estadia_uf * parseFloat(suma.sobre_estadia_uf * (suma.valor_m3 * Number((item.valor_uf / 1000).toFixed(5))))).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, ''))).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.')
          : Number(Number(suma.valor_total_bombeo_neto) + Number((suma.aditivo * 5500).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '')) + Number((suma.valor_traslado_bomba_uf * Number((item.valor_uf / 1000).toFixed(5))).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '')) + Number((suma.sobre_estadia_uf * parseFloat(suma.sobre_estadia_uf * (suma.valor_m3 * Number((item.valor_uf / 1000).toFixed(5))))).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, ''))).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.'),
        iva: esServicioMensual ? 
          Number(Number(Number(Number(UFultimoDiaMes * item.valor_m3).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '')) + Number((suma.aditivo * 5500).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '')) + Number((suma.valor_traslado_bomba_uf * Number((item.valor_uf / 1000).toFixed(5))).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '')) + Number((suma.sobre_estadia_uf * parseFloat(suma.sobre_estadia_uf * (suma.valor_m3 * Number((item.valor_uf / 1000).toFixed(5))))).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, ''))) * 0.19).toLocaleString('es-ES', {minimumFractionDigits: 0,maximumFractionDigits: 0,})
          : Number(Number(Number(suma.valor_total_bombeo_neto) + Number((suma.aditivo * 5500).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '')) + Number((suma.valor_traslado_bomba_uf * Number((item.valor_uf / 1000).toFixed(5))).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '')) + Number((suma.sobre_estadia_uf * parseFloat(suma.sobre_estadia_uf * (suma.valor_m3 * Number((item.valor_uf / 1000).toFixed(5))))).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, ''))) * 0.19).toLocaleString('es-ES', {minimumFractionDigits: 0,maximumFractionDigits: 0,}),
        total: esServicioMensual ?
          Number(Number(Number(Number(UFultimoDiaMes * item.valor_m3).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '')) + Number((suma.aditivo * 5500).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '')) + Number((suma.valor_traslado_bomba_uf * Number((item.valor_uf / 1000).toFixed(5))).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '')) + Number((suma.sobre_estadia_uf * parseFloat(suma.sobre_estadia_uf * (suma.valor_m3 * Number((item.valor_uf / 1000).toFixed(5))))).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, ''))) * 1.19).toLocaleString('es-ES', {minimumFractionDigits: 0,maximumFractionDigits: 0,})
          : Number(Number(Number(suma.valor_total_bombeo_neto) + Number((suma.aditivo * 5500).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '')) + Number((suma.valor_traslado_bomba_uf * Number((item.valor_uf / 1000).toFixed(5))).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '')) + Number((suma.sobre_estadia_uf * parseFloat(suma.sobre_estadia_uf * (suma.valor_m3 * Number((item.valor_uf / 1000).toFixed(5))))).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, ''))) * 1.19).toLocaleString('es-ES', {minimumFractionDigits: 0,maximumFractionDigits: 0,})
      }));

      const resultadosUnicos = Array.from(
        new Set(nuevoArreglo.map(JSON.stringify)),
        JSON.parse
      );
    
      setDataTotales(resultadosUnicos);

      let valorTrasladoBomba;
      let sobreEstadiaValor;
      let totalNeto;
      let iva;
      let total;

      if (resultadosUnicos.length > 0) {
        valorTrasladoBomba = Number(suma.valor_traslado_bomba_uf * resultadosUnicos[0].valor_uf).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '');
        sobreEstadiaValor = Number(suma.sobre_estadia_uf * (suma.valor_m3 * resultadosUnicos[0].valor_uf)).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '');
        totalNeto = esServicioMensual ? 
          Number(Number(resultadosUnicos[0].valor_total_bombeo_neto_suma.replace(/[\.,]/g, '')) + Number((suma.aditivo * 5500).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '')) + Number((suma.valor_traslado_bomba_uf * Number(resultadosUnicos[0].valor_uf)).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '')) + Number((suma.sobre_estadia_uf * parseFloat(suma.sobre_estadia_uf * (suma.valor_m3 * Number(Number(resultadosUnicos[0].valor_uf))))).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, ''))).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.')
          : Number(Number(suma.valor_total_bombeo_neto) + Number((suma.aditivo * 5500).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '')) + Number((suma.valor_traslado_bomba_uf * Number(resultadosUnicos[0].valor_uf)).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '')) + Number((suma.sobre_estadia_uf * parseFloat(suma.sobre_estadia_uf * (suma.valor_m3 * Number(Number(resultadosUnicos[0].valor_uf))))).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, ''))).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.');
        iva = esServicioMensual ?
          Number(Number(Number(resultadosUnicos[0].valor_total_bombeo_neto_suma.replace(/[\.,]/g, '')) + Number((suma.aditivo * 5500).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '')) + Number((suma.valor_traslado_bomba_uf * Number(Number(resultadosUnicos[0].valor_uf))).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '')) + Number((suma.sobre_estadia_uf * parseFloat(suma.sobre_estadia_uf * (suma.valor_m3 * Number(Number(resultadosUnicos[0].valor_uf))))).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, ''))) * 0.19).toLocaleString('es-ES', {minimumFractionDigits: 0,maximumFractionDigits: 0,})
          : Number(Number(Number(suma.valor_total_bombeo_neto) + Number((suma.aditivo * 5500).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '')) + Number((suma.valor_traslado_bomba_uf * Number(Number(resultadosUnicos[0].valor_uf))).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '')) + Number((suma.sobre_estadia_uf * parseFloat(suma.sobre_estadia_uf * (suma.valor_m3 * Number(Number(resultadosUnicos[0].valor_uf))))).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, ''))) * 0.19).toLocaleString('es-ES', {minimumFractionDigits: 0,maximumFractionDigits: 0,});
        total = esServicioMensual ?
          Number(Number(Number(resultadosUnicos[0].valor_total_bombeo_neto_suma.replace(/[\.,]/g, '')) + Number((suma.aditivo * 5500).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '')) + Number((suma.valor_traslado_bomba_uf * Number(Number(resultadosUnicos[0].valor_uf))).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '')) + Number((suma.sobre_estadia_uf * parseFloat(suma.sobre_estadia_uf * (suma.valor_m3 * Number(Number(resultadosUnicos[0].valor_uf))))).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, ''))) * 1.19).toLocaleString('es-ES', {minimumFractionDigits: 0,maximumFractionDigits: 0,})
          : Number(Number(Number(suma.valor_total_bombeo_neto) + Number((suma.aditivo * 5500).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '')) + Number((suma.valor_traslado_bomba_uf * Number(Number(resultadosUnicos[0].valor_uf))).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '')) + Number((suma.sobre_estadia_uf * parseFloat(suma.sobre_estadia_uf * (suma.valor_m3 * Number(Number(resultadosUnicos[0].valor_uf))))).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, ''))) * 1.19).toLocaleString('es-ES', {minimumFractionDigits: 0,maximumFractionDigits: 0,});
      }

      setEstadoPagoData({
        ...dataEstadoPago,
        ["m3_bombeado_uf"]: suma.m3_bombeados.toFixed(2),
        ["m3_bombeado_valor"]: suma.valor_total_bombeo_neto,
        ["valor_m3"]: suma.valor_m3,
        ["aditivo_uf"]: suma.aditivo.toFixed(2),
        ["aditivo_valor"]: (suma.aditivo * 5500).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, ''),
        ["valor_traslado_bomba_uf"]: suma.valor_traslado_bomba_uf === "" ? (0).toFixed(2) : parseFloat(suma.valor_traslado_bomba_uf).toFixed(2),
        ["valor_traslado_bomba"]: valorTrasladoBomba ? valorTrasladoBomba.replace(/\./g, '') : 0,
        ["sobre_estadia_uf"]: isNaN(suma.sobre_estadia_uf) || suma.sobre_estadia_uf === "" ? (0).toFixed(2) : parseFloat(suma.sobre_estadia_uf).toFixed(2),
        ["sobre_estadia_valor"]: sobreEstadiaValor ? sobreEstadiaValor.replace(/\./g, '') : 0,
        ["total_neto"]: totalNeto ? totalNeto.replace(/\./g, '') : 0,
        ["iva"]: iva ? iva.replace(/\./g, '') : 0,
        ["total"]: total ? total.replace(/\./g, '') : 0
      });
    }, [formReportsData]);

    const [isCheckedValorTrasladoBomba, setCheckedValorTrasladoBomba] = useState(true);
    const [isCheckedSobreEstadia, setCheckedSobreEstadia] = useState(true);

    const [dataOtrosDescuentos, setOtrosDescuentos] = useState(0);
    const [dataTotalNetoInicial, setTotalNetoInicial] = useState(0);

    const handleCheckboxValorTrasladoBombaChange = (valor_traslado_bomba, total_neto) => {
      setCheckedValorTrasladoBomba(!isCheckedValorTrasladoBomba);

      if(isCheckedValorTrasladoBomba) {
        const totalNeto = Number(total_neto.replace(/\./g, '')) - Number(valor_traslado_bomba.replace(/\./g, ''));
        setTotalNetoInicial(totalNeto);

        dataTotales[0].total_neto = Number(Number(total_neto.replace(/\./g, '')) - Number(valor_traslado_bomba.replace(/\./g, ''))).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.');
        dataTotales[0].iva = Number(Number(Number(total_neto.replace(/\./g, '')) - Number(valor_traslado_bomba.replace(/\./g, ''))) * 0.19).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/,/g, '.');
        dataTotales[0].total = Number(Number(Number(total_neto.replace(/\./g, '')) - Number(valor_traslado_bomba.replace(/\./g, ''))) * 1.19).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/,/g, '.');
      } else {
        const totalNeto = Number(total_neto.replace(/\./g, '')) + Number(valor_traslado_bomba.replace(/\./g, ''));
        setTotalNetoInicial(totalNeto);

        dataTotales[0].total_neto = Number(Number(total_neto.replace(/\./g, '')) + Number(valor_traslado_bomba.replace(/\./g, ''))).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.');
        dataTotales[0].iva = Number(Number(Number(total_neto.replace(/\./g, '')) + Number(valor_traslado_bomba.replace(/\./g, ''))) * 0.19).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/,/g, '.');
        dataTotales[0].total = Number(Number(Number(total_neto.replace(/\./g, '')) + Number(valor_traslado_bomba.replace(/\./g, ''))) * 1.19).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/,/g, '.');
      }
    };

    const handleCheckboxSobreEstadia = (sobre_estadia_valor, total_neto) => {
      setCheckedSobreEstadia(!isCheckedSobreEstadia);

      const totalNeto = Number(total_neto.replace(/\./g, '')) - Number(sobre_estadia_valor.replace(/\./g, ''));
      setTotalNetoInicial(totalNeto);

      if(isCheckedSobreEstadia) {
        dataTotales[0].total_neto = Number(Number(total_neto.replace(/\./g, '')) - Number(sobre_estadia_valor.replace(/\./g, ''))).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.');
        dataTotales[0].iva = Number(Number(Number(total_neto.replace(/\./g, '')) - Number(sobre_estadia_valor.replace(/\./g, ''))) * 0.19).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/,/g, '.');
        dataTotales[0].total = Number(Number(Number(total_neto.replace(/\./g, '')) - Number(sobre_estadia_valor.replace(/\./g, ''))) * 1.19).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/,/g, '.');
      } else {
        dataTotales[0].total_neto = Number(Number(total_neto.replace(/\./g, '')) + Number(sobre_estadia_valor.replace(/\./g, ''))).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.');
        dataTotales[0].iva = Number(Number(Number(total_neto.replace(/\./g, '')) + Number(sobre_estadia_valor.replace(/\./g, ''))) * 0.19).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/,/g, '.');
        dataTotales[0].total = Number(Number(Number(total_neto.replace(/\./g, '')) + Number(sobre_estadia_valor.replace(/\./g, ''))) * 1.19).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/,/g, '.');
      }
    };
    
    const handleOtrosDescuentos = (otro_descuento, total_neto_valor) => {
      if (/^\d*$/.test(otro_descuento)) {
        const nuevoDescuento = Number(otro_descuento) || 0;
        setOtrosDescuentos(nuevoDescuento);

        if (dataTotalNetoInicial === 0) {
          const totalNeto = Number(total_neto_valor.replace(/\./g, ''));
          setTotalNetoInicial(totalNeto);

          let totalNetoMostrado = nuevoDescuento === 0 ? totalNeto : totalNeto - nuevoDescuento;
          dataTotales[0].total_neto = totalNetoMostrado.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/,/g, '.');
          dataTotales[0].iva = Number(Number(totalNetoMostrado * 0.19).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '')).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.');
          dataTotales[0].total = Number(Number(totalNetoMostrado * 1.19).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '')).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.');

          setEstadoPagoData({
            ...dataEstadoPago,
            ["otros_descuentos_ep"]: nuevoDescuento,
            ["total_neto"]: totalNetoMostrado.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/[\.,]/g, ''),
            ["iva"]: Number(Number(totalNetoMostrado * 0.19).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '')).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, ''),
            ["total"]: Number(Number(totalNetoMostrado * 1.19).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '')).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '')
          });
        } else {
          let totalNetoConDescuento = dataTotalNetoInicial - nuevoDescuento;
          let totalNetoMostrado = nuevoDescuento === 0 ? dataTotalNetoInicial : totalNetoConDescuento;
          dataTotales[0].total_neto = totalNetoMostrado.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/,/g, '.');
          dataTotales[0].iva = Number(Number(totalNetoMostrado * 0.19).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '')).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.');
          dataTotales[0].total = Number(Number(totalNetoMostrado * 1.19).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '')).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.');

          setEstadoPagoData({
            ...dataEstadoPago,
            ["otros_descuentos_ep"]: nuevoDescuento,
            ["total_neto"]: totalNetoMostrado.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/[\.,]/g, ''),
            ["iva"]: Number(Number(totalNetoMostrado * 0.19).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '')).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, ''),
            ["total"]: Number(Number(totalNetoMostrado * 1.19).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '')).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '')
          });
        }
      }
    };

    const navigate = useNavigate();
    const handleSubmitEstadoPagoBH = async (event) => {
      event.preventDefault();

      try {
        const esFormularioValido = validaFormularioSubmit();

        if (Object.keys(formReportsData).length === 0) {
          alert('Debe agregar al menos un report');
        } else if (esFormularioValido) {
          //console.log(JSON.stringify(dataTotales));
          const response = await fetch(
            `https://vittamaq.qa-gtalent.cl/api/estado_pago_bh.php`,
            {
              method: "POST",
              headers: {
                'Accept': 'application/json', // Indica que esperas una respuesta JSON
                'Content-Type': 'application/json', 
              },
              body: JSON.stringify(dataEstadoPago),
            }
          );
  
          const dataResponse = await response.json();
          if (dataResponse.message === "Estado de pago agregado con éxito.") {
            const estadoPagoReports = Object.keys(formReportsData).reduce((acc, key) => {
              if (typeof formReportsData[key] === 'object') {
                acc[key] = { ...formReportsData[key], id_estado_pago_bh: dataResponse.id_estado_pago_bh };
              } else {
                acc[key] = formReportsData[key];
              }
              return acc;
            }, {});
  
            const responseReports = await fetch(
              `https://vittamaq.qa-gtalent.cl/api/estado_pago_reports_bh.php`,
              {
                method: "POST",
                headers: {
                  'Accept': 'application/json', // Indica que esperas una respuesta JSON
                  'Content-Type': 'application/json', 
                },
                body: JSON.stringify(estadoPagoReports),
              }
            );
    
            const dataResponseReports = await responseReports.json();
            if (dataResponseReports.message === "Reports de estado de pago agregados con éxito.") {
              const responseReportsActualizados = await fetch(
                `https://vittamaq.qa-gtalent.cl/api/report_bh.php`,
                {
                  method: "PUT",
                  headers: {
                    'Accept': 'application/json', // Indica que esperas una respuesta JSON
                    'Content-Type': 'application/json', 
                  },
                  body: JSON.stringify(formReportsData),
                }
              );
              
              const dataResponseReportsActualizados = await responseReportsActualizados.json();
              if (dataResponseReportsActualizados.message === "Reports de estado de pago actualizados con éxito.") {
                setEstadoPagoData({
                  id_folio_ep: "",
                  id_obra_ep: "",
                  id_cliente_ep: "",
                  id_cliente_final_ep: "",
                  m3_bombeado_uf: "",
                  m3_bombeado_valor: "",
                  valor_m3: "",
                  aditivo_uf: "",
                  aditivo_valor: "",
                  kms_recorridos_uf: "",
                  kms_recorridos_valor: "",
                  sobre_estadia_uf: "",
                  sobre_estadia_valor: "",
                  otros_descuentos_ep: "",
                  detalle_otros_descuentos_ep: "",
                  total_neto: "",
                  iva: "",
                  total: "",
                  id_usuario: ""
                });
    
                setFormReportsData([]);
      
                alert("Estado de pago agregado con éxito.");
      
                navigate("/dashboard", { state: dataLogin });
  
                closeModal();
              }
            }
          } else {
            throw new Error();
          }
        }
      } catch (error) {
        alert(error);
      }
    }

    const validaFormulario = () => {
      if (clienteSelected === "") {
        alert('Debe seleccionar un cliente');
        return false;
      }

      if (dataEstadoPago.id_folio_ep.trim() === '') {
        alert('Debe seleccionar un folio');
        return false;
      }

      if (dataConsultaReports.fecha_desde_ep.trim() === '') {
        alert('Debe indicar una fecha desde');
        return false;
      }

      if (dataConsultaReports.fecha_hasta_ep.trim() === '') {
        alert('Debe indicar una fecha hasta');
        return false;
      }

      return true;
    }

    const validaFormularioSubmit = () => {
      if (clienteSelected === "") {
        alert('Debe seleccionar un cliente');
        return false;
      }

      if (dataEstadoPago.id_folio_ep.trim() === '') {
        alert('Debe seleccionar un folio');
        return false;
      }

      if (dataConsultaReports.fecha_desde_ep.trim() === '') {
        alert('Debe indicar una fecha desde');
        return false;
      }

      if (dataConsultaReports.fecha_hasta_ep.trim() === '') {
        alert('Debe indicar una fecha hasta');
        return false;
      }

      if (dataEstadoPago.otros_descuentos_ep !== 0) {
        if (dataEstadoPago.detalle_otros_descuentos_ep.trim() === '') {
          alert('Debe indicar el detalle de otros descuentos, de lo contrario su valor debe ser 0');
          return false;
        }  
      }

      return true;
    }

    return (
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xxl">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Agregar Estado de Pago: Bomba Hormigón</h4>
            <button onClick={closeModal} className="btn-close"></button>
          </div>
          <div className="modal-body py-3 px-5">
            <div className="row row-cols-lg-4 mb-3">
              <div className="col">
                <label className="form-label">Cliente</label>
                <Select
                  name="id_cliente_ep" 
                  value={clienteSelected.value} 
                  onChange={handleClienteSelect}
                  options={optionsClientes}
                  isSearchable
                  className="react-select"
                  placeholder="Seleccione un cliente"
                />
              </div>
              <div className="col">
                <label className="form-label">Obra</label>
                <select className="form-select" name="id_obra_ep" value={dataConsultaReports.id_obra_ep} onChange={(e) => handleObraSelect(e.target.value)}>
                  <option value="">Seleccione una obra</option>
                  {clienteObra.map((obra, index) => (
                    <option key={index} value={obra.id_obra}>{obra.nombre}</option>
                  ))}
                </select>
              </div>
              <div className="col">
                <label className="form-label">Cliente Final</label>
                <select className="form-select" name="id_cliente_final_ep" value={dataEstadoPago.id_cliente_final_ep} disabled>
                  <option value="">Seleccione un cliente final</option>
                  {optionsClientes.map((cliente_final, index) => (
                    <option key={index} value={cliente_final.value}>{cliente_final.label}</option>
                  ))}
                </select>
              </div>
              {folios ? (
                <div className="col">
                  <label className="form-label">Folio</label>
                  <select className="form-select" name="id_folio_ep" value={dataEstadoPago.id_folio_ep} onChange={handleFormEstadoPagoData}>
                    <option value="">Seleccione un folio</option>
                    {folios.map((folio, index) => (
                      <option key={index} value={folio.folio}>{folio.folio}</option>
                    ))}
                  </select>
                </div>
                ) : (
                <div className="col">
                  <label className="form-label">Folio</label>
                  <select className="form-select" name="folio_ep">
                    <option value="">No se encontraron folios</option>
                  </select>
                </div>
              )}
            </div>
            <div className="row row-cols-lg-4 align-items-end mb-4">
              <div className="col">
                <label className="form-label">Fecha Desde</label>
                <input 
                  className="form-control" 
                  type="date" 
                  name="fecha_desde_ep" 
                  value={dataConsultaReports.fecha_desde_ep}
                  max={today.toISOString().split('T')[0]}
                  onChange={(e) => setDataConsultaReports({
                    ...dataConsultaReports,
                    fecha_desde_ep: e.target.value
                  })}
                />
              </div>
              <div className="col">
                <label className="form-label">Fecha Hasta</label>
                <input 
                  className="form-control" 
                  type="date" 
                  name="fecha_hasta_ep" 
                  value={dataConsultaReports.fecha_hasta_ep}
                  max={today.toISOString().split('T')[0]}
                  onChange={(e) => setDataConsultaReports({
                    ...dataConsultaReports,
                    fecha_hasta_ep: e.target.value
                  })}
                />
              </div>
              <div className="col">
                <label className="form-label d-block">&nbsp;</label>
                <button className="btn btn-dark" onClick={handleSubmitBuscarReports}>
                  <i className="bi bi-search"></i> Buscar
                </button>
              </div>
            </div>
            <div className="d-flex justify-content-md-between mb-3">
              <h5 className="mb-0">Detalle Reports</h5>
              <span className="d-inline-flex align-items-center">
                <label className="form-label mb-0 me-2">Valor UF Hoy:</label>
                <span className="fw-bold text-orange">{Number(indicadorUF ? indicadorUF : '0').toLocaleString('es-ES', { useGrouping: true })}</span>
              </span>
            </div>
            <div className="table-responsive border rounded mb-4">
              <table className="table table-hover text-center table-sm mb-0">
                <thead>
                  <tr className="table-active border-bottom">
                    {dataReports.length > 0 ? (
                      <th>
                        <input 
                          className="form-check-input" 
                          type="checkbox"
                          checked={selectAll}
                          onChange={handleSelectAll}
                        />
                      </th>
                    ) : (
                      <th></th>
                    )}
                    <th scope="col" className="text-center">N° Report</th>
                    <th scope="col">Bomba</th>
                    <th scope="col">Fecha</th>
                    <th scope="col">Día</th>
                    <th scope="col">Mes</th>
                    <th scope="col">M3 Bombeados</th>
                    <th scope="col">Valor M3 (UF)</th>
                    <th scope="col">Valor UF Total</th>
                    <th scope="col">Valor UF</th>
                    <th scope="col">V.T. Bombeo Neto</th>
                    <th scope="col">Vittech</th>
                    <th scope="col">Valor Traslado BH</th>
                    <th scope="col">Sobre Estadía</th>
                  </tr>
                </thead>
                <tbody>
                  {dataReports.length > 0 ? (
                    dataReports.map((report, index) => (
                      <tr key={index}>
                        <td>
                          <input 
                            className="form-check-input" 
                            type="checkbox"
                            checked={checkboxStates[index] || false}
                            onChange={() => toggleCheckbox(index, report.id_report_bh, report.m3_bombeados, report.valor_m3_uf, report.valor_uf, report.aditivo, report.valor_traslado_bomba_uf, report.sobre_estadia_hora, report.sobre_estadia_uf, report.valor_total_bombeo_neto)}
                          />
                        </td>
                        <td>{report.id_report_bh}</td>
                        <td>{report.bomba}</td>
                        <td>{report.fecha_report}</td>
                        <td>{report.dia}</td>
                        <td>{report.mes}</td>
                        <td>{report.m3_bombeados}</td>
                        <td>{report.valor_m3_uf}</td>
                        <td>{report.valor_total_uf}</td>
                        <td><small className="text-secondary">$ </small> {Number(report.valor_uf).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        <td><small className="text-secondary">$ </small> {report.valor_total_bombeo_neto}</td>
                        <td>{report.aditivo}</td>
                        {/* <td>{report.kms_recorridos}</td> */}
                        <td>{report.valor_traslado_bomba_uf}</td>
                        <td>{report.sobre_estadia_hora.substring(0, 5)}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="text-center ps-1" colSpan={14}>No se encontraron reports</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            
              <div className="row row-cols-md-2">
                <div className="col">
                  <h5 className="mb-3">Total Item</h5>
                  <div className="table-responsive border rounded">
                    <table className="table table-hover table-sm mb-0">
                      <thead>
                        <tr className="table-active border-bottom">
                          <th></th>
                          <th className="col-6">Item</th>
                          <th className="col-3">Cantidad (UF)</th>
                          <th className="col-3">Valor</th>
                        </tr>
                      </thead>
                      {dataTotales.length > 0 ? (
                        <tbody>
                          <tr>
                            <td> </td>
                            <td>M3 Bombeados {esServicioMensual ? "(Servicio Mensual)" : ""}</td>
                            <td>{dataTotales[0].m3_bombeados_suma}</td>
                            <td><small className="text-secondary">$ </small> {dataTotales[0].valor_total_bombeo_neto_suma}</td>
                          </tr>
                          <tr>
                            <td> </td>
                            <td>Vittech</td>
                            <td>{dataTotales[0].aditivo}</td>
                            <td><small className="text-secondary">$ </small> {dataTotales[0].aditivo_valor}</td>
                          </tr>
                          <tr>
                            <td>
                              <input 
                                className="form-check-input" 
                                type="checkbox"
                                checked={isCheckedValorTrasladoBomba}
                                onChange={() => handleCheckboxValorTrasladoBombaChange(dataTotales[0].valor_traslado_bomba, dataTotales[0].total_neto)}
                              />
                            </td>
                            <td>Valor Traslado Bomba</td>
                            <td>{dataTotales[0].valor_traslado_bomba_uf}</td>
                            <td><small className="text-secondary">$ </small> {dataTotales[0].valor_traslado_bomba}</td>
                          </tr>
                          <tr>
                            <td>
                              <input 
                                className="form-check-input" 
                                type="checkbox"
                                checked={isCheckedSobreEstadia}
                                onChange={() => handleCheckboxSobreEstadia(dataTotales[0].sobre_estadia_valor, dataTotales[0].total_neto)}
                              />
                            </td>
                            <td>Sobre Estadia</td>
                            <td>{dataTotales[0].sobre_estadia_uf}</td>
                            <td><small className="text-secondary">$ </small> {dataTotales[0].sobre_estadia_valor}</td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td></td>
                            <td>M3 Bombeados</td>
                            <td>0.00</td>
                            <td><small className="text-secondary">$ </small> 0</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>Vittech</td>
                            <td>0.00</td>
                            <td><small className="text-secondary">$ </small> 0</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>Valor Traslado Bomba</td>
                            <td>0.00</td>
                            <td><small className="text-secondary">$ </small> 0</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>Sobre Estadia</td>
                            <td>0.00</td>
                            <td><small className="text-secondary">$ </small> 0</td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
                <div className="col">
                  <h5 className="mb-3">Valores Totales</h5>
                  <div className="table-responsive border rounded mb-4">
                    <table className="table table-sm mb-0">
                      <thead>
                        <tr className="table-active border-bottom">
                          <th scope="col" className="ps-2">Total Neto</th>
                          <th scope="col">IVA</th>
                          <th scope="col" className="text-dark fw-semibold pe-2">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                      {dataTotales.length > 0 ? (
                        <tr>
                          <td className="ps-2"><small className="text-secondary">$ </small>{dataTotales[0].total_neto}</td>
                          <td><small className="text-secondary">$ </small>{dataTotales[0].iva}</td>
                          <td className="pe-2"><small className="text-secondary">$ </small><span className="text-orange">{dataTotales[0].total}</span></td>
                        </tr>
                      ) : (
                        <tr>
                          <td className="ps-2"><small className="text-secondary">$ </small>0</td>
                          <td><small className="text-secondary">$ </small>0</td>
                          <td className="pe-2"><small className="text-secondary">$ </small><span className="text-orange">0</span></td>
                        </tr>
                      )}
                      </tbody>
                    </table>
                  </div>
                  <div className="row row-cols-md-2">
                    <div className="col">
                      <label className="form-label">Otros Descuentos</label>
                      {dataTotales.length > 0 ? (
                        <input 
                          className="form-control" 
                          type="text" 
                          name="otros_descuentos_ep" 
                          value={dataOtrosDescuentos}
                          onChange={(e) => handleOtrosDescuentos(e.target.value, dataTotales[0].total_neto)}
                        />
                      ) : (
                        <input 
                          className="form-control" 
                          type="text" 
                          name="otros_descuentos_ep" 
                          disabled
                        />
                      )}
                    </div>
                    <div className="col">
                      <label className="form-label">Detalle Descuentos</label>
                      <input 
                        className="form-control" 
                        type="text" 
                        name="detalle_otros_descuentos_ep" 
                        onChange={(e) => setEstadoPagoData({
                          ...dataEstadoPago,
                          ["detalle_otros_descuentos_ep"]: e.target.value
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-yellow" onClick={handleSubmitEstadoPagoBH}><i className="bi bi-floppy"></i> Guardar</button>
          </div>
        </div>
      </div>
    );
  }

  function EstadoPagoBHModalContent({ data }) {
    const [tiposDocumentos, setTiposDocumentos] = useState([]);
    const [estadoPagoEstado, setEstadoPagoEstado] = useState(data.id_estado_pago_estado);
    const [estadoPagoReportsBH, setEstadoPagoReportsBH] = useState([]);
    const [estadoPagoDocumentosBH, setEstadoPagoDocumentosBH] = useState([]);
    const [detalleCotizacion, setDetalleCotizacion] = useState([]);

    useEffect(() => {
      obtenerTiposDocumentos();
      obtenerReportsBH(data.id_estado_pago_bh);
      obtenerDocumentosBH(data.id_estado_pago_bh);
      obtenerDetalleCotizacion(data.folio);
    }, []);

    const obtenerTiposDocumentos = async () => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/tipo_documento.php`
        );
        const data = await response.json();
        
        if (data && data[0].id_tipo_documento) {
          setTiposDocumentos(data);
        }
      } catch (error) {
        setTiposDocumentos([]);
      }
    };

    const obtenerDocumentosBH = async (id_estado_pago_bh) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/estado_pago_documentos_bh.php?id_estado_pago_bh=${id_estado_pago_bh}`
        );
        const data = await response.json();
        
        if (data && data[0].id_estado_pago_documentos_bh) {
          setEstadoPagoDocumentosBH(data);
        }
      } catch (error) {
        setEstadoPagoDocumentosBH([]);
      }
    };

    const [esServicioMensual, setEsServicioMensual] = useState(false);
    const obtenerDetalleCotizacion = async (folio) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/detalle_cotizacion.php?folio=${folio}`
        );
        const data = await response.json();
  
        if (data && data[0].id_servicio) {
          setDetalleCotizacion(data);

          const serviciosMensuales = [10, 11, 12, 13];
          const esMensual = serviciosMensuales.includes(data[0].id_servicio);
          setEsServicioMensual(esMensual);
        }
      } catch (error) {
        setDetalleCotizacion([]);
        setEsServicioMensual('');
      }
    };

    const [dataFechaReports, setDataFechaReports] = useState({});
    const obtenerReportsBH = async (id_estado_pago_bh) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/estado_pago_reports_bh.php?id_estado_pago_bh=${id_estado_pago_bh}`
        );
        const data = await response.json();
        
        if (data && data[0].id_estado_pago_bh) {
          setEstadoPagoReportsBH(data);
          
          //Obtener día y mes report
          const partesFecha = data[0].fecha_report.split('-');
          const date = new Date(`${partesFecha[2]}-${partesFecha[1]}-${partesFecha[0]}`);
      
          const diasSemana = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
          const nombreMeses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

          setDataFechaReports({
            ...dataFechaReports,
            ["fecha_report"]: data[0].fecha_report,
            ["dia"]: diasSemana[date.getDay()],
            ["mes"]: nombreMeses[date.getMonth()]
          });
        }
      } catch (error) {
        setEstadoPagoReportsBH([]);
      }
    };

    const handleModificaEstado = (data_estado_pago, id_estado_pago_estado) => {
      setEstadoPagoEstado(id_estado_pago_estado);

      setSecondModalOpen(true);
      
      setSecondModalContent(<ConfirmaModificaEstadoModalContent dataEstadoPago={data_estado_pago} idEstadoPagoEstado={id_estado_pago_estado} />);
    };

    function ConfirmaModificaEstadoModalContent ({ dataEstadoPago, idEstadoPagoEstado }) {
      const obtenerEstadoPorId = (idEstadoPagoEstado) => {
        const estadoEncontrado = estadosPagoEstado.find(estado => estado.id_estado_pago_estado === idEstadoPagoEstado);
        return estadoEncontrado ? estadoEncontrado.estado_pago_estado : 'Estado no encontrado';
      };

      const estado = obtenerEstadoPorId(Number(idEstadoPagoEstado));

      let jsonPIGT = null;
      if (idEstadoPagoEstado === '3') {
        //JSON para PIG-T
        const objetoPIGT = (dataCotizacion, detalleCotizacion, detalleDocumentos) => {
          const nuevoObjeto = {
            "cabecera_cotizacion": {
              "nombre_cliente": dataCotizacion.nombre_cliente,
              "rut_cliente": dataCotizacion.rut_cliente,
              "razon_social": dataCotizacion.razon_social,
              "giro": dataCotizacion.giro,
              "direccion_cliente": dataCotizacion.direccion,
              "region": dataCotizacion.region,
              "comuna": dataCotizacion.comuna,
              "telefono_representante": dataCotizacion.telefono_representante,
              "fecha_cotizacion": dataCotizacion.fecha_cotizacion,
              "folio": dataCotizacion.folio,
              "obra": dataCotizacion.nombre_obra,
              "nombre_solicitante": dataCotizacion.nombre_solicitante,
              "rut_solicitante": dataCotizacion.rut_solicitante,
              "email_solicitante": dataCotizacion.email_solicitante,
              "fecha_inicio": dataCotizacion.fecha_inicio,
              "fecha_fin": dataCotizacion.fecha_fin,
              "bodega": dataCotizacion.bodega,
              "usuario_registra": dataCotizacion.usuario_registra
            },
            "servicios_cotizacion": detalleCotizacion,
            "documentos_cotizacion": detalleDocumentos
          };
        
          return nuevoObjeto;
        };

        const glosasServicios = detalleCotizacion.map(servicio => ({
          descripcion_servicio: servicio.descripcion !== "" ? servicio.descripcion : servicio.nombre_maquinaria + " " + servicio.tipo_maquinaria + " " + servicio.tipo_arriendo,
        }));

        const glosasServiciosConcatenados = glosasServicios.map(servicio => servicio.descripcion_servicio).join('\n');

        const cotizacion = detalleCotizacion[0];
        const detalleCotizacionPigT = {
          descripcion_servicio: glosasServiciosConcatenados,
          tipo_maquinaria: cotizacion.tipo_maquinaria,
          tipo_arriendo: cotizacion.tipo_arriendo,
          sku: "BH0",
          glosa_servicio: "Cotización Referencia: " + data.folio,
          unidad_medida: cotizacion.unidad_medida,
          unidad_medida_alias: cotizacion.unidad_medida_alias,
          cantidad: "1",
          m3_bombeado_valor: Number(data.m3_bombeado_valor).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, ''),
          aditivo_valor: Number(data.aditivo_valor).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, ''),
          traslado_bomba_valor: Number(data.valor_traslado_bomba).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, ''),
          sobre_estadia_valor: Number(data.sobre_estadia_valor).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, ''),
          total_neto: Number(data.total_neto).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, ''),
          iva: Number(data.iva).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, ''),
          descuentos: Number(data.otros_descuentos).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, ''),
          total: Number(data.total).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '')
        };

        const detalleDocumentos = estadoPagoDocumentosBH.map(doc => {
          if (doc.tipo_documento === "HES") {
            return { ...doc, tipo_documento: "HES (Hoja Entrada De Servicio)" };
          } else if (doc.tipo_documento === "Orden de Trabajo") {
            return { ...doc, tipo_documento: "OT (Orden de Trabajo)" };
          } else {
            return doc;
          }
        });
  
        jsonPIGT = objetoPIGT(data, detalleCotizacionPigT, detalleDocumentos);
        //console.log(JSON.stringify(jsonPIGT,null,2));
      }

      return (
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="mb-0">Modificar Estado: {dataEstadoPago.folio}</h4>
              <button onClick={closeSecondModal} className="btn-close"></button>
            </div>
            <div className="modal-body">
              <p className="fs-5">¿Está seguro que desea modificar el estado del estado de pago a: {idEstadoPagoEstado === '2' ? <span className="text-red">{estado}</span> : <span className="text-orange">{estado}</span>} ?</p>
            </div>
            <div className="modal-footer">
              <button className={idEstadoPagoEstado === '2' ? "btn btn-danger" : "btn btn-yellow"} onClick={(e) => handleSubmitModificarEstado(e, dataEstadoPago.id_estado_pago_bh, idEstadoPagoEstado, jsonPIGT)}>
                {idEstadoPagoEstado === '2' ? (
                  <span>
                    <i className="bi bi-dash-circle"></i> Anular Estado de Pago
                  </span>
                ) : (
                  <span>
                    <i className="bi bi-check-circle"></i> Facturar Estado de Pago
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      );
    }

    const handleSubmitModificarEstado = async (event, idEstadoPagoBH, idEstadoPagoEstado, jsonPIGT) => {
      event.preventDefault();
  
      try {
        const formModificarEstado = { id_estado_pago_bh: idEstadoPagoBH, id_estado_pago_estado: idEstadoPagoEstado };

        const responseModificarEstado = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/estado_pago_bh.php`,
          {
            method: "PUT",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json', 
            },
            body: JSON.stringify(formModificarEstado),
          }
        );

        const dataModificarEstado = await responseModificarEstado.json();
        if (dataModificarEstado.message === "Estado de pago modificado con éxito.") {

          if (idEstadoPagoEstado === "2") {
            
            const habilitaReportsData = {
              habilita_report: 1,
              reports: estadoPagoReportsBH.map(item => ({
                id_report_bh: item.id_report_bh
              }))
            };

            const responseReportsActualizados = await fetch(
              `https://vittamaq.qa-gtalent.cl/api/report_bh.php`,
              {
                method: "PUT",
                headers: {
                  'Accept': 'application/json', // Indica que esperas una respuesta JSON
                  'Content-Type': 'application/json', 
                },
                body: JSON.stringify(habilitaReportsData),
              }
            );
    
            const dataResponseReportsActualizados = await responseReportsActualizados.json();
            if (dataResponseReportsActualizados.message === "Report BH habilitado exitosamente.") {
              alert("Estado de pago modificado con éxito.");
          
              obtenerEstadosPagoBH();
    
              closeSecondModal();
              closeModal();
    
              navigate("/dashboard", { state: dataLogin });
            }
          } else {
            alert("Estado de pago modificado con éxito.");

            if (jsonPIGT != null) {
              const responseKame = await fetch(
                `https://app.pig-t.cl/vittamaq_Cotizaciones_Kame`,
                {
                  method: "POST",
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json', 
                  },
                  body: JSON.stringify(jsonPIGT),
                }
              );
        
              const dataResponseKame = await responseKame.json();
              //console.log(JSON.stringify(dataResponseKame,null,2));
            }

            obtenerEstadosPagoBH();

            closeSecondModal();
            closeModal();

            navigate("/dashboard", { state: dataLogin });
          }
        } else {
          throw new Error();
        }
        
      } catch (error) {
        obtenerEstadosPagoBH();
      }
    }

    //DOCUMENTOS
    const [activeTab, setActiveTab] = useState('valores');
    const handleTabClick = (tab) => {
      setActiveTab(tab);
    };

    const filaPredeterminada = { 
      id: 1, 
      folio: data.folio,
      id_estado_pago_bh: data.id_estado_pago_bh,
      id_tipo_documento: '',
      detalle: '', 
      fecha: '', 
      id_usuario: dataLogin.id_usuario.toString()
    };
    const [filas, setFilas] = useState([filaPredeterminada]);

    const handleAgregarFila = () => {
      const nuevaFila = {
        id: filas.length + 1,
        folio: data.folio,
        id_estado_pago_bh: data.id_estado_pago_bh,
        id_tipo_documento: '',
        detalle: '', 
        fecha: '', 
        id_usuario: dataLogin.id_usuario.toString()
      };
  
      setFilas([...filas, nuevaFila]);
    };

    const handleInputChange = (id, campo, valor) => {
      const nuevasFilas = filas.map((fila) =>
        fila.id === id ? { ...fila, [campo]: valor } : fila
      );
  
      setFilas(nuevasFilas);
    };

    const handleEliminarFila = (id) => {
      if (filas.length > 1) {
        const nuevasFilas = filas.filter((fila) => fila.id !== id);
        setFilas(nuevasFilas);
      } else {
        alert("Debe agregar al menos un documento.");
      }
    };

    const handleConfirmaDocumentos = (e, filas) => {
      const esFormularioValido = validaFormularioDocumentos(filas);

      if (!esFormularioValido) {
        alert("Debe completar la información de los documentos.")
      } else {
        setSecondModalOpen(true);
        setSecondModalContent(<ConfirmaDocumentosModalContent documentos={filas} />);
      }
    };

    function ConfirmaDocumentosModalContent ({ documentos }) {
      const tipoDocumentoMap = tiposDocumentos.reduce((acc, tipo) => {
        acc[tipo.id_tipo_documento] = tipo.tipo_documento;
        return acc;
      }, {});
    
      const documentosConTipo = documentos.map(doc => {
        return {
          ...doc,
          tipo_documento: tipoDocumentoMap[doc.id_tipo_documento]
        };
      });
      
      return (
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="mb-0">Confirmar Documentos</h4>
              <button onClick={closeSecondModal} className="btn-close"></button>
            </div>
            <div className="modal-body">
              <p className="fs-5">¿Está seguro que desea ingresar los siguientes documentos?:</p>
              <table className="table table-hover table-sm mb-0">
                <thead>
                  <tr className="table-active">
                    <th className="ps-2">Tipo Documento</th>
                    <th>Número</th>
                    <th className="pe-2">Fecha</th>
                  </tr>
                </thead>
                <tbody>
                  {documentosConTipo.length > 0 ? (
                    documentosConTipo.map((documento, index) => (
                      <tr key={index}>
                        <td className="ps-2">{documento.tipo_documento}</td>
                        <td>{documento.detalle}</td>
                        <td className="pe-2">{documento.fecha.split('-').reverse().join('-')}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="text-center ps-1" colSpan={3}>No se encontraron documentos asociados</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button className="btn btn-yellow" onClick={handleSubmitDocumentos}><i className="bi bi-floppy"></i> Guardar</button>
            </div>
          </div>
        </div>
      );
    }

    const handleSubmitDocumentos = async (event) => {
      event.preventDefault();
      
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/estado_pago_documentos_bh.php`,
          {
            method: "POST",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json', 
            },
            body: JSON.stringify(filas),
          }
        );

        const dataResponse = await response.json();
        if (dataResponse.message === "Documentos BH ingresados con éxito.") {
          alert("Documentos agregados al estado de pago con éxito.");
          
          obtenerEstadosPagoBH();

          closeSecondModal();
          closeModal();

          navigate("/dashboard", { state: dataLogin });
        } else {
          throw new Error();
        }
      } catch (error) {
        obtenerEstadosPagoBH();
      }
    }

    const validaFormularioDocumentos = (datos_documentos) => {
      return datos_documentos.every(item => {
        return item.id && item.folio && item.id_estado_pago_bh &&
               item.id_tipo_documento && item.detalle &&
               item.fecha && item.id_usuario;
      });
    };

    return (
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xxl">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Detalle Estado Pago: Bomba Hormigón (BH-{data.id_estado_pago_bh})</h4>
            <button onClick={closeModal} className="btn-close"></button>
          </div>
          <div className="modal-body">
            <div className="row row-cols-md-2 mb-4">
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Folio</span>
                    <span className="label">{data.folio || ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Fecha Estado Pago</span>
                    <span className="label">{data.fecha_estado_pago || ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Equipo</span>
                    <span className="label">{data.nombre_equipo || ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Lugar de Faena</span>
                    <span className="label">{data.nombre_obra || ""}</span>
                  </li>
                </ul>
              </div>
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Rut</span>
                    <span className="label">{`${formatearRut(data.rut_cliente)}` || ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Cliente</span>
                    <span className="label">{data.nombre_cliente || ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Cliente Final</span>
                    <span className="label">{data.nombre_cliente_final || ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Email Solicitante</span>
                    <span className="label">{data.email_solicitante || ""}</span>
                  </li>
                </ul>
              </div>
            </div>
            <h5 className="mb-3">Detalle Reports</h5>
            <div className="table-responsive border rounded mb-4">
              <table className="table text-center table-sm mb-0">
                <thead>
                  <tr className="table-active border-bottom">
                    <th scope="col">N° Report</th>
                    <th scope="col">Bomba</th>
                    <th scope="col">Fecha</th>
                    <th scope="col">Día</th>
                    <th scope="col">Mes</th>
                    <th scope="col">M3 Bombeados</th>
                    <th scope="col">Valor M3 (UF)</th>
                    <th scope="col">Valor UF Total</th>
                    <th scope="col">Valor UF</th>
                    <th scope="col">V.T. Bombeo Neto</th>
                    <th scope="col">Vittech</th>
                    <th scope="col">Valor Traslado BH</th>
                    <th scope="col">Sobre Estadía</th>
                  </tr>
                </thead>
                <tbody>
                {estadoPagoReportsBH ? (
                  estadoPagoReportsBH.map((reportBH, index) => (
                    <tr key={index}>
                      <td>{reportBH.id_report_bh}</td>
                      <td>{reportBH.bomba}</td>
                      <td>{reportBH.fecha_report}</td>
                      <td>{dataFechaReports.dia}</td>
                      <td>{dataFechaReports.mes}</td>
                      <td>{reportBH.m3_bombeados}</td>
                      <td>{reportBH.valor_m3}</td>
                      <td>{reportBH.valor_uf_total}</td>
                      <td><small className="text-secondary">$</small> {parseFloat(reportBH.valor_uf).toLocaleString('es-CL', { useGrouping: true })}</td>
                      <td><small className="text-secondary">$</small> {parseFloat(reportBH.valor_total_bombeo_neto).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.')}</td>
                      <td>{reportBH.aditivo}</td>
                      <td>{reportBH.valor_traslado_bomba_uf}</td>
                      <td>{reportBH.sobre_estadia_hora.substring(0, 5)}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="text-center ps-1" colSpan={12}>No se encontraron reports asociados</td>
                  </tr>
                )}
                </tbody>
              </table>
            </div>


            <div className="card">
              <div className="card-header">
                <ul className="nav nav-tabs card-header-tabs mx-auto">
                  <li className="nav-item">
                    <a className={`nav-link ${activeTab === 'valores' ? 'nav-link active' : 'nav-link'}`} onClick={() => handleTabClick('valores')}>Valores</a>
                  </li>
                  <li className="nav-item">
                    <a className={`nav-link ${activeTab === 'documentos' ? 'nav-link active' : 'nav-link'}`} onClick={() => handleTabClick('documentos')}>Documentos</a>
                  </li>
                </ul>
              </div>
              <div className="card-body p-0">
                <div className="tab-content">
                  <div className={`tab-pane p-3 ${activeTab === 'valores' ? 'active' : ''}`}>
                    <div className="row row-cols-md-2">
                      <div className="col">
                        <h5 className="mb-3">Detalle</h5>
                        <ul className="list-group">
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            <span className="form-label mb-0">M3 Bombeados ({esServicioMensual ? <span className="fw-bold text-dark">Servicio Mensual: {data.m3_bombeado_uf || ""}</span> : <span className="fw-bold text-dark">{data.m3_bombeado_uf || ""}</span>})</span>
                            <span className="label"><small className="text-secondary">$ </small>{Number(data.m3_bombeado_valor).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.') || ""}</span>
                          </li>
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            <span className="form-label mb-0">Vittech (<span className="fw-bold text-dark">{data.aditivo_uf || ""}</span>)</span>
                            <span className="label"><small className="text-secondary">$ </small>{Number(data.aditivo_valor).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.') || ""}</span>
                          </li>
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            <span className="form-label mb-0">Valor Traslado Bomba (<span className="fw-bold text-dark">{data.valor_traslado_bomba_uf || ""}</span>)</span>
                            <span className="label"><small className="text-secondary">$ </small>{Number(data.valor_traslado_bomba).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.') || ""}</span>
                          </li>
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            <span className="form-label mb-0">Sobre Estadia (<span className="fw-bold text-dark">{data.sobre_estadia_uf || ""}</span>)</span>
                            <span className="label"><small className="text-secondary">$ </small>{Number(data.sobre_estadia_valor).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.') || ""}</span>
                          </li>
                        </ul>
                      </div>
                      <div className="col">
                      <h5 className="mb-3">Totales</h5>
                        <ul className="list-group">
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            <span className="form-label mb-0">Total Neto</span>
                            <span className="label"><small className="text-secondary">$ </small>{Number(data.total_neto).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.') || ""}</span>
                          </li>
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            <span className="form-label mb-0">IVA</span>
                            <span className="label"><small className="text-secondary">$ </small>{Number(data.iva).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.') || ""}</span>
                          </li>
                          <li className="list-group-item">
                            <div className="d-flex justify-content-between align-items-center mb-2">
                              <span className="form-label mb-0">Descuentos</span>
                              <span className="label"><small className="text-secondary"> </small><span className="text-red">- $ {Number(data.otros_descuentos).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.') || ""}</span></span>
                            </div>
                            <div className="border rounded table-active bg-body-secondary py-1 px-2">
                              <small>{data.detalle_otros_descuentos || ""}</small>
                            </div>
                          </li>
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            <span className="form-label mb-0"><span className="fw-bold text-dark">Total Facturado</span></span>
                            <span className="label"><small className="text-secondary">$ </small><span className="fs-5 text-dark">{Number(data.total).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.') || ""}</span></span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className={`tab-pane ${activeTab === 'documentos' ? 'active' : ''}`}>
                    <div className="d-flex justify-content-lg-between align-items-center p-3">
                      <h5 className="mb-0">Documentos Asociados</h5>
                      <div className="d-inline-flex gap-2">
                        <button type="button" className="btn btn-outline-dark btn-sm" onClick={handleAgregarFila}>
                          <i className="bi bi-plus-circle"></i> Agregar
                        </button>
                        <button type="button" className="btn btn-dark btn-sm" onClick={(e) => handleConfirmaDocumentos(e, filas)}>
                          <i className="bi bi-floppy"></i> Guardar
                        </button>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-sm table-hover mb-0">
                        <thead>
                          <tr className="table-active border-top border-bottom">
                            <th className="ps-3">Tipo Documento</th>
                            <th>Número</th>
                            <th className="pe-3">Fecha</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                        {estadoPagoDocumentosBH.length > 0 ? (
                          estadoPagoDocumentosBH.map((documento, index) => (
                            <tr key={index}>
                              <td className="ps-3">{documento.tipo_documento}</td>
                              <td>{documento.detalle}</td>
                              <td>{documento.fecha_detalle}</td>
                              <td className="pe-3"></td>
                            </tr>
                          ))
                        ) : (
                          <tr></tr>
                        )}
                        {filas.map((fila) => (
                          <tr key={fila.id}>
                            <td className="ps-3">
                              <select className="form-select form-select-sm" value={fila.id_tipo_documento} onChange={(e) => handleInputChange(fila.id, 'id_tipo_documento', e.target.value)}>
                                <option value="">Seleccione Tipo de Documento</option>
                                {tiposDocumentos.map((tipo_documento, index) => (
                                  <option key={index} value={tipo_documento.id_tipo_documento}>{tipo_documento.tipo_documento}</option>
                                ))}
                              </select>
                            </td>
                            <td>
                              <input
                                className="form-control form-control-sm"
                                type="text"
                                value={fila.detalle}
                                onChange={(e) =>
                                  handleInputChange(fila.id, 'detalle', e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <input
                                className="form-control form-control-sm"
                                type="date"
                                value={fila.fecha}
                                onChange={(e) =>
                                  handleInputChange(fila.id, 'fecha', e.target.value)
                                }
                              />
                            </td>
                            <td className="pe-3">
                              <button className="btn btn-outline-danger btn-sm" onClick={() => handleEliminarFila(fila.id)}>
                                <i className="bi bi-trash3"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {dataLogin.id_perfil === 1 && (
              <div className="d-inline-flex flex-lg-row gap-2">
                <button className="btn btn-outline-danger" onClick={(event) => handleModificaEstado(data, '2')}><i className="bi bi-dash-circle"></i> Anular</button>
                <button className="btn btn-yellow" onClick={(event) => handleModificaEstado(data, '3')}><i className="bi bi-check-circle"></i> Facturar</button>
              </div>
            )}
            {/* <select className="form-select form-select-width" name="id_estado_pago_estado" value={estadoPagoEstado} onChange={(event) => handleModificaEstado(data, event.target.value)}>
                {estadosPagoEstado.map((estado) => (
                  <option key={estado.id_estado_pago_estado} value={estado.id_estado_pago_estado}>{estado.estado_pago_estado}</option>
                ))}
              </select> */}
          </div>
        </div>
      </div>
    );
  }

  function AgregarEstadoPagoGHModalContent() {
    const [clientes, setClientes] = useState([]);
    useEffect(() => {
      obtenerClientes();
    }, []);

    const obtenerClientes = async () => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/cliente.php`
        );
        const data = await response.json();

        if (data && data[0].id_cliente) {
          setClientes(data);
        }
      } catch (error) {
        setClientes([]);
      }
    };

    const [clienteSelected, setClienteSelected] = useState('');
    const handleClienteSelect = async (clienteSelected) => {
      setClienteSelected(clienteSelected.value);
      await obtenerClienteObra(clienteSelected.value);

      setEstadoPagoData({
        ...dataEstadoPago,
        ["id_cliente_ep"]: clienteSelected.value,
        ["id_cliente_final_ep"]: clienteSelected.value,
        ["id_usuario"]: dataLogin.id_usuario
      });
    };

    const optionsClientes = clientes.map((cliente) => ({
      value: cliente.id_cliente,
      label: cliente.nombre_cliente,
    }));

    const handleObraSelect = async (obraSelected) => {
      await obtenerFolios(obraSelected);

      setDataConsultaReports({
        ...dataConsultaReports,
        ["id_obra_ep"]: obraSelected,
      });

      setEstadoPagoData({
        ...dataEstadoPago,
        ["id_obra_ep"]: obraSelected,
      });
    };

    const [clienteObra, setclienteObra] = useState([]);
    const obtenerClienteObra = async (cliente) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/cliente_obra.php?id_cliente=${cliente}`
        );
        const data = await response.json();
        
        if (data && data[0].id_obra) {
          setclienteObra(data);
          /* dataEstadoPago.id_cliente_ep = data[0].id_cliente;
          dataEstadoPago.id_cliente_final_ep = data[0].id_cliente_final; */
        }
      } catch (error) {
        
        setclienteObra([]);
      }
    };

    const [folios, setFolios] = useState([]);
    const obtenerFolios = async (obra) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/cabecera_cotizacion.php?id_obra=${obra}&id_maquinaria=2`
        );
        const data = await response.json();

        if (data && data[0].folio) {
          setFolios(data);
        }
      } catch (error) {
        setFolios([]);
        //alert("No hay Folios para mostrar.");
      }
    };

    const [dataEstadoPago, setEstadoPagoData] = useState({
      folio_ep: "",
      id_obra_ep: "",
      id_cliente_ep: "",
      id_cliente_final_ep: "",
      horas_trabajadas_cantidad: "",
      horas_trabajadas_valor: "",
      horas_trabajadas_valor_total: "",
      otros_descuentos_ep: 0,
      detalle_otros_descuentos_ep: "",
      total_neto: "",
      iva: "",
      total: "",
      id_usuario: ""
    });

    const handleFormEstadoPagoData = (e) => {
      const { name, value } = e.target;

      setEstadoPagoData({
        ...dataEstadoPago,
        [name]: value,
      });

      setDataConsultaReports({
        ...dataConsultaReports,
        [name]: value,
      });
    };

    const [dataConsultaReports, setDataConsultaReports] = useState({
      id_obra_ep: "",
      folio_ep: "",
      fecha_desde_ep: "",
      fecha_hasta_ep: ""
    });

    const [dataReports, setDataReports] = useState([]);
    const [horasTrabajadasValor, setHorasTrabajadasValor] = useState(0.00);
    const handleSubmitBuscarReports = async (event) => {
      event.preventDefault();

      try {
        const esFormularioValido = validaFormulario();

        if (esFormularioValido) {
          const idObra = dataConsultaReports.id_obra_ep;
          const folio = dataConsultaReports.folio_ep;
          const fechaDesde = dataConsultaReports.fecha_desde_ep;
          const fechaHasta = dataConsultaReports.fecha_hasta_ep;
  
          const response = await fetch(
            `https://vittamaq.qa-gtalent.cl/api/estado_pago_gh.php?id_obra=${idObra}&folio=${folio}&fecha_desde=${fechaDesde}&fecha_hasta=${fechaHasta}`
          );
          const data = await response.json();

          if (data && data[0].id_report_gh) {
            const resultadoFormateado = data.map(item => {
              const SalidaBodega = new Date(`1970-01-01T${item.hora_salida_bodega}`);
              const LlegadaBodega = new Date(`1970-01-01T${item.hora_llegada_bodega}`);
              const inicioColacion = new Date(`1970-01-01T${item.hora_inicio_colacion}`);
              const terminoColacion = new Date(`1970-01-01T${item.hora_termino_colacion}`);
        
              const horasTrabajadasManana = (inicioColacion - SalidaBodega) / 3600000;
              const horasManana = Math.floor(horasTrabajadasManana);
              const minutosManana = Math.floor((horasTrabajadasManana - horasManana) * 60);
              const segundosManana = Math.floor(((horasTrabajadasManana - horasManana) * 60 - minutosManana) * 60);
              const horasMananaFormateadas = `${String(horasManana).padStart(2, '0')}:${String(minutosManana).padStart(2, '0')}:${String(segundosManana).padStart(2, '0')}`;

              const horasTrabajadasTarde = (LlegadaBodega - terminoColacion) / 3600000;
              const horasTarde = Math.floor(horasTrabajadasTarde);
              const minutosTarde = Math.floor((horasTrabajadasTarde - horasTarde) * 60);
              const segundosTarde = Math.floor(((horasTrabajadasTarde - horasTarde) * 60 - minutosTarde) * 60);
              const horasTardeFormateadas = `${String(horasTarde).padStart(2, '0')}:${String(minutosTarde).padStart(2, '0')}:${String(segundosTarde).padStart(2, '0')}`;

              const minutosTotales = (horasTrabajadasManana + horasTrabajadasTarde) * 60;
              const horasFinales = Math.floor(minutosTotales / 60);
              const minutosFinales = minutosTotales % 60;
              const horasTotalesFormateadas = `${String(horasFinales).padStart(2, '0')}:${String(minutosFinales).padStart(2, '0')}:00`;
              
              //console.log(horasMananaFormateadas);

              //Obtener día y mes report
              const partesFecha = item.fecha_report.split('-');
              const date = new Date(`${partesFecha[2]}-${partesFecha[1]}-${partesFecha[0]}`);
          
              const diasSemana = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
              const nombreMeses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
          
              const diaSemana = diasSemana[date.getDay()];
              const nombreMes = nombreMeses[date.getMonth()];

              return {
                id_report_gh: item.id_report_gh,
                fecha_report: item.fecha_report,
                dia: diaSemana,
                mes: nombreMes,
                hora_salida_bodega: item.hora_salida_bodega,
                hora_llegada_faena: item.hora_llegada_faena,
                hora_termino_faena: item.hora_termino_faena,
                hora_llegada_bodega: item.hora_llegada_bodega,
                minimo_horas: item.minimo_horas,
                hora_inicio_colacion: item.hora_inicio_colacion,
                hora_termino_colacion: item.hora_termino_colacion,
                horas_manana: horasMananaFormateadas,
                horas_tarde: horasTardeFormateadas,
                total_horas_trabajadas: horasTotalesFormateadas
              };
            });

            const resultadoFinal = resultadoFormateado.map(item => ({
              id_report_gh: item.id_report_gh,
              fecha_report: item.fecha_report,
              dia: item.dia,
              mes: item.mes,
              hora_salida_bodega: item.hora_salida_bodega,
              hora_llegada_faena: item.hora_llegada_faena,
              hora_termino_faena: item.hora_termino_faena,
              hora_llegada_bodega: item.hora_llegada_bodega,
              minimo_horas: item.minimo_horas,
              hora_inicio_colacion: item.hora_inicio_colacion,
              hora_termino_colacion: item.hora_termino_colacion,
              horas_manana: item.horas_manana,
              horas_tarde: item.horas_tarde,
              total_horas_trabajadas: item.total_horas_trabajadas
            }));

            setDataReports(resultadoFinal);

            setHorasTrabajadasValor(data[0].valor_horas_trabajadas);

            //Consulta otros servicios asociados desde cotización
            const responseServicios = await fetch(
              `https://vittamaq.qa-gtalent.cl/api/detalle_cotizacion.php?folio_rbh=${folio}`
            );

            const dataServicios = await responseServicios.json();

            if (dataServicios && dataServicios[0].id_servicio) {
              setServiciosEstadoPagoData(dataServicios);

              const serviciosConGlosa = dataServicios.filter(servicio => servicio.glosa_servicio !== "");
              setServiciosAsignadosData(serviciosConGlosa);

              const primerServicioSinGlosa = dataServicios.find(servicio => servicio.glosa_servicio === "") || {};
              setValorMinimoHorasData(primerServicioSinGlosa.cantidad_minima);
            }

          } else {
            setDataReports([]);
          }
        }
      } catch (error) {
        alert("No se encontró información para los filtros indicados.");
      }
    }

    const [dataReportsGH, setDataReportsGH] = useState({});
    const [checkboxStates, setCheckboxStates] = useState({});
    const [serviciosAsignadosData, setServiciosAsignadosData] = useState([]);
    const [serviciosEstadoPagoData, setServiciosEstadoPagoData] = useState([]);
    const [valorMinimoHorasData, setValorMinimoHorasData] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
  
    const toggleCheckbox = (index, isChecked, id_report_gh, hora_inicio_manana, hora_termino_manana, total_horas_manana, hora_inicio_tarde, hora_termino_tarde, total_horas_tarde, total_horas_trabajadas) => {
      setCheckboxStates(prevState => {
        const newState = {
          ...prevState,
          [index]: !prevState[index]
        };
    
        setDataReportsGH(prevData => {
          const updatedData = { ...prevData };
          if (isChecked) {
            // Si el checkbox está marcado, actualizar los datos como de costumbre
            updatedData[id_report_gh] = {
              ...updatedData[id_report_gh],
              ["id_report_gh"]: id_report_gh,
              ["hora_inicio_manana"]: hora_inicio_manana,
              ["hora_termino_manana"]: hora_termino_manana,
              ["total_horas_manana"]: total_horas_manana,
              ["hora_inicio_tarde"]: hora_inicio_tarde,
              ["hora_termino_tarde"]: hora_termino_tarde,
              ["total_horas_tarde"]: total_horas_tarde,
              ["total_horas"]: total_horas_trabajadas 
            };
          } else {
            // Si el checkbox está desmarcado, eliminar el elemento correspondiente
            delete updatedData[id_report_gh];
          }
          return updatedData;
        });
    
        return newState;
      });
    };

    //Checkbox seleccionar todos los reports GH
    const handleSelectAll = () => {
      const newSelectAll = !selectAll;
      setSelectAll(newSelectAll);
  
      dataReports.forEach((item, index) => {
        const isChecked = newSelectAll;
        if (checkboxStates[index] !== isChecked) {
          toggleCheckbox(
            index,
            isChecked,
            item.id_report_gh,
            item.hora_llegada_faena,
            item.hora_inicio_colacion,
            item.horas_manana,
            item.hora_termino_colacion,
            item.hora_termino_faena,
            item.horas_tarde,
            item.total_horas_trabajadas
          );
        }
      });
    };

    function convertirHoraADecimal(hora) {
      const partes = hora.split(':');
      const horas = parseInt(partes[0]);
      const minutos = parseInt(partes[1]) / 60;
      const segundos = parseInt(partes[2]) / 3600;
      return horas + minutos + segundos;
    }

    useEffect(() => {
      const suma = Object.values(dataReportsGH).reduce(
        (accumulator, item) => {
          let totalHoras = convertirHoraADecimal(item.total_horas);

          accumulator.total_horas += parseFloat(totalHoras);
          accumulator.total_horas = accumulator.total_horas < Number(valorMinimoHorasData) ? Number(valorMinimoHorasData) : accumulator.total_horas;
          return accumulator;
        },
        { total_horas: 0 }
      );

      const sumaServicios = Object.values(serviciosAsignadosData).reduce(
        (accumulator, item) => {
          accumulator.valor_total += parseFloat(item.valor_total);
          return accumulator;
        },
        { valor_total: 0 }
      );

      let hhTrabValor = Math.trunc(horasTrabajadasValor).toLocaleString('es-ES', { useGrouping: true }).replace('.', '');

      const nuevoArreglo = Object.entries(dataReportsGH).map(([id, item]) => ({
        total_horas_trabajadas: suma.total_horas,
        horas_trabajadas_valor: Number(hhTrabValor).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.'),
        horas_trabajadas_valor_total: Number(Number(suma.total_horas) * Number(hhTrabValor)).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.'),
        total_neto: Number(Number(Number(suma.total_horas) * Number(hhTrabValor)) + Number(sumaServicios.valor_total)).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.'),
        iva: Number(Number(Number(Number(suma.total_horas) * Number(hhTrabValor)) + Number(sumaServicios.valor_total)) * 0.19).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.'),
        total: Number(Number(Number(Number(suma.total_horas) * Number(hhTrabValor)) + Number(sumaServicios.valor_total)) * 1.19).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.')
      }));

      const resultadosUnicos = Array.from(
        new Set(nuevoArreglo.map(JSON.stringify)),
        JSON.parse
      );
    
      setDataTotales(resultadosUnicos);

      setTotalNetoInicial(0);

      setEstadoPagoData({
        ...dataEstadoPago,
        ["horas_trabajadas_cantidad"]: suma.total_horas.toFixed(2),
        ["horas_trabajadas_valor"]: Number(horasTrabajadasValor),
        ["horas_trabajadas_valor_total"]: Number(Number(suma.total_horas) * Number(horasTrabajadasValor)),
        ["total_neto"]: Number(Number(Number(suma.total_horas) * Number(horasTrabajadasValor)) + Number(sumaServicios.valor_total)),
        ["iva"]: Number(Number(Number(Number(suma.total_horas) * Number(horasTrabajadasValor)) + Number(sumaServicios.valor_total)) * 0.19),
        ["total"]: Number(Number(Number(Number(suma.total_horas) * Number(horasTrabajadasValor)) + Number(sumaServicios.valor_total)) * 1.19)
      });

      //console.log(JSON.stringify(resultadosUnicos));
    }, [dataReportsGH, serviciosAsignadosData]);

    const handleAgregarServicio = (folio_ep) => {
      setSecondModalOpen(true);
      setSecondModalContent(<AgregarServicioModalContent folio={folio_ep} />);
    };

    const [dataTotales, setDataTotales] = useState({
      total_horas_trabajadas: 0,
      horas_trabajadas_cantidad: 0,
      horas_trabajadas_valor: 0,
      horas_trabajadas_valor_total: 0,
      total_neto: 0,
      iva: 0,
      total: 0
    });

    const [dataOtrosDescuentos, setOtrosDescuentos] = useState(0);
    const [dataTotalNetoInicial, setTotalNetoInicial] = useState(0);
    const handleOtrosDescuentos = (otro_descuento, total_neto_valor) => {
      if (/^\d*$/.test(otro_descuento)) {
        const nuevoDescuento = Number(otro_descuento) || 0;
        setOtrosDescuentos(nuevoDescuento);

        dataEstadoPago.otros_descuentos_ep = nuevoDescuento;

        if (dataTotalNetoInicial === 0) {
          const totalNeto = Number(total_neto_valor.replace(/\./g, ''));
          setTotalNetoInicial(totalNeto);

          let totalNetoMostrado = nuevoDescuento === 0 ? totalNeto : totalNeto - nuevoDescuento;
          dataTotales[0].total_neto = totalNetoMostrado.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/,/g, '.');
          dataTotales[0].iva = Number(Number(totalNetoMostrado * 0.19).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '')).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.');
          dataTotales[0].total = Number(Number(totalNetoMostrado * 1.19).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '')).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.');

          setEstadoPagoData({
            ...dataEstadoPago,
            ["total_neto"]: Number(totalNetoMostrado),
            ["iva"]: Number(Number(totalNetoMostrado * 0.19).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '')),
            ["total"]: Number(Number(totalNetoMostrado * 1.19).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, ''))
          });
        } else {
          let totalNetoConDescuento = dataTotalNetoInicial - nuevoDescuento;
          let totalNetoMostrado = nuevoDescuento === 0 ? dataTotalNetoInicial : totalNetoConDescuento;
          dataTotales[0].total_neto = totalNetoMostrado.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/,/g, '.');
          dataTotales[0].iva = Number(Number(totalNetoMostrado * 0.19).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '')).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.');
          dataTotales[0].total = Number(Number(totalNetoMostrado * 1.19).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '')).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.');

          setEstadoPagoData({
            ...dataEstadoPago,
            ["total_neto"]: Number(totalNetoMostrado),
            ["iva"]: Number(Number(totalNetoMostrado * 0.19).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '')),
            ["total"]: Number(Number(totalNetoMostrado * 1.19).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, ''))
          });
        }
      }
    };
    
    function AgregarServicioModalContent({ folio }) {
      useEffect(() => {
        obtenerServicios(folio);
      }, []);

      const [servicios, setServicios] = useState([]);
      const obtenerServicios = async (folio) => {
        try {
          const response = await fetch(
            `https://vittamaq.qa-gtalent.cl/api/estado_pago_gh.php?folio=${folio}`
          );
          const data = await response.json();
    
          if (data && data[0].folio) {
            setServicios(data);
          }
        } catch (error) {
          setServicios([]);
        }
      };

      const [checkboxStates, setCheckboxStates] = useState({});
      const [formServiciosData, setFormServiciosData] = useState({});
      const toggleCheckbox = (index, id_servicio, glosa_servicio, cantidad_minima, id_unidad_medida, valor_unitario_neto) => {
        setCheckboxStates((prevState) => {
          const newState = {
            ...prevState,
            [index]: !prevState[index],
          };

          setOtrosDescuentos(0);

          setEstadoPagoData({
            ...dataEstadoPago,
            ["otros_descuentos_ep"]: 0,
            ["detalle_otros_descuentos_ep"]: ""
          });
      
          setFormServiciosData((prevData) => {
            if (!newState[index]) {
              setDataReports(prevReports =>
                prevReports.map(servicio => 
                  servicio.index === index
                    ? { ...servicio, id_servicio: id_servicio, glosa_servicio: glosa_servicio, cantidad_minima: cantidad_minima, id_unidad_medida: id_unidad_medida, valor_unitario_neto: valor_unitario_neto }
                    : servicio
                )
              );
  
              const { [index]: _, ...restData } = prevData;
              return restData;
            }
      
            return {
              ...prevData,
              [index]: {
                ...prevData[index],
                ["id_servicio"]: id_servicio,
                ["glosa_servicio"]: glosa_servicio,
                ["cantidad_minima"]: cantidad_minima,
                ["id_unidad_medida"]: id_unidad_medida,
                ["valor_unitario_neto"]: valor_unitario_neto,
                ["valor_total"]: 0
              },
            };
          });
      
          return newState;
        });
      };   

      useEffect(() => {
        const nuevoArreglo = Object.keys(formServiciosData).map(key => {
          const item = formServiciosData[key];

          return {
            id_servicio: item.id_servicio,
            glosa_servicio: item.glosa_servicio,
            cantidad_minima: item.cantidad_minima,
            id_unidad_medida: item.id_unidad_medida,
            valor_unitario_neto: Number(item.valor_unitario_neto),
            valor_total: Number(item.cantidad_minima) * Number(item.valor_unitario_neto),
            id_usuario: dataLogin.id_usuario
          };
        });
  
        const resultadosUnicos = Array.from(
          new Set(nuevoArreglo.map(JSON.stringify)),
          JSON.parse
        );

        setServiciosAsignadosData(resultadosUnicos);

        setTotalNetoInicial(0);

      }, [formServiciosData]);
  
      return (
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="mb-0">Agregar Servicio: Grúa Hidráulica</h4>
              <button onClick={closeSecondModal} className="btn-close"></button>
            </div>
            <div className="modal-body">
              <div className="table-responsive">
                <table className="table table-hover mb-0">
                  <thead>
                    <tr className="table-active">
                      <th scope="col"></th>
                      <th scope="col">Servicio</th>
                      <th scope="col">Cantidad Mínima</th>
                      <th scope="col">Unidad Medida</th>
                      <th scope="col">Valor Unitario Neto más IVA</th>
                    </tr>
                  </thead>
                  <tbody>
                    {servicios.length > 0 ? (
                      servicios.map((servicio, index) => (
                        <tr key={index}>
                          <td>
                            <input 
                              className="form-check-input" 
                              type="checkbox" 
                              checked={checkboxStates[index] || false}
                              onChange={() => toggleCheckbox(index, servicio.id_servicio, servicio.glosa_servicio, servicio.cantidad_minima, servicio.id_unidad_medida, servicio.valor_unitario_neto)}
                            />
                          </td>
                          <td>{servicio.glosa_servicio}</td>
                          <td>{servicio.cantidad_minima}</td>
                          <td>{servicio.unidad_medida}</td>
                          <td><small className="text-secondary">$ </small>{Number(servicio.valor_unitario_neto).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.')}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="text-center ps-1" colSpan={5}>No se encontraron servicios</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="modal-footer">
              <button className="btn btn-yellow" onClick={closeSecondModal}>Cerrar</button>
            </div>
          </div>
        </div>
      );
    }

    const navigate = useNavigate();
    const handleSubmitEstadoPagoGH = async (event) => {
      event.preventDefault();

      try {
        const esFormularioValido = validaFormularioSubmit();

        if (Object.keys(dataReportsGH).length === 0) {
          alert('Debe agregar al menos un report');
        } else if (esFormularioValido) {
          const response = await fetch(
            `https://vittamaq.qa-gtalent.cl/api/estado_pago_gh.php`,
            {
              method: "POST",
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json', 
              },
              body: JSON.stringify(dataEstadoPago),
            }
          );
  
          const dataResponse = await response.json();          
          if (dataResponse.message === "Estado de pago agregado con éxito.") {
            const estadoPagoReports = Object.keys(dataReportsGH).reduce((acc, key) => {
              if (typeof dataReportsGH[key] === 'object') {
                acc[key] = { ...dataReportsGH[key], id_estado_pago_gh: dataResponse.id_estado_pago_gh, id_usuario: dataLogin.id_usuario };
              } else {
                acc[key] = dataReportsGH[key];
              }
              return acc;
            }, {});
  
            const responseReports = await fetch(
              `https://vittamaq.qa-gtalent.cl/api/estado_pago_reports_gh.php`,
              {
                method: "POST",
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json', 
                },
                body: JSON.stringify(estadoPagoReports),
              }
            );
    
            const dataResponseReports = await responseReports.json();
            if (dataResponseReports.message === "Reports de estado de pago agregados con éxito.") {
              const serviciosAsignadosEstadoPagoGH = serviciosEstadoPagoData.map(item => ({
                  ...item,
                  id_estado_pago_gh: dataResponse.id_estado_pago_gh,
                  id_usuario: dataLogin.id_usuario
              }));

              const responseServicios = await fetch(
                `https://vittamaq.qa-gtalent.cl/api/estado_pago_servicios_gh.php`,
                {
                  method: "POST",
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json', 
                  },
                  body: JSON.stringify(serviciosAsignadosEstadoPagoGH),
                }
              );
      
              const dataResponseServicios = await responseServicios.json();
              if (dataResponseServicios.message === "Servicios de estado de pago agregados con éxito.") {    
                const responseReportsActualizados = await fetch(
                  `https://vittamaq.qa-gtalent.cl/api/report_gh.php`,
                  {
                    method: "PUT",
                    headers: {
                      'Accept': 'application/json', // Indica que esperas una respuesta JSON
                      'Content-Type': 'application/json', 
                    },
                    body: JSON.stringify(dataReportsGH),
                  }
                );
        
                const dataResponseReportsActualizados = await responseReportsActualizados.json();
                if (dataResponseReportsActualizados.message === "Reports de estado de pago actualizados con éxito.") {
                  setEstadoPagoData({
                    folio_ep: "",
                    id_obra_ep: "",
                    id_cliente_ep: "",
                    id_cliente_final_ep: "",
                    horas_trabajadas_cantidad: "",
                    horas_trabajadas_valor: "",
                    horas_trabajadas_valor_total: "",
                    otros_descuentos_ep: "",
                    detalle_otros_descuentos_ep: "",
                    total_neto: "",
                    iva: "",
                    total: "",
                    id_usuario: ""
                  });
      
                  setDataReportsGH([]);
                  setServiciosAsignadosData([]);
        
                  alert("Estado de pago agregado con éxito.");
        
                  navigate("/dashboard", { state: dataLogin });
  
                  closeModal();
                }
              }
            }
          } else {
            throw new Error();
          }
        }
      } catch (error) {
        alert(error);
      }
    }

    const validaFormulario = () => {
      if (clienteSelected === "") {
        alert('Debe seleccionar un cliente');
        return false;
      }

      if (dataEstadoPago.folio_ep.trim() === '') {
        alert('Debe seleccionar un folio');
        return false;
      }

      if (dataConsultaReports.fecha_desde_ep.trim() === '') {
        alert('Debe indicar una fecha desde');
        return false;
      }

      if (dataConsultaReports.fecha_hasta_ep.trim() === '') {
        alert('Debe indicar una fecha hasta');
        return false;
      }

      return true;
    }

    const validaFormularioSubmit = () => {
      if (clienteSelected === "") {
        alert('Debe seleccionar un cliente');
        return false;
      }

      if (dataEstadoPago.folio_ep.trim() === '') {
        alert('Debe seleccionar un folio');
        return false;
      }

      if (dataConsultaReports.fecha_desde_ep.trim() === '') {
        alert('Debe indicar una fecha desde');
        return false;
      }

      if (dataConsultaReports.fecha_hasta_ep.trim() === '') {
        alert('Debe indicar una fecha hasta');
        return false;
      }

      if (dataEstadoPago.otros_descuentos_ep !== 0) {
        if (dataEstadoPago.detalle_otros_descuentos_ep.trim() === '') {
          alert('Debe indicar el detalle de otros descuentos, de lo contrario su valor debe ser 0');
          return false;
        }  
      }

      return true;
    }

    // Inicializa los tooltips
    useEffect(() => {
      // Verifica si Bootstrap está disponible en window
      if (typeof window.bootstrap !== 'undefined') {
        const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        tooltipTriggerList.map(function (tooltipTriggerEl) {
          return new window.bootstrap.Tooltip(tooltipTriggerEl);
        });
      }
    }, [dataReports]);

    useEffect(() => {
      console.log(JSON.stringify(dataTotales,null,2))
    }, [dataTotales]);

    return (
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xxl">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Agregar Estado de Pago: Grúa Hidráulica</h4>
            <button onClick={closeModal} className="btn-close"></button>
          </div>
          <div className="modal-body py-3 px-5">
            <div className="row row-cols-lg-4 mb-3">
              <div className="col">
                <label className="form-label">Cliente</label>
                <Select
                  name="id_cliente_ep" 
                  value={clienteSelected.value} 
                  onChange={handleClienteSelect}
                  options={optionsClientes}
                  isSearchable
                  className="react-select"
                  placeholder="Seleccione un cliente"
                />
              </div>
              <div className="col">
                <label className="form-label">Obra</label>
                <select className="form-select" name="id_obra_ep" value={dataConsultaReports.id_obra_ep} onChange={(e) => handleObraSelect(e.target.value)}>
                  <option value="">Seleccione una obra</option>
                  {clienteObra.map((obra, index) => (
                    <option key={index} value={obra.id_obra}>{obra.nombre}</option>
                  ))}
                </select>
              </div>
              <div className="col">
                <label className="form-label">Cliente Final</label>
                <select className="form-select" name="id_cliente_final_ep" value={dataEstadoPago.id_cliente_final_ep} disabled>
                  <option value="">Seleccione un cliente final</option>
                  {optionsClientes.map((cliente_final, index) => (
                    <option key={index} value={cliente_final.value}>{cliente_final.label}</option>
                  ))}
                </select>
              </div>
              {folios ? (
                <div className="col">
                  <label className="form-label">Folio</label>
                  <select className="form-select" name="folio_ep" value={dataEstadoPago.folio_ep} onChange={handleFormEstadoPagoData}>
                    <option value="">Seleccione un folio</option>
                    {folios.map((folio, index) => (
                      <option key={index} value={folio.folio}>{folio.folio}</option>
                    ))}
                  </select>
                </div>
                ) : (
                <div className="col">
                  <label className="form-label">Folio</label>
                  <select className="form-select" name="folio_ep">
                    <option value="">No se encontraron folios</option>
                  </select>
                </div>
              )}
            </div>
            <div className="row row-cols-lg-4 align-items-end mb-4">
              <div className="col">
                <label className="form-label">Fecha Desde</label>
                <input 
                  className="form-control" 
                  type="date" 
                  name="fecha_desde_ep"
                  value={dataConsultaReports.fecha_desde_ep}
                  max={today.toISOString().split('T')[0]}
                  onChange={(e) => setDataConsultaReports({
                    ...dataConsultaReports,
                    fecha_desde_ep: e.target.value
                  })}
                />
              </div>
              <div className="col">
                <label className="form-label">Fecha Hasta</label>
                <input 
                  className="form-control" 
                  type="date" 
                  name="fecha_hasta_ep"
                  value={dataConsultaReports.fecha_hasta_ep}
                  max={today.toISOString().split('T')[0]}
                  onChange={(e) => setDataConsultaReports({
                    ...dataConsultaReports,
                    fecha_hasta_ep: e.target.value
                  })}
                />
              </div>
              <div className="col">
                <label className="form-label d-block">&nbsp;</label>
                <button className="btn btn-dark" onClick={handleSubmitBuscarReports}>
                  <i className="bi bi-search"></i> Buscar
                </button>
              </div>
            </div>
            <h5 className="mb-3">Detalle Reports</h5>
            <div className="table-responsive border rounded mb-4">
              <table className="table table-hover table-sm text-center mb-0">
                <thead>
                  <tr className="border-bottom">
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col" className="border-end"></th>
                    <th scope="col" className="border-end" colSpan={3}>Mañana</th>
                    <th scope="col" className="border-end" colSpan={3}>Tarde</th>
                    <th scope="col"></th>
                  </tr>
                  <tr className="table-active border-bottom">
                    <th scope="col">
                      {dataReports.length > 0 ? (
                        <div className="form-check">
                          <input 
                            className="form-check-input" 
                            type="checkbox"
                            checked={selectAll}
                            onChange={handleSelectAll}
                          />
                        </div>
                      ) : (
                        <span></span>
                      )}
                    </th>
                    <th scope="col">N° Report</th>
                    <th scope="col">Fecha</th>
                    <th scope="col">Día</th>
                    <th scope="col">Mes</th>
                    <th scope="col">Hora Inicio</th>
                    <th scope="col">Hora Término</th>
                    <th scope="col">Horas Mañana</th>
                    <th scope="col">Hora Inicio</th>
                    <th scope="col">Hora Término</th>
                    <th scope="col">Horas Tarde</th>
                    <th scope="col">
                      Total Horas&nbsp;
                      <small data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Indica mínimo" title="Indica mínimo">
                        <i className="bi bi-info-circle text-secondary"></i>
                      </small>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {dataReports.length > 0 ? (
                    dataReports.map((report, index) => (
                      <tr key={index}>
                        <td>
                          <div className="form-check">
                            <input 
                              className="form-check-input" 
                              type="checkbox" 
                              checked={checkboxStates[index] || false}
                              onChange={(e) => toggleCheckbox(index, e.target.checked, report.id_report_gh, report.hora_llegada_faena, report.hora_inicio_colacion, report.horas_manana, report.hora_termino_colacion, report.hora_llegada_bodega, report.horas_tarde, report.total_horas_trabajadas)}
                              /* checked={checkboxes[index] || false}
                              onChange={() => handleCheckboxChange(index)} */
                            />
                          </div>
                        </td>
                        <td>{report.id_report_gh}</td>
                        <td>{report.fecha_report}</td>
                        <td>{report.dia}</td>
                        <td>{report.mes}</td>
                        <td>{report.hora_llegada_faena.substring(0, 5)}</td>
                        <td>{report.hora_inicio_colacion.substring(0, 5)}</td>
                        <td>{report.horas_manana.substring(0, 5)}</td>
                        <td>{report.hora_termino_colacion.substring(0, 5)}</td>
                        <td>{report.hora_llegada_bodega.substring(0, 5)}</td>
                        <td>{report.horas_tarde.substring(0, 5)}</td>
                        {/* <td>{report.total_horas_trabajadas.substring(0, 5)}</td> */}
                        <td>
                          {report.total_horas_trabajadas.substring(0, 5)}&nbsp;
                          {report.total_horas_trabajadas.substring(0, 5) < report.minimo_horas.substring(0, 5) && (
                            <small data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title={report.minimo_horas.substring(0, 5)} title={report.minimo_horas.substring(0, 5)}>
                              <i className="bi bi-info-circle text-secondary"></i>
                            </small>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="text-center ps-1" colSpan={12}>No se encontraron reports</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            
              <div className="row row-cols-md-2">
                <div className="col">
                  <div className="d-flex justify-content-between mb-3">
                    <h5 className="mb-0">Total Descripción</h5>
                   {/*  {dataReports.length > 0 ? (
                      <button className="btn btn-dark btn-sm" onClick={(e) => handleAgregarServicio(dataConsultaReports.folio_ep)}><i className="bi bi-plus-circle"></i> Agregar Servicio</button>
                    ) : (
                      <button className="btn btn-dark btn-sm" disabled><i className="bi bi-plus-circle"></i> Agregar Servicio</button>
                    )} */}
                  </div>
                  <div className="table-responsive border rounded">
                    <table className="table table-sm mb-0">
                      <thead>
                        <tr className="table-active border-bottom">
                          <th className="col-6 ps-2">Descripción</th>
                          <th className="col-2">Cantidad</th>
                          <th className="col-2">Valor</th>
                          <th className="col-2 pe-2">Valor Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataTotales.length > 0 ? (
                          <tr>
                            <td className="ps-2">Horas Trabajadas</td>
                            <td>{dataTotales[0].total_horas_trabajadas.toFixed(2)}</td>
                            <td><small className="text-secondary">$ </small>{dataTotales[0].horas_trabajadas_valor}</td>
                            <td className="pe-2"><small className="text-secondary">$ </small>{dataTotales[0].horas_trabajadas_valor_total}</td>
                          </tr>
                        ) : (
                          <tr>
                            <td className="ps-2">Horas Trabajadas</td>
                            <td>0</td>
                            <td><small className="text-secondary">$ </small>0</td>
                            <td className="pe-2"><small className="text-secondary">$ </small>0</td>
                          </tr>
                        )}
                        {serviciosAsignadosData.length > 0 && (
                          serviciosAsignadosData.map((servicio, index) => (
                            <tr key={index}>
                              <td className="ps-2">{servicio.glosa_servicio}</td>
                              <td>{servicio.cantidad_minima}</td>
                              <td><small className="text-secondary">$ </small>{Number(servicio.valor_unitario_neto).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.')}</td>
                              <td className="pe-2"><small className="text-secondary">$ </small>{Number(servicio.valor_total).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.')}</td>
                            </tr>
                          ))
                        )}
                        {/* {serviciosAsignadosData.length > 0 && (
                          serviciosAsignadosData.map((servicio, index) => {
                            if (servicio.id_servicio === 75) { //Ayudante Diario
                              return (
                                <tr key={index}>
                                  <td className="ps-2">{servicio.glosa_servicio}</td>
                                  <td>{servicio.cantidad_minima}</td>
                                  <td><small className="text-secondary">$ </small>{Number(servicio.valor_unitario_neto).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.')}</td>
                                  <td className="pe-2"><small className="text-secondary">$ </small>{Number(servicio.valor_total).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.')}</td>
                                </tr>
                              );
                            } else {
                              return (
                                <tr key={index}>
                                  <td className="ps-2">{servicio.glosa_servicio}</td>
                                  <td>{servicio.cantidad_minima}</td>
                                  <td><small className="text-secondary">$ </small>{Number(servicio.valor_unitario_neto).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.')}</td>
                                  <td className="pe-2"><small className="text-secondary">$ </small>{Number(servicio.valor_total).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.')}</td>
                                </tr>
                              );
                            }
                          })
                        )} */}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col">
                  <h5 className="mb-3">Valores Totales</h5>
                  <div className="table-responsive border rounded mb-4">
                    <table className="table table-sm mb-0">
                      <thead>
                        <tr className="table-active border-bottom">
                          <th scope="col" className="ps-2">Total Neto</th>
                          <th scope="col">IVA</th>
                          <th scope="col" className="text-dark fw-semibold pe-2">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataTotales.length > 0 ? (
                          <tr>
                            <td className="ps-2"><small className="text-secondary">$ </small>{dataTotales[0].total_neto}</td>
                            <td><small className="text-secondary">$ </small>{dataTotales[0].iva}</td>
                            <td className="pe-2"><small className="text-secondary">$ </small><span className="text-orange">{dataTotales[0].total}</span></td>
                          </tr>
                        ) : (
                          <tr>
                            <td className="ps-2"><small className="text-secondary">$ </small>0</td>
                            <td><small className="text-secondary">$ </small>0</td>
                            <td className="pe-2"><small className="text-secondary">$ </small><span className="text-orange">0</span></td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="row row-cols-md-2">
                    <div className="col">
                      <label className="form-label">Otros Descuentos</label>
                      {dataTotales.length > 0 ? (
                        <input 
                          className="form-control" 
                          type="text" 
                          name="otros_descuentos_ep" 
                          value={dataOtrosDescuentos}
                          onChange={(e) => handleOtrosDescuentos(e.target.value, dataTotales[0].total_neto)}
                        />
                      ) : (
                        <input 
                          className="form-control" 
                          type="text" 
                          name="otros_descuentos_ep" 
                          disabled
                        />
                      )}
                    </div>
                    <div className="col">
                      <label className="form-label">Detalle Descuentos</label>
                      <input 
                        className="form-control" 
                        type="text" 
                        name="detalle_otros_descuentos_ep" 
                        value={dataEstadoPago.detalle_otros_descuentos_ep}
                        onChange={(e) => setEstadoPagoData({
                          ...dataEstadoPago,
                          ["detalle_otros_descuentos_ep"]: e.target.value
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-yellow" onClick={handleSubmitEstadoPagoGH}><i className="bi bi-floppy"></i> Guardar</button>
          </div>
        </div>
      </div>
    );
  }

  function EstadoPagoGHModalContent({ data }) {
    const [tiposDocumentos, setTiposDocumentos] = useState([]);
    const [estadoPagoEstado, setEstadoPagoEstado] = useState(data.id_estado_pago_estado);
    const [estadoPagoReportsGH, setEstadoPagoReportsGH] = useState([]);
    const [estadoPagoServiciosGH, setEstadoPagoServiciosGH] = useState([]);
    const [estadoPagoDocumentosGH, setEstadoPagoDocumentosGH] = useState([]);
    const [detalleCotizacion, setDetalleCotizacion] = useState([]);

    useEffect(() => {
      obtenerTiposDocumentos();
      obtenerReportsGH(data.id_estado_pago_gh);
      obtenerServiciosGH(data.id_estado_pago_gh);
      obtenerDocumentosGH(data.id_estado_pago_gh);
      obtenerDetalleCotizacion(data.folio);
    }, []);

    const obtenerTiposDocumentos = async () => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/tipo_documento.php`
        );
        const data = await response.json();
        
        if (data && data[0].id_tipo_documento) {
          setTiposDocumentos(data);
        }
      } catch (error) {
        setTiposDocumentos([]);
      }
    };

    const obtenerDocumentosGH = async (id_estado_pago_gh) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/estado_pago_documentos_gh.php?id_estado_pago_gh=${id_estado_pago_gh}`
        );
        const data = await response.json();
        
        if (data && data[0].id_estado_pago_documentos_gh) {
          setEstadoPagoDocumentosGH(data);
        }
      } catch (error) {
        setEstadoPagoDocumentosGH([]);
      }
    };

    const obtenerDetalleCotizacion = async (folio) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/detalle_cotizacion.php?folio=${folio}`
        );
        const data = await response.json();
  
        if (data && data[0].id_servicio) {
          setDetalleCotizacion(data);
        }
      } catch (error) {
        setDetalleCotizacion([]);
      }
    };

    const [dataFechaReports, setDataFechaReports] = useState({});
    const obtenerReportsGH = async (id_estado_pago_gh) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/estado_pago_reports_gh.php?id_estado_pago_gh=${id_estado_pago_gh}`
        );
        const data = await response.json();
  
        if (data && data[0].id_estado_pago_gh) {
          setEstadoPagoReportsGH(data);
          
          //Obtener día y mes report
          const partesFecha = data[0].fecha_report.split('-');
          const date = new Date(`${partesFecha[2]}-${partesFecha[1]}-${partesFecha[0]}`);
      
          const diasSemana = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
          const nombreMeses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

          setDataFechaReports({
            ...dataFechaReports,
            ["fecha_report"]: data[0].fecha_report,
            ["dia"]: diasSemana[date.getDay()],
            ["mes"]: nombreMeses[date.getMonth()]
          });
        }
      } catch (error) {
        setEstadoPagoReportsGH([]);
      }
    };

    const obtenerServiciosGH = async (id_estado_pago_gh) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/estado_pago_servicios_gh.php?id_estado_pago_gh=${id_estado_pago_gh}`
        );
        const data = await response.json();
  
        if (data && data[0].id_estado_pago_gh) {
          setEstadoPagoServiciosGH(data);
        }
      } catch (error) {
        setEstadoPagoServiciosGH([]);
      }
    };

    const handleModificaEstado = (data_estado_pago, id_estado_pago_estado) => {
      setEstadoPagoEstado(id_estado_pago_estado);

      setSecondModalOpen(true);
      
      setSecondModalContent(<ConfirmaModificaEstadoModalContent dataEstadoPago={data_estado_pago} idEstadoPagoEstado={id_estado_pago_estado} />);
    };

    function ConfirmaModificaEstadoModalContent ({ dataEstadoPago, idEstadoPagoEstado }) {
      const obtenerEstadoPorId = (idEstadoPagoEstado) => {
        const estadoEncontrado = estadosPagoEstado.find(estado => estado.id_estado_pago_estado === idEstadoPagoEstado);
        return estadoEncontrado ? estadoEncontrado.estado_pago_estado : 'Estado no encontrado';
      };

      const estado = obtenerEstadoPorId(Number(idEstadoPagoEstado));
      
      let jsonPIGT = null;
      if (idEstadoPagoEstado === '3') {
        //JSON para PIG-T
        const objetoPIGT = (dataCotizacion, detalleCotizacion, detalleDocumentos) => {
          const nuevoObjeto = {
            "cabecera_cotizacion": {
              "nombre_cliente": dataCotizacion.nombre_cliente,
              "rut_cliente": dataCotizacion.rut_cliente,
              "razon_social": dataCotizacion.razon_social,
              "giro": dataCotizacion.giro,
              "direccion_cliente": dataCotizacion.direccion,
              "region": dataCotizacion.region,
              "comuna": dataCotizacion.comuna,
              "telefono_representante": dataCotizacion.telefono_representante,
              "fecha_cotizacion": dataCotizacion.fecha_cotizacion,
              "folio": dataCotizacion.folio,
              "obra": dataCotizacion.nombre_obra,
              "nombre_solicitante": dataCotizacion.nombre_solicitante,
              "rut_solicitante": dataCotizacion.rut_solicitante,
              "email_solicitante": dataCotizacion.email_solicitante,
              "fecha_inicio": dataCotizacion.fecha_inicio,
              "fecha_fin": dataCotizacion.fecha_fin,
              "bodega": dataCotizacion.bodega,
              "usuario_registra": dataCotizacion.usuario_registra
            },
            "servicios_cotizacion": detalleCotizacion,
            "documentos_cotizacion": detalleDocumentos
          };
        
          return nuevoObjeto;
        };

        const glosasServicios = detalleCotizacion.map(servicio => ({
          descripcion_servicio: servicio.descripcion !== "" ? servicio.descripcion : servicio.nombre_maquinaria + " " + servicio.tipo_maquinaria + " " + servicio.tipo_arriendo,
        }));

        const glosasServiciosConcatenados = glosasServicios.map(servicio => servicio.descripcion_servicio).join('\n');

        const cotizacion = detalleCotizacion[0];
        const detalleCotizacionPigT = {
          descripcion_servicio: glosasServiciosConcatenados,
          tipo_maquinaria: cotizacion.tipo_maquinaria,
          tipo_arriendo: cotizacion.tipo_arriendo,
          sku: "GH0",
          glosa_servicio: "Cotización Referencia: " + data.folio,
          unidad_medida: cotizacion.unidad_medida,
          unidad_medida_alias: cotizacion.unidad_medida_alias,
          cantidad: "1",
          total_neto: Number(data.total_neto).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, ''),
          iva: Number(data.iva).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, ''),
          descuentos: Number(data.otros_descuentos).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, ''),
          total: Number(data.total).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '')
        };

        const detalleDocumentos = estadoPagoDocumentosGH.map(doc => {
          if (doc.tipo_documento === "HES") {
            return { ...doc, tipo_documento: "HES (Hoja Entrada De Servicio)" };
          } else if (doc.tipo_documento === "Orden de Trabajo") {
            return { ...doc, tipo_documento: "OT (Orden de Trabajo)" };
          } else {
            return doc;
          }
        });
  
        jsonPIGT = objetoPIGT(data, detalleCotizacionPigT, detalleDocumentos);
        //console.log(JSON.stringify(jsonPIGT,null,2));
      }

      return (
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="mb-0">Modificar Estado: {dataEstadoPago.folio}</h4>
              <button onClick={closeSecondModal} className="btn-close"></button>
            </div>
            <div className="modal-body">
              <p className="fs-5">¿Está seguro que desea modificar el estado del estado de pago a: {idEstadoPagoEstado === '2' ? <span className="text-red">{estado}</span> : <span className="text-orange">{estado}</span>} ?</p>
            </div>
            <div className="modal-footer">
              <button className={idEstadoPagoEstado === '2' ? "btn btn-danger" : "btn btn-yellow"} onClick={(e) => handleSubmitModificarEstado(e, dataEstadoPago.id_estado_pago_gh, idEstadoPagoEstado, jsonPIGT)}>
              {idEstadoPagoEstado === '2' ? (
                <span>
                  <i className="bi bi-dash-circle"></i> Anular Estado de Pago
                </span>
              ) : (
                <span>
                  <i className="bi bi-check-circle"></i> Facturar Estado de Pago
                </span>
              )}
              </button>
            </div>
          </div>
        </div>
      );
    }

    const handleSubmitModificarEstado = async (event, idEstadoPagoGH, idEstadoPagoEstado, jsonPIGT) => {
      event.preventDefault();
  
      try {
        const formModificarEstado = { id_estado_pago_gh: idEstadoPagoGH, id_estado_pago_estado: idEstadoPagoEstado };

        const responseModificarEstado = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/estado_pago_gh.php`,
          {
            method: "PUT",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json', 
            },
            body: JSON.stringify(formModificarEstado),
          }
        );

        const dataModificarEstado = await responseModificarEstado.json();
        if (dataModificarEstado.message === "Estado de pago modificado con éxito.") {

          if (idEstadoPagoEstado === "2") {

            const habilitaReportsData = {
              habilita_report: 1,
              reports: estadoPagoReportsGH.map(item => ({
                id_report_gh: item.id_report_gh
              }))
            };

            const responseReportsActualizados = await fetch(
              `https://vittamaq.qa-gtalent.cl/api/report_gh.php`,
              {
                method: "PUT",
                headers: {
                  'Accept': 'application/json', // Indica que esperas una respuesta JSON
                  'Content-Type': 'application/json', 
                },
                body: JSON.stringify(habilitaReportsData),
              }
            );
    
            const dataResponseReportsActualizados = await responseReportsActualizados.json();
            if (dataResponseReportsActualizados.message === "Report GH habilitado exitosamente.") {
              alert("Estado de pago modificado con éxito.");
          
              obtenerEstadosPagoGH();
    
              closeSecondModal();
              closeModal();
    
              navigate("/dashboard", { state: dataLogin });
            }
          } else {
            alert("Estado de pago modificado con éxito.");

            if (jsonPIGT != null) {
              const responseKame = await fetch(
                `https://app.pig-t.cl/vittamaq_Cotizaciones_Kame`,
                {
                  method: "POST",
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json', 
                  },
                  body: JSON.stringify(jsonPIGT),
                }
              );
        
              const dataResponseKame = await responseKame.json();
              //console.log(JSON.stringify(dataResponseKame,null,2));
            }
          
            obtenerEstadosPagoGH();
  
            closeSecondModal();
            closeModal();
  
            navigate("/dashboard", { state: dataLogin });
          }
        } else {
          throw new Error();
        }
        
      } catch (error) {
        obtenerEstadosPagoGH();
      }
    }

    //DOCUMENTOS
    const [activeTab, setActiveTab] = useState('valores');
    const handleTabClick = (tab) => {
      setActiveTab(tab);
    };

    const filaPredeterminada = { 
      id: 1, 
      folio: data.folio,
      id_estado_pago_gh: data.id_estado_pago_gh,
      id_tipo_documento: '',
      detalle: '', 
      fecha: '', 
      id_usuario: dataLogin.id_usuario.toString()
    };
    const [filas, setFilas] = useState([filaPredeterminada]);

    const handleAgregarFila = () => {
      const nuevaFila = {
        id: filas.length + 1,
        folio: data.folio,
        id_estado_pago_gh: data.id_estado_pago_gh,
        id_tipo_documento: '',
        detalle: '', 
        fecha: '', 
        id_usuario: dataLogin.id_usuario.toString()
      };

      setFilas([...filas, nuevaFila]);
    };

    const handleInputChange = (id, campo, valor) => {
      const nuevasFilas = filas.map((fila) =>
        fila.id === id ? { ...fila, [campo]: valor } : fila
      );

      setFilas(nuevasFilas);
    };

    const handleEliminarFila = (id) => {
      if (filas.length > 1) {
        const nuevasFilas = filas.filter((fila) => fila.id !== id);
        setFilas(nuevasFilas);
      } else {
        alert("Debe agregar al menos un documento.");
      }
    };

    const handleConfirmaDocumentos = (e, filas) => {
      const esFormularioValido = validaFormularioDocumentos(filas);

      if (!esFormularioValido) {
        alert("Debe completar la información de los documentos.")
      } else {
        setSecondModalOpen(true);
        setSecondModalContent(<ConfirmaDocumentosModalContent documentos={filas} />);
      }
    };

    function ConfirmaDocumentosModalContent ({ documentos }) {
      const tipoDocumentoMap = tiposDocumentos.reduce((acc, tipo) => {
        acc[tipo.id_tipo_documento] = tipo.tipo_documento;
        return acc;
      }, {});

      const documentosConTipo = documentos.map(doc => {
        return {
          ...doc,
          tipo_documento: tipoDocumentoMap[doc.id_tipo_documento]
        };
      });
      
      return (
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="mb-0">Confirmar Documentos</h4>
              <button onClick={closeSecondModal} className="btn-close"></button>
            </div>
            <div className="modal-body">
              <p className="fs-5">¿Está seguro que desea ingresar los siguientes documentos?:</p>
              <table className="table table-hover table-sm mb-0">
                <thead>
                  <tr className="table-active">
                    <th className="ps-2">Tipo Documento</th>
                    <th>Número</th>
                    <th className="pe-2">Fecha</th>
                  </tr>
                </thead>
                <tbody>
                  {documentosConTipo.length > 0 ? (
                    documentosConTipo.map((documento, index) => (
                      <tr key={index}>
                        <td className="ps-2">{documento.tipo_documento}</td>
                        <td>{documento.detalle}</td>
                        <td className="pe-2">{documento.fecha.split('-').reverse().join('-')}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="text-center ps-1" colSpan={3}>No se encontraron documentos asociados</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button className="btn btn-yellow" onClick={handleSubmitDocumentos}><i className="bi bi-floppy"></i> Guardar</button>
            </div>
          </div>
        </div>
      );
    }

    const handleSubmitDocumentos = async (event) => {
      event.preventDefault();
      
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/estado_pago_documentos_gh.php`,
          {
            method: "POST",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json', 
            },
            body: JSON.stringify(filas),
          }
        );

        const dataResponse = await response.json();
        if (dataResponse.message === "Documentos GH ingresados con éxito.") {
          alert("Documentos agregados al estado de pago con éxito.");
          
          obtenerEstadosPagoGH();

          closeSecondModal();
          closeModal();

          navigate("/dashboard", { state: dataLogin });
        } else {
          throw new Error();
        }
      } catch (error) {
        obtenerEstadosPagoBH();
      }
    }

    const validaFormularioDocumentos = (datos_documentos) => {
      return datos_documentos.every(item => {
        return item.id && item.folio && item.id_estado_pago_gh &&
              item.id_tipo_documento && item.detalle &&
              item.fecha && item.id_usuario;
      });
    };

    return (
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Detalle Estado Pago: Grúa Hidráulica (GH-{data.id_estado_pago_gh})</h4>
            <button onClick={closeModal} className="btn-close"></button>
          </div>
          <div className="modal-body">
            <div className="row row-cols-md-2 mb-4">
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Folio</span>
                    <span className="label">{data.folio || ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Fecha Estado Pago</span>
                    <span className="label">{data.fecha_estado_pago || ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Equipo</span>
                    <span className="label">{data.nombre_equipo || ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Lugar de Faena</span>
                    <span className="label">{data.nombre_obra || ""}</span>
                  </li>
                </ul>
              </div>
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Rut</span>
                    <span className="label">{`${formatearRut(data.rut_cliente)}` || ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Cliente</span>
                    <span className="label">{data.nombre_cliente || ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Cliente Final</span>
                    <span className="label">{data.nombre_cliente_final || ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Email Solicitante</span>
                    <span className="label">{data.email_solicitante || ""}</span>
                  </li>
                </ul>
              </div>
            </div>
            <h5 className="mb-3">Detalle Reports</h5>
            <div className="table-responsive border rounded mb-4">
              <table className="table text-center table-sm mb-0">
                <thead>
                  <tr className="border-bottom">
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col" className="border-end"></th>
                    <th scope="col" className="border-end" colSpan={3}>Mañana</th>
                    <th scope="col" className="border-end" colSpan={3}>Tarde</th>
                    <th scope="col"></th>
                  </tr>
                  <tr className="table-active border-bottom">
                    <th scope="col">N° Report</th>
                    <th scope="col">Fecha</th>
                    <th scope="col">Día</th>
                    <th scope="col">Mes</th>
                    <th scope="col">Hora Inicio</th>
                    <th scope="col">Hora Término</th>
                    <th scope="col">Horas Mañana</th>
                    <th scope="col">Hora Inicio</th>
                    <th scope="col">Hora Término</th>
                    <th scope="col">Horas Tarde</th>
                    <th scope="col">Total Horas</th>
                  </tr>
                </thead>
                <tbody>
                {estadoPagoReportsGH ? (
                  estadoPagoReportsGH.map((reportGH, index) => (
                    <tr key={index}>
                      <td>{reportGH.id_report_gh}</td>
                      <td>{reportGH.fecha_report}</td>
                      <td>{dataFechaReports.dia}</td>
                      <td>{dataFechaReports.mes}</td>
                      <td>{reportGH.hora_inicio_manana.substring(0, 5)}</td>
                      <td>{reportGH.hora_termino_manana.substring(0, 5)}</td>
                      <td>{reportGH.total_horas_manana.substring(0, 5)}</td>
                      <td>{reportGH.hora_inicio_tarde.substring(0, 5)}</td>
                      <td>{reportGH.hora_termino_tarde.substring(0, 5)}</td>
                      <td>{reportGH.total_horas_tarde.substring(0, 5)}</td>
                      <td>{reportGH.total_horas.substring(0, 5)}</td>
                    </tr>
                  ))
                ) : (
                    <tr>
                      <td className="text-center ps-1" colSpan={12}>No se encontraron reports asociados</td>
                    </tr>
                )}
                </tbody>
              </table>
            </div>
            <div className="card">
              <div className="card-header">
                <ul className="nav nav-tabs card-header-tabs mx-auto">
                  <li className="nav-item">
                    <a className={`nav-link ${activeTab === 'valores' ? 'nav-link active' : 'nav-link'}`} onClick={() => handleTabClick('valores')}>Valores</a>
                  </li>
                  <li className="nav-item">
                    <a className={`nav-link ${activeTab === 'documentos' ? 'nav-link active' : 'nav-link'}`} onClick={() => handleTabClick('documentos')}>Documentos</a>
                  </li>
                </ul>
              </div>
              <div className="card-body p-0">
                <div className="tab-content">
                  <div className={`tab-pane p-3 ${activeTab === 'valores' ? 'active' : ''}`}>
                    <div className="row row-cols-md-2">
                      <div className="col">
                        <h5 className="mb-3">Detalle</h5>
                        <ul className="list-group">
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            <span className="form-label mb-0">Horas Trabajadas (<span className="fw-bold text-dark">{data.horas_trabajadas_cantidad || ""}</span> * {Number(data.horas_trabajadas_valor).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.') || ""})</span>
                            <span className="label"><small className="text-secondary">$ </small>{Number(data.horas_trabajadas_valor_total).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.') || ""}</span>
                          </li>
                          {estadoPagoServiciosGH && estadoPagoServiciosGH.length > 0 && estadoPagoServiciosGH.map((servicioGH, index) => (
                            <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                              <span className="form-label mb-0">{servicioGH.nombre_servicio} (<span className="fw-bold text-dark">{servicioGH.cantidad_minima}</span> * {Number(servicioGH.valor_unitario_neto).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.')})</span>
                              <span className="label"><small className="text-secondary">$ </small>{Number(servicioGH.valor_total).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.')}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="col">
                        <h5 className="mb-3">Totales</h5>
                        <ul className="list-group">
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            <span className="form-label mb-0">Total Neto</span>
                            <span className="label"><small className="text-secondary">$ </small>{Number(data.total_neto).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.') || ""}</span>
                          </li>
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            <span className="form-label mb-0">IVA</span>
                            <span className="label"><small className="text-secondary">$ </small>{Number(data.iva).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.') || ""}</span>
                          </li>
                          <li className="list-group-item">
                            <div className="d-flex justify-content-between align-items-center mb-2">
                              <span className="form-label mb-0">Descuentos</span>
                              <span className="label"><small className="text-secondary"> </small><span className="text-red">- $ {Number(data.otros_descuentos).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.') || ""}</span></span>
                            </div>
                            <div className="border rounded table-active bg-body-secondary py-1 px-2">
                              <small>{data.detalle_otros_descuentos || ""}</small>
                            </div>
                          </li>
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            <span className="form-label mb-0"><span className="fw-bold text-dark">Total Facturado</span></span>
                            <span className="label"><small className="text-secondary">$ </small><span className="fs-5 text-dark">{Number(data.total).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.') || ""}</span></span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className={`tab-pane ${activeTab === 'documentos' ? 'active' : ''}`}>
                    <div className="d-flex justify-content-lg-between align-items-center p-3">
                      <h5 className="mb-0">Documentos Asociados</h5>
                      <div className="d-inline-flex gap-2">
                        <button type="button" className="btn btn-outline-dark btn-sm" onClick={handleAgregarFila}>
                          <i className="bi bi-plus-circle"></i> Agregar
                        </button>
                        <button type="button" className="btn btn-dark btn-sm" onClick={(e) => handleConfirmaDocumentos(e, filas)}>
                          <i className="bi bi-floppy"></i> Guardar
                        </button>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-sm table-hover mb-0">
                        <thead>
                          <tr className="table-active border-top border-bottom">
                            <th className="ps-3">Tipo Documento</th>
                            <th>Número</th>
                            <th className="pe-3">Fecha</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                        {estadoPagoDocumentosGH.length > 0 ? (
                          estadoPagoDocumentosGH.map((documento, index) => (
                            <tr key={index}>
                              <td className="ps-3">{documento.tipo_documento}</td>
                              <td>{documento.detalle}</td>
                              <td>{documento.fecha_detalle}</td>
                              <td className="pe-3"></td>
                            </tr>
                          ))
                        ) : (
                          <tr></tr>
                        )}
                        {filas.map((fila) => (
                          <tr key={fila.id}>
                            <td className="ps-3">
                              <select className="form-select form-select-sm" value={fila.id_tipo_documento} onChange={(e) => handleInputChange(fila.id, 'id_tipo_documento', e.target.value)}>
                                <option value="">Seleccione Tipo de Documento</option>
                                {tiposDocumentos.map((tipo_documento, index) => (
                                  <option key={index} value={tipo_documento.id_tipo_documento}>{tipo_documento.tipo_documento}</option>
                                ))}
                              </select>
                            </td>
                            <td>
                              <input
                                className="form-control form-control-sm"
                                type="text"
                                value={fila.detalle}
                                onChange={(e) =>
                                  handleInputChange(fila.id, 'detalle', e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <input
                                className="form-control form-control-sm"
                                type="date"
                                value={fila.fecha}
                                onChange={(e) =>
                                  handleInputChange(fila.id, 'fecha', e.target.value)
                                }
                              />
                            </td>
                            <td className="pe-3">
                              <button className="btn btn-outline-danger btn-sm" onClick={() => handleEliminarFila(fila.id)}>
                                <i className="bi bi-trash3"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer flex-row">
            {dataLogin.id_perfil === 1 && (
              <div className="d-inline-flex flex-lg-row gap-2">
                <button className="btn btn-outline-danger" onClick={(event) => handleModificaEstado(data, '2')}><i className="bi bi-dash-circle"></i> Anular</button>
                <button className="btn btn-yellow" onClick={(event) => handleModificaEstado(data, '3')}><i className="bi bi-check-circle"></i> Facturar</button>
              </div>
            )}
            {/* {dataLogin.id_perfil === 1 && (
              <select className="form-select form-select-width" name="id_estado_pago_estado" value={estadoPagoEstado} onChange={(event) => handleModificaEstado(data, event.target.value)}>
                {estadosPagoEstado.map((estado) => (
                  <option key={estado.id_estado_pago_estado} value={estado.id_estado_pago_estado}>{estado.estado_pago_estado}</option>
                ))}
              </select>
            )} */}
          </div>
        </div>
      </div>
    );
  }

  const navigate = useNavigate();

  function AgregarEstadoPagoGTModalContent() {
    const [clientes, setClientes] = useState([]);
    useEffect(() => {
      obtenerClientes();
    }, []);

    const obtenerClientes = async () => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/cliente.php`
        );
        const data = await response.json();

        if (data && data[0].id_cliente) {
          setClientes(data);
        }
      } catch (error) {
        setClientes([]);
      }
    };

    const [clienteSelected, setClienteSelected] = useState('');
    const handleClienteSelect = async (clienteSelected) => {
      setClienteSelected(clienteSelected.value);
      await obtenerClienteObra(clienteSelected.value);

      setEstadoPagoData({
        ...dataEstadoPago,
        ["id_cliente_ep"]: clienteSelected.value,
        ["id_cliente_final_ep"]: clienteSelected.value,
        ["id_usuario"]: dataLogin.id_usuario
      });
    };

    const optionsClientes = clientes.map((cliente) => ({
      value: cliente.id_cliente,
      label: cliente.nombre_cliente,
    }));

    const handleObraSelect = async (obraSelected) => {
      await obtenerFolios(obraSelected);

      setDataConsultaReports({
        ...dataConsultaReports,
        ["id_obra_ep"]: obraSelected,
      });

      setEstadoPagoData({
        ...dataEstadoPago,
        ["id_obra_ep"]: obraSelected,
      });
    };

    const [clienteObra, setclienteObra] = useState([]);
    const obtenerClienteObra = async (cliente) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/cliente_obra.php?id_cliente=${cliente}`
        );
        const data = await response.json();
  
        if (data && data[0].id_obra) {
          setclienteObra(data);
          /* dataEstadoPago.id_cliente_ep = data[0].id_cliente;
          dataEstadoPago.id_cliente_final_ep = data[0].id_cliente_final; */
        }
      } catch (error) {
        setclienteObra([]);
      }
    };

    const [folios, setFolios] = useState([]);
    const obtenerFolios = async (obra) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/cabecera_cotizacion.php?id_obra=${obra}&id_maquinaria=3`
        );
        const data = await response.json();
  
        if (data && data[0].folio) {
          setFolios(data);
        }
      } catch (error) {
        setFolios([]);
      }
    };

    const [dataDetalleOperador, setDataDetalleOperador] = useState({
      horas_incluidas_operador: 0,
      valor_hora_adicional_uf: 0,
      valor_hora_adicional_domingos_festivos_uf: 0,
      valor_uf_fecha_facturacion: indicadorUF
    });

    const [dataDetalleGrua, setDataDetalleGrua] = useState({
      horas_incluidas_grua: 0,
      valor_hora_adicional_uf: 0,
      valor_uf_fecha_facturacion: indicadorUF
    });

    const [dataEstadoPago, setEstadoPagoData] = useState({
      folio_ep: "",
      id_obra_ep: "",
      id_cliente_ep: "",
      id_cliente_final_ep: "",
      horas_trabajadas_cantidad: "",
      horas_incluidas_operador: "",
      horas_adicionales_operador: "",
      valor_hora_adicional_uf_operador: "",
      horas_adicionales_domingos_festivos_operador: "",
      valor_hora_adicional_domingos_festivos_uf_operador: "",
      valor_total_horas_adicionales_operador: "",
      horas_incluidas_grua: "",
      horas_adicionales_grua: "",
      valor_hora_adicional_uf_grua: "",
      valor_total_horas_adicionales_grua: "",
      valor_uf_fecha_facturacion: "",
      otros_descuentos_ep: 0,
      detalle_otros_descuentos_ep: "",
      total_neto: "",
      iva: "",
      total: "",
      id_usuario: ""
    });

    /* useEffect(() => {
     console.log(JSON.stringify(dataEstadoPago,null,2));
    }, [dataEstadoPago]); */

    const handleFormEstadoPagoData = (e) => {
      const { name, value } = e.target;

      setEstadoPagoData({
        ...dataEstadoPago,
        [name]: value,
      });

      setDataConsultaReports({
        ...dataConsultaReports,
        [name]: value,
      });
    };

    const [dataConsultaReports, setDataConsultaReports] = useState({
      id_obra_ep: "",
      folio_ep: "",
      fecha_desde_ep: "",
      fecha_hasta_ep: ""
    });

    const [dataReports, setDataReports] = useState([]);
    const [dataServiciosFolio, setDataServiciosFolio] = useState([]);
    //const [horasTrabajadasValor, setHorasTrabajadasValor] = useState(0.00);

    const handleSubmitBuscarReports = async (event) => {
      event.preventDefault();

      //console.log(JSON.stringify(dataConsultaReports,null,2))
      //console.log(JSON.stringify(dataEstadoPago,null,2))

      try {
        const esFormularioValido = validaFormularioBuscarReports();

        if (esFormularioValido) {
          setDataReportsGT({});
          setCheckboxStates({});
          setSelectAll(false);

          const idObra = dataConsultaReports.id_obra_ep;
          const folio = dataConsultaReports.folio_ep;
          const fechaDesde = dataConsultaReports.fecha_desde_ep;
          const fechaHasta = dataConsultaReports.fecha_hasta_ep;

          //console.log(JSON.stringify(dataConsultaReports,null,2));
  
          const response = await fetch(
            `https://vittamaq.qa-gtalent.cl/api/estado_pago_gt.php?id_obra=${idObra}&folio=${folio}&fecha_desde=${fechaDesde}&fecha_hasta=${fechaHasta}`
          );
          const data = await response.json();
          
          if (data && data[0].id_report_gt) {
            const resultadoFormateado = data.map(item => {
              //Cálculo de Horas Trabajadas
              const inicioManana = new Date(`1970-01-01T${item.hora_inicio_manana}`);
              const terminoManana = new Date(`1970-01-01T${item.hora_termino_manana}`);
              const inicioTarde = new Date(`1970-01-01T${item.hora_inicio_tarde}`);
              const terminoTarde = new Date(`1970-01-01T${item.hora_termino_tarde}`);
        
              const horasTrabajadasManana = (terminoManana - inicioManana) / 3600000;
              const horasManana = Math.floor(horasTrabajadasManana);
              const minutosManana = Math.floor((horasTrabajadasManana - horasManana) * 60);
              const segundosManana = Math.floor(((horasTrabajadasManana - horasManana) * 60 - minutosManana) * 60);
              const horasMananaFormateadas = `${String(horasManana).padStart(2, '0')}:${String(minutosManana).padStart(2, '0')}:${String(segundosManana).padStart(2, '0')}`;

              const horasTrabajadasTarde = (terminoTarde - inicioTarde) / 3600000;
              const horasTarde = Math.floor(horasTrabajadasTarde);
              const minutosTarde = Math.floor((horasTrabajadasTarde - horasTarde) * 60);
              const segundosTarde = Math.floor(((horasTrabajadasTarde - horasTarde) * 60 - minutosTarde) * 60);
              const horasTardeFormateadas = `${String(horasTarde).padStart(2, '0')}:${String(minutosTarde).padStart(2, '0')}:${String(segundosManana).padStart(2, '0')}`;

              const minutosTotales = (horasTrabajadasManana + horasTrabajadasTarde) * 60;
              const horasFinales = Math.floor(minutosTotales / 60);
              const minutosFinales = minutosTotales % 60;
              const horasTotalesFormateadas = `${String(horasFinales).padStart(2, '0')}:${String(minutosFinales).padStart(2, '0')}:00`;

              //Obtener día y mes report
              const partesFecha = item.fecha_report.split('-');
              const date = new Date(`${partesFecha[2]}-${partesFecha[1]}-${partesFecha[0]}`);
          
              const diasSemana = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
              const nombreMeses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
          
              const diaSemana = diasSemana[date.getDay()];
              const nombreMes = nombreMeses[date.getMonth()];

              dataDetalleOperador.horas_incluidas_operador = item.horas_incluidas_operador ? item.horas_incluidas_operador : 0;
              dataDetalleOperador.valor_hora_adicional_uf = item.valor_hr_adicional_operador ? Number(item.valor_hr_adicional_operador).toFixed(4) : 0;
              dataDetalleOperador.valor_hora_adicional_domingos_festivos_uf = item.valor_hr_adicional_operador_sdf ? Number(item.valor_hr_adicional_operador_sdf).toFixed(4) : 0;
              dataDetalleGrua.horas_incluidas_grua = item.horas_incluidas_grua ? item.horas_incluidas_grua : 0;
              dataDetalleGrua.valor_hora_adicional_uf = item.valor_hr_adicional_grua ? Number(item.valor_hr_adicional_grua).toFixed(4) : 0;

              return {
                id_report_gt: item.id_report_gt,
                fecha_report: item.fecha_report,
                dia: diaSemana,
                mes: nombreMes,
                hora_inicio_manana: item.hora_inicio_manana,
                hora_termino_manana: item.hora_termino_manana,
                hora_inicio_tarde: item.hora_inicio_tarde,
                hora_termino_tarde: item.hora_termino_tarde,
                minimo_horas: item.minimo_horas,
                hora_inicio_colacion: item.hora_inicio_colacion,
                hora_termino_colacion: item.hora_termino_colacion,
                horas_manana: horasMananaFormateadas,
                horas_tarde: horasTardeFormateadas,
                total_horas_trabajadas: horasTotalesFormateadas
              };
            });

            const resultadoFinal = resultadoFormateado.map(item => ({
              id_report_gt: item.id_report_gt,
              fecha_report: item.fecha_report,
              dia: item.dia,
              mes: item.mes,
              hora_inicio_manana: item.hora_inicio_manana,
              hora_termino_manana: item.hora_termino_manana,
              hora_inicio_tarde: item.hora_inicio_tarde,
              hora_termino_tarde: item.hora_termino_tarde,
              minimo_horas: item.minimo_horas,
              hora_inicio_colacion: item.hora_inicio_colacion,
              hora_termino_colacion: item.hora_termino_colacion,
              horas_manana: item.horas_manana,
              horas_tarde: item.horas_tarde,
              total_horas_trabajadas: item.total_horas_trabajadas
            }));

            setDataReports(resultadoFinal);

            // Obtiene otros servicios asociados al folio
            const responseServicios = await fetch(
              `https://vittamaq.qa-gtalent.cl/api/estado_pago_gt.php?folio=${folio}`
            );

            const dataServicios = await responseServicios.json();

            if (dataServicios && dataServicios[0].folio) {
              //console.log(JSON.stringify(dataServicios,null,2))
              const newData = dataServicios.map(item => {
                const valorUnitario = parseFloat(item.valor_unitario_neto);
            
                //let ufHoy = (indicadorUF / 1000);
                //const valorTotal = Number(valorUnitario * ufHoy);

                //Cálculo de valores con fecha del último día de arriendo
                const [year, month, day] = fechaHasta.split('-');
                const fechaUltimoDiaEP = `${day}-${month}-${year}`;

                const { serie } = UFmensual;
          
                const fechaActualIndicadorUF = serie.find(item => {
                  const itemDate = new Date(item.fecha).toLocaleDateString('es-CL');
                  return itemDate === fechaUltimoDiaEP;
                });

                const UFultimoDiaMes = Number(fechaActualIndicadorUF.valor / 1000);
                const valorTotal = Number(valorUnitario * UFultimoDiaMes);
            
                return {
                  ...item,
                  valor_total: valorTotal.toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '')
                };
              });
              setDataServiciosFolio(newData);
            } else {
              setDataServiciosFolio([]);
            }
          } else {
            setDataReports([]);
          }
        }
      } catch (error) {
        alert("No se encontró información para los filtros indicados.");
      }
    }

    const [dataTotalesOperador, setDataTotalesOperador] = useState({
      horas_trabajadas_cantidad: 0,
      horas_incluidas_operador: 0,
      horas_adicionales: 0,
      valor_hora_adicional_uf: 0,
      horas_adicionales_domingos_festivos: 0,
      valor_hora_adicional_domingos_festivos_uf: 0,
      valor_uf_fecha_facturacion: 0,
      valor_total_horas_adicionales: 0
    });

    const [dataTotalesGrua, setDataTotalesGrua] = useState({
      horas_trabajadas_cantidad: 0,
      horas_incluidas_grua: 0,
      horas_adicionales: 0,
      valor_hora_adicional_uf: 0,
      valor_uf_fecha_facturacion: 0,
      valor_total_horas_adicionales: 0
    });

    const [dataReportsGT, setDataReportsGT] = useState({});
    const [checkboxStates, setCheckboxStates] = useState({});
    const [selectAll, setSelectAll] = useState(false);

    const toggleCheckbox = (index, isChecked, id_report_gt, fecha_report, hora_inicio_manana, hora_termino_manana, total_horas_manana, hora_inicio_tarde, hora_termino_tarde, total_horas_tarde, total_horas_trabajadas) => {
      setCheckboxStates(prevState => {
        const newState = {
          ...prevState,
          [index]: !prevState[index]
        };
    
        setDataReportsGT(prevData => {
          const updatedData = { ...prevData };
          if (isChecked) {
            // Si el checkbox está marcado, actualizar los datos como de costumbre
            updatedData[id_report_gt] = {
              ...updatedData[id_report_gt],
              ["id_report_gt"]: id_report_gt,
              ["fecha_report"]: fecha_report,
              ["hora_inicio_manana"]: hora_inicio_manana,
              ["hora_termino_manana"]: hora_termino_manana,
              ["total_horas_manana"]: total_horas_manana,
              ["hora_inicio_tarde"]: hora_inicio_tarde,
              ["hora_termino_tarde"]: hora_termino_tarde,
              ["total_horas_tarde"]: total_horas_tarde,
              ["total_horas"]: total_horas_trabajadas 
            };
          } else {
            // Si el checkbox está desmarcado, eliminar el elemento correspondiente
            delete updatedData[id_report_gt];
          }
          return updatedData;
        });
    
        return newState;
      });
    };

    //Checkbox seleccionar todos los reports GT
    const handleSelectAll = () => {
      const newSelectAll = !selectAll;
      setSelectAll(newSelectAll);
  
      dataReports.forEach((item, index) => {
        const isChecked = newSelectAll;
        if (checkboxStates[index] !== isChecked) {
          toggleCheckbox(
            index,
            isChecked,
            item.id_report_gt,
            item.fecha_report,
            item.hora_inicio_manana,
            item.hora_termino_manana,
            item.horas_manana,
            item.hora_inicio_tarde,
            item.hora_termino_tarde,
            item.horas_tarde,
            item.total_horas_trabajadas
          );
        }
      });
    };

    function convertirHoraADecimal(hora) {
      const partes = hora.split(':');
      const horas = parseInt(partes[0]);
      const minutos = parseInt(partes[1]) / 60;
      const segundos = parseInt(partes[2]) / 3600;
      return horas + minutos + segundos;
    }
    
    function esDiaLaboral(fecha) {
      const diaSemana = fecha.getDay();
    
      //const festivos = ["01-01", "03-29", "03-30", "05-01", "06-09", "06-20", "06-29", "07-16", "08-15", "09-18", "09-19", "09-20", "10-12", "10-27", "10-31", "11-01", "11-24", "12-08", "12-25"]; // Días festivos en formato "MM-DD"
      const festivos = diasFestivos.map(festivo => {
        const [year, month, day] = festivo.fecha.split("-");
        return `${month}-${day}`;
      });
    
      const finDeSemana = [0, 6]; // Domingo (0) y Sábado (6)
    
      const esFestivo = festivos.includes(`${fecha.getMonth() + 1}-${fecha.getDate()}`);
    
      // Es día laboral si no es fin de semana ni festivo
      return !finDeSemana.includes(diaSemana) && !esFestivo;
    }
    
    const [dataTotalesGT, setDataTotalesGT] = useState({
      total_neto: 0,
      iva: 0,
      total: 0
    });

    useEffect(() => {
      const suma = Object.values(dataReportsGT).reduce(
        (accumulator, item) => {
          const [day, month, year] = item.fecha_report.split('-');
          const fecha = new Date(`${month}-${day}-${year}`);
        
          const esLaboral = esDiaLaboral(fecha);
      
          let totalHoras = convertirHoraADecimal(item.total_horas);
      
          if (esLaboral) {
            accumulator.total_horas_laborales += parseFloat(totalHoras);
          } else {
            accumulator.total_horas_no_laborales += parseFloat(totalHoras);
          }
          
          return accumulator;
        },
        { total_horas_laborales: 0, total_horas_no_laborales: 0 }
      );

      //let ufHoy = (indicadorUF / 1000);
      const fechaActual = new Date().toISOString().split('T')[0];
      //Cálculo de valores con fecha del último día de arriendo
      const [year, month, day] = dataConsultaReports.fecha_hasta_ep != "" ? dataConsultaReports.fecha_hasta_ep.split('-') : fechaActual.split('-');
      const fechaUltimoDiaEP = `${day}-${month}-${year}`;

      const { serie } = UFmensual;

      const fechaActualIndicadorUF = serie.find(item => {
        const itemDate = new Date(item.fecha).toLocaleDateString('es-CL');
        return itemDate === fechaUltimoDiaEP;
      });

      const UFultimoDiaMes = Number(fechaActualIndicadorUF.valor / 1000);
      let ValorTotalHorasAdicionales; 

      if (suma.total_horas_no_laborales !== 0) {
        ValorTotalHorasAdicionales = Number(((Math.max(Number((suma.total_horas_laborales - dataDetalleOperador.horas_incluidas_operador)), 0) * dataDetalleOperador.valor_hora_adicional_uf) + (Number(suma.total_horas_no_laborales) * dataDetalleOperador.valor_hora_adicional_domingos_festivos_uf) ) * UFultimoDiaMes).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.');
      } else {
        ValorTotalHorasAdicionales = Number(((Math.max(Number((suma.total_horas_laborales - dataDetalleOperador.horas_incluidas_operador)), 0) * dataDetalleOperador.valor_hora_adicional_uf) ) * UFultimoDiaMes).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.')
      }

      // Totales Operador
      const nuevoArregloOperador = Object.entries(dataReportsGT).map(([id, item]) => ({
        horas_trabajadas_cantidad: Number(suma.total_horas_laborales).toFixed(2),
        horas_incluidas_operador: dataDetalleOperador.horas_incluidas_operador,
        horas_adicionales: Math.max(Number((suma.total_horas_laborales - dataDetalleOperador.horas_incluidas_operador).toFixed(2)), 0).toFixed(2),
        valor_hora_adicional_uf: dataDetalleOperador.valor_hora_adicional_uf,
        horas_adicionales_domingos_festivos: Number(suma.total_horas_no_laborales).toFixed(2),
        valor_hora_adicional_domingos_festivos_uf: dataDetalleOperador.valor_hora_adicional_domingos_festivos_uf,
        valor_uf_fecha_facturacion: Number(dataDetalleOperador.valor_uf_fecha_facturacion).toLocaleString('es-ES', { useGrouping: true }),
        valor_total_horas_adicionales: ValorTotalHorasAdicionales
      }));

      const resultadosUnicosOperador = Array.from(
        new Set(nuevoArregloOperador.map(JSON.stringify)),
        JSON.parse
      );
    
      setDataTotalesOperador(resultadosUnicosOperador);

      // Totales Grúa
      const nuevoArregloGrua = Object.entries(dataReportsGT).map(([id, item]) => ({
        horas_trabajadas_cantidad: Number(suma.total_horas_laborales).toFixed(2),
        horas_incluidas_grua: dataDetalleGrua.horas_incluidas_grua,
        horas_adicionales: Math.max(Number((suma.total_horas_laborales - dataDetalleGrua.horas_incluidas_grua).toFixed(2)), 0).toFixed(2),
        valor_hora_adicional_uf: dataDetalleGrua.valor_hora_adicional_uf,
        valor_uf_fecha_facturacion: Number(dataDetalleGrua.valor_uf_fecha_facturacion).toLocaleString('es-ES', { useGrouping: true }),
        valor_total_horas_adicionales: Number(((Math.max(Number((suma.total_horas_laborales - dataDetalleGrua.horas_incluidas_grua)), 0) * dataDetalleGrua.valor_hora_adicional_uf) ) * UFultimoDiaMes).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.')
      }));

      const resultadosUnicosGrua = Array.from(
        new Set(nuevoArregloGrua.map(JSON.stringify)),
        JSON.parse
      );
    
      setDataTotalesGrua(resultadosUnicosGrua);

      let totalNeto;
      let iva;
      let total;

      if (resultadosUnicosOperador.length > 0 && resultadosUnicosGrua.length > 0 && dataServiciosFolio.length > 0) {
        const sumaValorTotal = Object.values(dataServiciosFolio).reduce(
          (accumulator, item) => {
            accumulator.valor_total += parseFloat(item.valor_total);
            
            return accumulator;
          },
          { valor_total: 0 }
        );

        totalNeto = Number(Number(String(resultadosUnicosOperador[0].valor_total_horas_adicionales).replace(/\./g, '')) + Number(String(resultadosUnicosGrua[0].valor_total_horas_adicionales).replace(/\./g, '')) + Number(sumaValorTotal.valor_total)).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/,/g, '.');
        iva = Number(Number(Number(String(resultadosUnicosOperador[0].valor_total_horas_adicionales).replace(/\./g, '')) + Number(String(resultadosUnicosGrua[0].valor_total_horas_adicionales).replace(/\./g, '')) + Number(sumaValorTotal.valor_total)) * 0.19).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/,/g, '.');
        total = Number(Number(Number(String(resultadosUnicosOperador[0].valor_total_horas_adicionales).replace(/\./g, '')) + Number(String(resultadosUnicosGrua[0].valor_total_horas_adicionales).replace(/\./g, '')) + Number(sumaValorTotal.valor_total)) * 1.19).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/,/g, '.');
      }

      setDataTotalesGT({
        ...dataTotalesGT,
        ["total_neto"]: totalNeto ? totalNeto : 0,
        ["iva"]: iva ? iva : 0,
        ["total"]: total ? total : 0
      });

      setEstadoPagoData({
        ...dataEstadoPago,
        ["horas_trabajadas_cantidad"]: Number(suma.total_horas_laborales + suma.total_horas_no_laborales).toFixed(2),
        ["horas_incluidas_operador"]: resultadosUnicosOperador.length > 0 ? resultadosUnicosOperador[0].horas_incluidas_operador : 0,
        ["horas_adicionales_operador"]: resultadosUnicosOperador.length > 0 ? resultadosUnicosOperador[0].horas_adicionales : 0,
        ["valor_hora_adicional_uf_operador"]: resultadosUnicosOperador.length > 0 ? resultadosUnicosOperador[0].valor_hora_adicional_uf : 0,
        ["horas_adicionales_domingos_festivos_operador"]: resultadosUnicosOperador.length > 0 ? resultadosUnicosOperador[0].horas_adicionales_domingos_festivos : 0,
        ["valor_hora_adicional_domingos_festivos_uf_operador"]: resultadosUnicosOperador.length > 0 ? resultadosUnicosOperador[0].valor_hora_adicional_domingos_festivos_uf : 0,
        ["valor_total_horas_adicionales_operador"]: resultadosUnicosOperador.length > 0 ? Number(String(resultadosUnicosOperador[0].valor_total_horas_adicionales).replace(/\./g, '')) : 0,
        ["horas_incluidas_grua"]: resultadosUnicosGrua.length > 0 ? resultadosUnicosGrua[0].horas_incluidas_grua : 0,
        ["horas_adicionales_grua"]: resultadosUnicosGrua.length > 0 ? resultadosUnicosGrua[0].horas_adicionales : 0,
        ["valor_hora_adicional_uf_grua"]: resultadosUnicosGrua.length > 0 ? resultadosUnicosGrua[0].valor_hora_adicional_uf : 0,
        ["valor_total_horas_adicionales_grua"]: resultadosUnicosGrua.length > 0 ? Number(String(resultadosUnicosGrua[0].valor_total_horas_adicionales).replace(/\./g, '')) : 0,
        ["valor_uf_fecha_facturacion"]: resultadosUnicosGrua.length > 0 ? Number(String(resultadosUnicosGrua[0].valor_uf_fecha_facturacion).replace(/\./g, '').replace(/,/g, '.')) : 0,
        ["total_neto"]: totalNeto ? totalNeto.replace(/\./g, '') : 0,
        ["iva"]: iva ? iva.replace(/\./g, '') : 0,
        ["total"]: total ? total.replace(/\./g, '') : 0
      });
    }, [dataReportsGT]);

    /* useEffect(() => {
      console.log(JSON.stringify(dataTotalesGT,null,2))
    }, [dataTotalesGT]); */

    const validaFormularioBuscarReports = () => {
      if (clienteSelected === "") {
        alert('Debe seleccionar un cliente');
        return false;
      }

      if (dataConsultaReports.folio_ep.trim() === '') {
        alert('Debe seleccionar un folio');
        return false;
      }

      if (dataConsultaReports.fecha_desde_ep.trim() === '') {
        alert('Debe indicar una fecha desde');
        return false;
      }

      if (dataConsultaReports.fecha_hasta_ep.trim() === '') {
        alert('Debe indicar una fecha hasta');
        return false;
      }

      return true;
    }

    const [dataOtrosDescuentos, setOtrosDescuentos] = useState(0);
    const [dataTotalNetoInicial, setTotalNetoInicial] = useState(0);
    const handleOtrosDescuentos = (otro_descuento, total_neto_valor) => {
      if (/^\d*$/.test(otro_descuento)) {
        const nuevoDescuento = Number(otro_descuento) || 0;
        setOtrosDescuentos(nuevoDescuento);

        if (dataTotalNetoInicial === 0) {
          const totalNeto = Number(total_neto_valor.replace(/\./g, ''));
          setTotalNetoInicial(totalNeto);

          let totalNetoMostrado = nuevoDescuento === 0 ? totalNeto : totalNeto - nuevoDescuento;
          dataTotalesGT.total_neto = totalNetoMostrado.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/,/g, '.');
          dataTotalesGT.iva = Number(Number(totalNetoMostrado * 0.19).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '')).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.');
          dataTotalesGT.total = Number(Number(totalNetoMostrado * 1.19).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '')).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.');

          setEstadoPagoData({
            ...dataEstadoPago,
            ["otros_descuentos_ep"]: nuevoDescuento,
            ["total_neto"]: totalNetoMostrado.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/[\.,]/g, ''),
            ["iva"]: Number(Number(totalNetoMostrado * 0.19).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '')).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, ''),
            ["total"]: Number(Number(totalNetoMostrado * 1.19).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '')).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '')
          });
        } else {
          let totalNetoConDescuento = dataTotalNetoInicial - nuevoDescuento;
          let totalNetoMostrado = nuevoDescuento === 0 ? dataTotalNetoInicial : totalNetoConDescuento;
          dataTotalesGT.total_neto = totalNetoMostrado.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/,/g, '.');
          dataTotalesGT.iva = Number(Number(totalNetoMostrado * 0.19).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '')).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.');
          dataTotalesGT.total = Number(Number(totalNetoMostrado * 1.19).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '')).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.');

          setEstadoPagoData({
            ...dataEstadoPago,
            ["otros_descuentos_ep"]: nuevoDescuento,
            ["total_neto"]: totalNetoMostrado.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/[\.,]/g, ''),
            ["iva"]: Number(Number(totalNetoMostrado * 0.19).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '')).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, ''),
            ["total"]: Number(Number(totalNetoMostrado * 1.19).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '')).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '')
          });
        }
      }
    };

    //Submit
    const navigate = useNavigate();
    const handleSubmitEstadoPagoGT = async (event) => {
      event.preventDefault();
      
      //console.log(JSON.stringify(dataServiciosFolio,null,2));
      try {
        const esFormularioValido = validaFormularioSubmit();

        if (Object.keys(dataReportsGT).length === 0) {
          alert('Debe agregar al menos un report');
        } else if (esFormularioValido) {
          const response = await fetch(
            `https://vittamaq.qa-gtalent.cl/api/estado_pago_gt.php`,
            {
              method: "POST",
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json', 
              },
              body: JSON.stringify(dataEstadoPago),
            }
          );
  
          const dataResponse = await response.json();

          if (dataResponse.message === "Estado de pago agregado con éxito.") {
            const estadoPagoReports = Object.keys(dataReportsGT).reduce((acc, key) => {
              if (typeof dataReportsGT[key] === 'object') {
                acc[key] = { ...dataReportsGT[key], id_estado_pago_gt: dataResponse.id_estado_pago_gt, id_usuario: dataLogin.id_usuario };
              } else {
                acc[key] = dataReportsGT[key];
              }
              return acc;
            }, {});
  
            const responseReports = await fetch(
              `https://vittamaq.qa-gtalent.cl/api/estado_pago_reports_gt.php`,
              {
                method: "POST",
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json', 
                },
                body: JSON.stringify(estadoPagoReports),
              }
            );
    
            const dataResponseReports = await responseReports.json();
            
            if (dataResponseReports.message === "Reports de estado de pago agregados con éxito.") {
              const serviciosAsignadosEstadoPagoGT = dataServiciosFolio.map(item => ({
                  ...item,
                  id_estado_pago_gt: dataResponse.id_estado_pago_gt,
                  id_usuario: dataLogin.id_usuario
              }));

              const responseServicios = await fetch(
                `https://vittamaq.qa-gtalent.cl/api/estado_pago_servicios_gt.php`,
                {
                  method: "POST",
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json', 
                  },
                  body: JSON.stringify(serviciosAsignadosEstadoPagoGT),
                }
              );
      
              const dataResponseServicios = await responseServicios.json();
              if (dataResponseServicios.message === "Servicios de estado de pago agregados con éxito.") {

                const responseReportsActualizados = await fetch(
                  `https://vittamaq.qa-gtalent.cl/api/report_gt.php`,
                  {
                    method: "PUT",
                    headers: {
                      'Accept': 'application/json', // Indica que esperas una respuesta JSON
                      'Content-Type': 'application/json', 
                    },
                    body: JSON.stringify(dataReportsGT),
                  }
                );
        
                const dataResponseReportsActualizados = await responseReportsActualizados.json();
                if (dataResponseReportsActualizados.message === "Reports de estado de pago actualizados con éxito.") {
                  setEstadoPagoData({
                    folio_ep: "",
                    id_obra_ep: "",
                    id_cliente_ep: "",
                    id_cliente_final_ep: "",
                    horas_trabajadas_cantidad: "",
                    horas_incluidas_operador: "",
                    horas_adicionales_operador: "",
                    valor_hora_adicional_uf_operador: "",
                    horas_adicionales_domingos_festivos_operador: "",
                    valor_hora_adicional_domingos_festivos_uf_operador: "",
                    valor_total_horas_adicionales_operador: "",
                    horas_incluidas_grua: "",
                    horas_adicionales_grua: "",
                    valor_hora_adicional_uf_grua: "",
                    valor_total_horas_adicionales_grua: "",
                    valor_uf_fecha_facturacion: "",
                    otros_descuentos_ep: "",
                    detalle_otros_descuentos_ep: "",
                    total_neto: "",
                    iva: "",
                    total: "",
                    id_usuario: ""
                  });
      
                  setDataReportsGT([]);
                  setDataServiciosFolio([]);
        
                  alert("Estado de pago agregado con éxito.");
        
                  navigate("/dashboard", { state: dataLogin });
  
                  closeModal();
                }
              }
            }
          } else {
            throw new Error();
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

    const validaFormularioSubmit = () => {
      if (clienteSelected === "") {
        alert('Debe seleccionar un cliente');
        return false;
      }

      if (dataEstadoPago.folio_ep.trim() === '') {
        alert('Debe seleccionar un folio');
        return false;
      }

      if (dataConsultaReports.fecha_desde_ep.trim() === '') {
        alert('Debe indicar una fecha desde');
        return false;
      }

      if (dataConsultaReports.fecha_hasta_ep.trim() === '') {
        alert('Debe indicar una fecha hasta');
        return false;
      }

      if (dataEstadoPago.otros_descuentos_ep !== 0) {
        if (dataEstadoPago.detalle_otros_descuentos_ep.trim() === '') {
          alert('Debe indicar el detalle de otros descuentos, de lo contrario su valor debe ser 0');
          return false;
        }  
      }

      return true;
    }

    return (
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Agregar Estado de Pago: Grúa Torre</h4>
            <button onClick={closeModal} className="btn-close"></button>
          </div>
          <div className="modal-body py-3 px-5">
            <div className="row row-cols-lg-4 mb-3">
              <div className="col">
                <label className="form-label">Cliente</label>
                <Select
                  name="id_cliente_ep" 
                  value={clienteSelected.value} 
                  onChange={handleClienteSelect}
                  options={optionsClientes}
                  isSearchable
                  className="react-select"
                  placeholder="Seleccione un cliente"
                />
              </div>
              <div className="col">
                <label className="form-label">Obra</label>
                <select className="form-select" name="id_obra_ep" value={dataConsultaReports.id_obra_ep} onChange={(e) => handleObraSelect(e.target.value)}>
                  <option value="">Seleccione una obra</option>
                  {clienteObra.map((obra, index) => (
                    <option key={index} value={obra.id_obra}>{obra.nombre}</option>
                  ))}
                </select>
              </div>
              <div className="col">
                <label className="form-label">Cliente Final</label>
                <select className="form-select" name="id_cliente_final_ep" value={dataEstadoPago.id_cliente_final_ep} disabled>
                  <option value="">Seleccione un cliente final</option>
                  {optionsClientes.map((cliente_final, index) => (
                    <option key={index} value={cliente_final.value}>{cliente_final.label}</option>
                  ))}
                </select>
              </div>
              {folios ? (
                <div className="col">
                  <label className="form-label">Folio</label>
                  <select className="form-select" name="folio_ep" value={dataEstadoPago.folio_ep} onChange={handleFormEstadoPagoData}>
                    <option value="">Seleccione un folio</option>
                    {folios.map((folio, index) => (
                      <option key={index} value={folio.folio}>{folio.folio}</option>
                    ))}
                  </select>
                </div>
                ) : (
                <div className="col">
                  <label className="form-label">Folio</label>
                  <select className="form-select" name="folio_ep">
                    <option value="">No se encontraron folios</option>
                  </select>
                </div>
              )}
            </div>
            <div className="row row-cols-md-4 align-items-end mb-4">
              <div className="col">
                <label className="form-label">Fecha Desde</label>
                <input 
                  className="form-control" 
                  type="date" 
                  name="fecha_desde_ep"
                  value={dataConsultaReports.fecha_desde_ep}
                  max={today.toISOString().split('T')[0]}
                  onChange={(e) => setDataConsultaReports({
                    ...dataConsultaReports,
                    fecha_desde_ep: e.target.value
                  })}
                />
              </div>
              <div className="col">
                <label className="form-label">Fecha Hasta</label>
                <input 
                  className="form-control" 
                  type="date" 
                  name="fecha_hasta_ep"
                  value={dataConsultaReports.fecha_hasta_ep}
                  max={today.toISOString().split('T')[0]}
                  onChange={(e) => setDataConsultaReports({
                    ...dataConsultaReports,
                    fecha_hasta_ep: e.target.value
                  })}
                />
              </div>
              <div className="col">
                <label className="form-label d-block">&nbsp;</label>
                <button className="btn btn-dark" onClick={handleSubmitBuscarReports}>
                  <i className="bi bi-search"></i> Buscar
                </button>
              </div>
            </div>
            <div className="d-flex justify-content-md-between mb-3">
              <h5 className="mb-0">Detalle Reports</h5>
              <span className="d-inline-flex align-items-center">
                <label className="form-label mb-0 me-2">UF Hoy</label>
                <span className="fw-bold text-orange"><small className="text-secondary">$</small> {Number(indicadorUF ? indicadorUF : '0').toLocaleString('es-ES', { useGrouping: true })}</span>
              </span>
            </div>
            <div className="table-responsive border rounded mb-4">
              <table className="table table-hover table-sm text-center mb-0">
                <thead>
                  <tr className="border-bottom">
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col" className="border-end"></th>
                    <th scope="col" className="border-start border-end" colSpan={3}>Mañana</th>
                    <th scope="col" className="border-start border-end" colSpan={3}>Tarde</th>
                    <th scope="col"></th>
                  </tr>
                  <tr className="table-active border-bottom">
                    <th scope="col">
                      {dataReports.length > 0 ? (
                        <div className="form-check">
                          <input 
                            className="form-check-input" 
                            type="checkbox"
                            checked={selectAll}
                            onChange={handleSelectAll}
                          />
                        </div>
                      ) : (
                        <span></span>
                      )}
                    </th>
                    <th scope="col">N° Report</th>
                    <th scope="col">Fecha</th>
                    <th scope="col">Día</th>
                    <th scope="col">Mes</th>
                    <th scope="col">Hora Inicio</th>
                    <th scope="col">Hora Término</th>
                    <th scope="col">Horas Mañana</th>
                    <th scope="col">Hora Inicio</th>
                    <th scope="col">Hora Término</th>
                    <th scope="col">Horas Tarde</th>
                    <th scope="col">Total Horas</th>
                  </tr>
                </thead>
                <tbody>
                  {dataReports.length > 0 ? (
                    dataReports.map((report, index) => (
                      <tr key={index}>
                        <td>
                          <div className="form-check">
                            <input 
                              className="form-check-input" 
                              type="checkbox" 
                              checked={checkboxStates[index] || false}
                              onChange={(e) => toggleCheckbox(index, e.target.checked, report.id_report_gt, report.fecha_report, report.hora_inicio_manana, report.hora_termino_manana, report.horas_manana, report.hora_inicio_tarde, report.hora_termino_tarde, report.horas_tarde, report.total_horas_trabajadas)}
                            />
                          </div>
                        </td>
                        <td>{report.id_report_gt}</td>
                        <td>{report.fecha_report}</td>
                        <td>{report.dia}</td>
                        <td>{report.mes}</td>
                        <td className="text-center border-start">{report.hora_inicio_manana.substring(0, 5)}</td>
                        <td className="text-center">{report.hora_termino_manana.substring(0, 5)}</td>
                        <td className="text-center border-end">{report.horas_manana.substring(0, 5)}</td>
                        <td className="text-center">{report.hora_inicio_tarde.substring(0, 5)}</td>
                        <td className="text-center">{report.hora_termino_tarde.substring(0, 5)}</td>
                        <td className="text-center border-end">{report.horas_tarde.substring(0, 5)}</td>
                        <td className="text-center">{report.total_horas_trabajadas.substring(0, 5)}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="text-center ps-1" colSpan={12}>No se encontraron reports</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="row row-cols-2 mb-4">
              <div className="col">
                <div className="d-flex justify-content-md-between mb-3">
                  <h5 className="mb-0">Detalle Operador</h5>
                  <span className="d-inline-flex align-items-center">
                    <label className="form-label mb-0 me-2">Horas Trabajadas:</label>
                    {dataTotalesOperador.length > 0 ? (
                      <span className="fw-bold text-orange">{dataTotalesOperador[0].horas_trabajadas_cantidad}</span>
                    ) : (
                      <span className="fw-bold text-orange">0</span>
                    )}
                  </span>
                </div>
                <div className="table-responsive border rounded">
                  <table className="table table-hover table-sm mb-0">
                    <thead>
                      <tr className="table-active border-bottom">
                        <th className="col-6 ps-2">Descripción</th>
                        <th className="col-2 pe-2">Detalle</th>
                      </tr>
                    </thead>
                    {dataTotalesOperador.length > 0 ? (
                      <tbody>
                        <tr>
                          <td className="ps-2">Horas Incluidas Operador</td>
                          <td className="pe-2">{dataTotalesOperador[0].horas_incluidas_operador}</td>
                        </tr>
                        <tr>
                          <td className="ps-2">Horas Adicionales</td>
                          <td className="pe-2">{dataTotalesOperador[0].horas_adicionales}</td>
                        </tr>
                        <tr>
                          <td className="ps-2">Valor Horas Adicional (UF)</td>
                          <td className="pe-2">{dataTotalesOperador[0].valor_hora_adicional_uf}</td>
                        </tr>
                        <tr>
                          <td className="ps-2">Horas Adicionales Domingos y Festivos</td>
                          <td className="pe-2">{dataTotalesOperador[0].horas_adicionales_domingos_festivos}</td>
                        </tr>
                        <tr>
                          <td className="ps-2">Valor Horas Adicional (UF)</td>
                          <td className="pe-2">{dataTotalesOperador[0].valor_hora_adicional_domingos_festivos_uf}</td>
                        </tr>
                        <tr>
                          <td className="ps-2">Valor UF a Fecha de Facturación</td>
                          <td className="pe-2"><small className="text-secondary">$</small> {dataTotalesOperador[0].valor_uf_fecha_facturacion}</td>
                        </tr>
                        <tr>
                          <td className="ps-2">Valor Total Horas Adicionales</td>
                          <td className="pe-2"><small className="text-secondary">$</small> <span className="text-orange">{dataTotalesOperador[0].valor_total_horas_adicionales}</span></td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td className="ps-2">Horas Incluidas Operador</td>
                          <td className="pe-2">0</td>
                        </tr>
                        <tr>
                          <td className="ps-2">Horas Adicionales</td>
                          <td className="pe-2">0</td>
                        </tr>
                        <tr>
                          <td className="ps-2">Valor Horas Adicional (UF)</td>
                          <td className="pe-2">0</td>
                        </tr>
                        <tr>
                          <td className="ps-2">Horas Adicionales Domingos y Festivos</td>
                          <td className="pe-2">0</td>
                        </tr>
                        <tr>
                          <td className="ps-2">Valor Horas Adicional (UF)</td>
                          <td className="pe-2">0</td>
                        </tr>
                        <tr>
                          <td className="ps-2">Valor UF a Fecha de Facturación</td>
                          <td className="pe-2"><small className="text-secondary">$</small> 0</td>
                        </tr>
                        <tr>
                          <td className="ps-2">Valor Total Horas Adicionales</td>
                          <td className="pe-2"><small className="text-secondary">$</small> <span className="text-orange">0</span></td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
              <div className="col">
                <h5 className="mb-3">Detalle Grúa</h5>
                <div className="table-responsive border rounded">
                  <table className="table table-hover table-sm mb-0">
                    <thead>
                      <tr className="table-active border-bottom">
                        <th className="col-6 ps-2">Descripción</th>
                        <th className="col-2 pe-2">Detalle</th>
                      </tr>
                    </thead>
                    {dataTotalesGrua.length > 0 ? (
                      <tbody>
                        <tr>
                          <td className="ps-2">Horas Incluidas Grúa</td>
                          <td className="pe-2">{dataTotalesGrua[0].horas_incluidas_grua}</td>
                        </tr>
                        <tr>
                          <td className="ps-2">Horas Adicionales</td>
                          <td className="pe-2">{dataTotalesGrua[0].horas_adicionales}</td>
                        </tr>
                        <tr>
                          <td className="ps-2">Valor Horas Adicional (UF)</td>
                          <td className="pe-2">{dataTotalesGrua[0].valor_hora_adicional_uf}</td>
                        </tr>
                        <tr>
                          <td className="ps-2">Valor UF a Fecha de Facturación</td>
                          <td className="pe-2"><small className="text-secondary">$</small> {dataTotalesGrua[0].valor_uf_fecha_facturacion}</td>
                        </tr>
                        <tr>
                          <td className="ps-2">Valor Total Horas Adicionales</td>
                          <td className="pe-2"><small className="text-secondary">$</small> <span className="text-orange">{dataTotalesGrua[0].valor_total_horas_adicionales}</span></td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td className="ps-2">Horas Incluidas Grúa</td>
                          <td className="pe-2">0</td>
                        </tr>
                        <tr>
                          <td className="ps-2">Horas Adicionales</td>
                          <td className="pe-2">0</td>
                        </tr>
                        <tr>
                          <td className="ps-2">Valor Horas Adicional (UF)</td>
                          <td className="pe-2">0</td>
                        </tr>
                        <tr>
                          <td className="ps-2">Valor UF a Fecha de Facturación</td>
                          <td className="pe-2"><small className="text-secondary">$</small> 0</td>
                        </tr>
                        <tr>
                          <td className="ps-2">Valor Total Horas Adicionales</td>
                          <td className="pe-2"><small className="text-secondary">$</small> <span className="text-orange">0</span></td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
            <h5 className="mb-3">Detalle</h5>
            <div className="table-responsive border rounded mb-4">
              <table className="table table-hover table-sm mb-0">
                <thead>
                  <tr className="table-active border-bottom">
                    <th className="ps-2" scope="col">Item</th>
                    <th scope="col">Valor UF</th>
                    <th className="pe-2" scope="col">Valor $</th>
                  </tr>
                </thead>
                <tbody>
                  {dataServiciosFolio.length > 0 ? (
                    dataServiciosFolio.map((totales, index) => (
                      <tr key={index}>
                        <td className="ps-2">
                          {
                            totales.glosa_servicio !== null && totales.glosa_servicio !== '' ? (
                              totales.glosa_servicio
                            ) : (
                              totales.id_maquinaria !== 1 && totales.id_maquinaria !== 2 && totales.id_tipo_maquinaria === 0 ? (
                                totales.nombre_maquinaria + " - " + totales.tipo_arriendo
                              ) : (
                                totales.nombre_maquinaria + (totales.tipo_maquinaria === undefined ? " - " + totales.tipo_arriendo : " - " + totales.tipo_maquinaria + " - " + totales.tipo_arriendo)
                              )
                            )
                          }
                        </td>
                        <td>{totales.valor_unitario_neto}</td>
                        <td className="pe-2"><small className="text-secondary">$</small> <span className="text-orange">{Number(totales.valor_total).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.')}</span></td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={4} className="text-center">No se han encontrado otros servicios asociados</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="row row-cols-md-2">
              <div className="col">
                <h5 className="mb-3">Descuentos</h5>
                <div className="row row-cols-md-2">
                  <div className="col">
                    <label className="form-label">Otros Descuentos</label>
                    {dataTotalesGT.total_neto ? (
                      <input 
                        className="form-control" 
                        type="text" 
                        name="otros_descuentos_ep" 
                        value={dataOtrosDescuentos}
                        onChange={(e) => handleOtrosDescuentos(e.target.value, dataTotalesGT.total_neto)}
                      />
                    ) : (
                      <input 
                        className="form-control" 
                        type="text" 
                        name="otros_descuentos_ep" 
                        value={0}
                        disabled
                      />
                    )}
                  </div>
                  <div className="col">
                    <label className="form-label">Detalle Descuentos</label>
                    <input 
                      className="form-control" 
                      type="text" 
                      name="detalle_otros_descuentos_ep"
                      onChange={(e) => setEstadoPagoData({
                        ...dataEstadoPago,
                        ["detalle_otros_descuentos_ep"]: e.target.value
                      })}
                    />
                  </div>
                </div>
              </div>
              <div className="col">
                <h5 className="mb-3">Valores Totales</h5>
                <div className="table-responsive border rounded">
                  <table className="table table-sm mb-0">
                    <thead>
                      <tr className="table-active border-bottom">
                        <th scope="col">Total Neto</th>
                        <th scope="col">IVA</th>
                        <th scope="col" className="text-dark fw-semibold">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                    {dataTotalesGT.total_neto ? (
                      <tr>
                        <td>$ {dataTotalesGT.total_neto}</td>
                        <td>$ {dataTotalesGT.iva}</td>
                        <td>$ <span className="text-orange">{dataTotalesGT.total}</span></td>
                      </tr>
                    ) : (
                      <tr>
                        <td>$ 0</td>
                        <td>$ 0</td>
                        <td>$ <span className="text-orange">0</span></td>
                      </tr>
                    )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-yellow" onClick={handleSubmitEstadoPagoGT}><i className="bi bi-floppy"></i> Guardar</button>
          </div>
        </div>
      </div>
    );
  }

  function EstadoPagoGTModalContent({ data }) {
    const [tiposDocumentos, setTiposDocumentos] = useState([]);
    const [estadoPagoEstado, setEstadoPagoEstado] = useState(data.id_estado_pago_estado);
    const [estadoPagoReportsGT, setEstadoPagoReportsGT] = useState([]);
    const [estadoPagoServiciosGT, setEstadoPagoServiciosGT] = useState([]);
    const [estadoPagoDocumentosGT, setEstadoPagoDocumentosGT] = useState([]);
    const [detalleCotizacion, setDetalleCotizacion] = useState([]);

    useEffect(() => {
      obtenerTiposDocumentos();
      obtenerReportsGT(data.id_estado_pago_gt);
      obtenerServiciosGT(data.id_estado_pago_gt);
      obtenerDocumentosGT(data.id_estado_pago_gt);
      obtenerDetalleCotizacion(data.folio);
    }, []);

    const obtenerTiposDocumentos = async () => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/tipo_documento.php`
        );
        const data = await response.json();
        
        if (data && data[0].id_tipo_documento) {
          setTiposDocumentos(data);
        }
      } catch (error) {
        setTiposDocumentos([]);
      }
    };

    const obtenerDocumentosGT = async (id_estado_pago_gt) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/estado_pago_documentos_gt.php?id_estado_pago_gt=${id_estado_pago_gt}`
        );
        const data = await response.json();
        
        if (data && data[0].id_estado_pago_documentos_gt) {
          setEstadoPagoDocumentosGT(data);
        }
      } catch (error) {
        setEstadoPagoDocumentosGT([]);
      }
    };

    const obtenerDetalleCotizacion = async (folio) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/detalle_cotizacion.php?folio=${folio}`
        );
        const data = await response.json();
  
        if (data && data[0].id_servicio) {
          setDetalleCotizacion(data);
        }
      } catch (error) {
        setDetalleCotizacion([]);
      }
    };

    const [dataFechaReports, setDataFechaReports] = useState({});
    const obtenerReportsGT = async (id_estado_pago_gt) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/estado_pago_reports_gt.php?id_estado_pago_gt=${id_estado_pago_gt}`
        );
        const data = await response.json();
  
        if (data && data[0].id_estado_pago_gt) {
          setEstadoPagoReportsGT(data);
          
          //Obtener día y mes report
          const partesFecha = data[0].fecha_report.split('-');
          const date = new Date(`${partesFecha[2]}-${partesFecha[1]}-${partesFecha[0]}`);
      
          const diasSemana = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
          const nombreMeses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

          setDataFechaReports({
            ...dataFechaReports,
            ["fecha_report"]: data[0].fecha_report,
            ["dia"]: diasSemana[date.getDay()],
            ["mes"]: nombreMeses[date.getMonth()]
          });
        }
      } catch (error) {
        setEstadoPagoReportsGT([]);
      }
    };

    const obtenerServiciosGT = async (id_estado_pago_gt) => {
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/estado_pago_servicios_gt.php?id_estado_pago_gt=${id_estado_pago_gt}`
        );
        const data = await response.json();
  
        if (data && data[0].id_estado_pago_gt) {
          setEstadoPagoServiciosGT(data);
        }
      } catch (error) {
        setEstadoPagoServiciosGT([]);
      }
    };

    const handleModificaEstado = (data_estado_pago, id_estado_pago_estado) => {
      if (dataTotales.total === 0) {
        alert("No puede facturar un estado de pago con valores en 0");
      } else {
        setEstadoPagoEstado(id_estado_pago_estado);

        setSecondModalOpen(true);
        
        setSecondModalContent(<ConfirmaModificaEstadoModalContent dataEstadoPago={data_estado_pago} idEstadoPagoEstado={id_estado_pago_estado} />); 
      }
    };

    function ConfirmaModificaEstadoModalContent ({ dataEstadoPago, idEstadoPagoEstado }) {
      const obtenerEstadoPorId = (idEstadoPagoEstado) => {
        const estadoEncontrado = estadosPagoEstado.find(estado => estado.id_estado_pago_estado === idEstadoPagoEstado);
        return estadoEncontrado ? estadoEncontrado.estado_pago_estado : 'Estado no encontrado';
      };

      const estado = obtenerEstadoPorId(Number(idEstadoPagoEstado));

      let jsonPIGT = null;
      if (idEstadoPagoEstado === '3') {
        //JSON para PIG-T
        const objetoPIGT = (dataCotizacion, detalleCotizacion, dataDocumentos) => {
          const nuevoObjeto = {
            "cabecera_cotizacion": {
              "nombre_cliente": dataCotizacion.nombre_cliente,
              "rut_cliente": dataCotizacion.rut_cliente,
              "razon_social": dataCotizacion.razon_social,
              "giro": dataCotizacion.giro,
              "direccion_cliente": dataCotizacion.direccion,
              "region": dataCotizacion.region,
              "comuna": dataCotizacion.comuna,
              "telefono_representante": dataCotizacion.telefono_representante,
              "fecha_cotizacion": dataCotizacion.fecha_cotizacion,
              "folio": dataCotizacion.folio,
              "obra": dataCotizacion.nombre_obra,
              "nombre_solicitante": dataCotizacion.nombre_solicitante,
              "rut_solicitante": dataCotizacion.rut_solicitante,
              "email_solicitante": dataCotizacion.email_solicitante,
              "fecha_inicio": dataCotizacion.fecha_inicio,
              "fecha_fin": dataCotizacion.fecha_fin,
              "bodega": dataCotizacion.bodega,
              "usuario_registra": dataCotizacion.usuario_registra
            },
            "servicios_cotizacion": detalleCotizacion,
            "documentos_cotizacion": dataDocumentos
          };
        
          return nuevoObjeto;
        };

        const glosasServiciosConcatenados = serviciosFacturar.map(servicio => servicio.glosa_servicio).join('\n');

        const cotizacion = detalleCotizacion[0];
        const detalleCotizacionPigT = {
          descripcion_servicio: glosasServiciosConcatenados,
          tipo_maquinaria: cotizacion.tipo_maquinaria,
          tipo_arriendo: cotizacion.tipo_arriendo,
          sku: "GT0",
          glosa_servicio: "Cotización Referencia: " + data.folio,
          unidad_medida: cotizacion.unidad_medida,
          unidad_medida_alias: cotizacion.unidad_medida_alias,
          cantidad: "1",
          valor_horas_adicionales_grua: Number(data.valor_total_horas_adicionales_grua).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, ''),
          valor_horas_adicionales_operador: Number(data.valor_total_horas_adicionales_operador).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, ''),
          total_neto: Number(data.total_neto).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, ''),
          iva: Number(data.iva).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, ''),
          descuentos: Number(data.otros_descuentos).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, ''),
          total: Number(data.total).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '')
        };

        const detalleDocumentos = estadoPagoDocumentosGT.map(doc => {
          if (doc.tipo_documento === "HES") {
            return { ...doc, tipo_documento: "HES (Hoja Entrada De Servicio)" };
          } else if (doc.tipo_documento === "Orden de Trabajo") {
            return { ...doc, tipo_documento: "OT (Orden de Trabajo)" };
          } else {
            return doc;
          }
        });
  
        jsonPIGT = objetoPIGT(data, detalleCotizacionPigT, detalleDocumentos);
        //console.log(JSON.stringify(jsonPIGT,null,2));
      }

      return (
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="mb-0">Modificar Estado: {dataEstadoPago.folio}</h4>
              <button onClick={closeSecondModal} className="btn-close"></button>
            </div>
            <div className="modal-body">
              <p className="fs-5">¿Está seguro que desea modificar el estado del estado de pago a: {idEstadoPagoEstado === '2' ? <span className="text-red">{estado}</span> : <span className="text-orange">{estado}</span>} ?</p>
            </div>
            <div className="modal-footer">
              <button className={idEstadoPagoEstado === '2' ? "btn btn-danger" : "btn btn-yellow"} onClick={(e) => handleSubmitModificarEstado(e, dataEstadoPago.id_estado_pago_gt, idEstadoPagoEstado, jsonPIGT)}>
              {idEstadoPagoEstado === '2' ? (
                <span>
                  <i className="bi bi-dash-circle"></i> Anular Estado de Pago
                </span>
              ) : (
                <span>
                  <i className="bi bi-check-circle"></i> Facturar Estado de Pago
                </span>
              )}
              </button>
            </div>
          </div>
        </div>
      );
    }

    const [dataTotales, setDataTotales] = useState({
      otros_descuentos: Number(data.otros_descuentos).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, ''),
      total_neto: Number(data.total_neto).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, ''),
      iva: Number(data.iva).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, ''),
      total: Number(data.total).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '')
    });

    const [checkboxStates, setCheckboxStates] = useState({});
    const [serviciosFacturar, setServiciosFacturar] = useState([]);
    // Inicializar checkboxStates con todos los valores en true
    useEffect(() => {
      const initialCheckboxStates = {};
      estadoPagoServiciosGT.forEach((_, index) => {
        initialCheckboxStates[index] = true;
      });
      setCheckboxStates(initialCheckboxStates);

      const idServiciosFacturar = estadoPagoServiciosGT.map(item => (
        { 
          id_servicio: item.id_servicio,
          glosa_servicio:
            item.glosa_servicio !== null && item.glosa_servicio !== '' ? (
              item.glosa_servicio
            ) : (
              item.id_equipo === 0 ? (
                item.nombre_maquinaria + " - " + item.tipo_arriendo
              ) : (
                item.nombre_maquinaria + " - " + item.nombre_equipo + " - " + item.tipo_arriendo
              )
            )
        }
      ));

      setServiciosFacturar(idServiciosFacturar);
    }, [estadoPagoServiciosGT]);

    const toggleCheckbox = (index, idServicio, glosaServicio, valorServicio) => {
      setCheckboxStates(prevState => {
        const isChecked = !prevState[index];
    
        const newState = {
          ...prevState,
          [index]: isChecked
        };
    
        // Actualizar el total_neto en función de si el checkbox está marcado o desmarcado
        setDataTotales(prevDataTotales => {
          const newTotalNeto = isChecked 
            ? prevDataTotales.total_neto + Number(valorServicio)
            : prevDataTotales.total_neto - Number(valorServicio);  
          
          //Cálculo de totales
          const newOtrosDescuentos = Number(data.otros_descuentos).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '');
          const newIva = Number(newTotalNeto * 0.19).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '').toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '');
          const newTotal = Number(Number(newTotalNeto * 1.19) - newOtrosDescuentos).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\./g, '').toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, '');

          if (newTotalNeto <= 0) {
            return {
              ...prevDataTotales,
              otros_descuentos: 0,
              total_neto: 0,
              iva: 0,
              total: 0
            };
          } else {
            return {
              ...prevDataTotales,
              otros_descuentos: newOtrosDescuentos,
              total_neto: newTotalNeto,
              iva: newIva,
              total: newTotal
            };
          }
        });

        //Servicios a facturar
        setServiciosFacturar(prevServicios => {
          if (isChecked) {
            if (!prevServicios.some(servicio => servicio.id_servicio === idServicio)) {
              return [...prevServicios, { id_servicio: idServicio, glosa_servicio: glosaServicio }];
            } else {
              return prevServicios;
            }
          } else {
            return prevServicios.filter(servicio => servicio.id_servicio !== idServicio);
          }
        });

        return newState;
      });
    };

    const handleSubmitModificarEstado = async (event, idEstadoPagoGT, idEstadoPagoEstado, jsonPIGT) => {
      event.preventDefault();
  
      try {
        const formModificarEstado = { 
          id_estado_pago_gt: idEstadoPagoGT, 
          total_neto: dataTotales.total_neto,
          iva: dataTotales.iva,
          total: dataTotales.total, 
          id_estado_pago_estado: idEstadoPagoEstado          
        };

        const responseModificarEstado = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/estado_pago_gt.php`,
          {
            method: "PUT",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json', 
            },
            body: JSON.stringify(formModificarEstado),
          }
        );

        const dataModificarEstado = await responseModificarEstado.json();
        if (dataModificarEstado.message === "Estado de pago modificado con éxito.") {

          if (idEstadoPagoEstado === "2") {
            const habilitaReportsData = {
              habilita_report: 1,
              reports: estadoPagoReportsGT.map(item => ({
                id_report_gt: item.id_report_gt
              }))
            };

            const responseReportsActualizados = await fetch(
              `https://vittamaq.qa-gtalent.cl/api/report_gt.php`,
              {
                method: "PUT",
                headers: {
                  'Accept': 'application/json', // Indica que esperas una respuesta JSON
                  'Content-Type': 'application/json', 
                },
                body: JSON.stringify(habilitaReportsData),
              }
            );
    
            const dataResponseReportsActualizados = await responseReportsActualizados.json();
            if (dataResponseReportsActualizados.message === "Report GT habilitado exitosamente.") {
              alert("Estado de pago anulado con éxito.");
          
              obtenerEstadosPagoGT();
    
              closeSecondModal();
              closeModal();
    
              navigate("/dashboard", { state: dataLogin });
            }
          } else {
            alert("Estado de pago facturado con éxito.");

            obtenerEstadosPagoGT();

            closeSecondModal();
            closeModal();

            if (jsonPIGT != null) {
              const responseKame = await fetch(
                `https://app.pig-t.cl/vittamaq_Cotizaciones_Kame`,
                {
                  method: "POST",
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json', 
                  },
                  body: JSON.stringify(jsonPIGT),
                }
              );
        
              const dataResponseKame = await responseKame.json();
              //console.log(JSON.stringify(dataResponseKame,null,2));
            }

            navigate("/dashboard", { state: dataLogin });
          }
        } else {
          throw new Error();
        }
        
      } catch (error) {
        obtenerEstadosPagoGT();
      }
    }

    //DOCUMENTOS
    const [activeTab, setActiveTab] = useState('valores');
    const handleTabClick = (tab) => {
      setActiveTab(tab);
    };

    const filaPredeterminada = { 
      id: 1, 
      folio: data.folio,
      id_estado_pago_gt: data.id_estado_pago_gt,
      id_tipo_documento: '',
      detalle: '', 
      fecha: '', 
      id_usuario: dataLogin.id_usuario.toString()
    };
    const [filas, setFilas] = useState([filaPredeterminada]);

    const handleAgregarFila = () => {
      const nuevaFila = {
        id: filas.length + 1,
        folio: data.folio,
        id_estado_pago_gt: data.id_estado_pago_gt,
        id_tipo_documento: '',
        detalle: '', 
        fecha: '', 
        id_usuario: dataLogin.id_usuario.toString()
      };

      setFilas([...filas, nuevaFila]);
    };

    const handleInputChange = (id, campo, valor) => {
      const nuevasFilas = filas.map((fila) =>
        fila.id === id ? { ...fila, [campo]: valor } : fila
      );

      setFilas(nuevasFilas);
    };

    const handleEliminarFila = (id) => {
      if (filas.length > 1) {
        const nuevasFilas = filas.filter((fila) => fila.id !== id);
        setFilas(nuevasFilas);
      } else {
        alert("Debe agregar al menos un documento.");
      }
    };

    const handleConfirmaDocumentos = (e, filas) => {
      const esFormularioValido = validaFormularioDocumentos(filas);

      if (!esFormularioValido) {
        alert("Debe completar la información de los documentos.")
      } else {
        setSecondModalOpen(true);
        setSecondModalContent(<ConfirmaDocumentosModalContent documentos={filas} />);
      }
    };

    function ConfirmaDocumentosModalContent ({ documentos }) {
      const tipoDocumentoMap = tiposDocumentos.reduce((acc, tipo) => {
        acc[tipo.id_tipo_documento] = tipo.tipo_documento;
        return acc;
      }, {});

      const documentosConTipo = documentos.map(doc => {
        return {
          ...doc,
          tipo_documento: tipoDocumentoMap[doc.id_tipo_documento]
        };
      });
      
      return (
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="mb-0">Confirmar Documentos</h4>
              <button onClick={closeSecondModal} className="btn-close"></button>
            </div>
            <div className="modal-body">
              <p className="fs-5">¿Está seguro que desea ingresar los siguientes documentos?:</p>
              <table className="table table-hover table-sm mb-0">
                <thead>
                  <tr className="table-active">
                    <th className="ps-2">Tipo Documento</th>
                    <th>Número</th>
                    <th className="pe-2">Fecha</th>
                  </tr>
                </thead>
                <tbody>
                  {documentosConTipo.length > 0 ? (
                    documentosConTipo.map((documento, index) => (
                      <tr key={index}>
                        <td className="ps-2">{documento.tipo_documento}</td>
                        <td>{documento.detalle}</td>
                        <td className="pe-2">{documento.fecha.split('-').reverse().join('-')}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="text-center ps-1" colSpan={3}>No se encontraron documentos asociados</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button className="btn btn-yellow" onClick={handleSubmitDocumentos}><i className="bi bi-floppy"></i> Guardar</button>
            </div>
          </div>
        </div>
      );
    }

    const handleSubmitDocumentos = async (event) => {
      event.preventDefault();
      
      try {
        const response = await fetch(
          `https://vittamaq.qa-gtalent.cl/api/estado_pago_documentos_gt.php`,
          {
            method: "POST",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json', 
            },
            body: JSON.stringify(filas),
          }
        );

        const dataResponse = await response.json();
        if (dataResponse.message === "Documentos GT ingresados con éxito.") {
          alert("Documentos agregados al estado de pago con éxito.");
          
          obtenerEstadosPagoGT();

          closeSecondModal();
          closeModal();

          navigate("/dashboard", { state: dataLogin });
        } else {
          throw new Error();
        }
      } catch (error) {
        obtenerEstadosPagoGT();
      }
    }

    const validaFormularioDocumentos = (datos_documentos) => {
      return datos_documentos.every(item => {
        return item.id && item.folio && item.id_estado_pago_gt &&
              item.id_tipo_documento && item.detalle &&
              item.fecha && item.id_usuario;
      });
    };

    return (
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="mb-0">Detalle Estado Pago: Grúa Torre (GT-{data.id_estado_pago_gt})</h4>
            <button onClick={closeModal} className="btn-close"></button>
          </div>
          <div className="modal-body">
            <div className="row row-cols-md-2 mb-4">
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Folio</span>
                    <span className="label">{data.folio || ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Fecha Estado Pago</span>
                    <span className="label">{data.fecha_estado_pago || ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Equipo</span>
                    <span className="label">{data.nombre_equipo || ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Lugar de Faena</span>
                    <span className="label">{data.nombre_obra || ""}</span>
                  </li>
                </ul>
              </div>
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Rut</span>
                    <span className="label">{`${formatearRut(data.rut_cliente)}` || ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Cliente</span>
                    <span className="label">{data.nombre_cliente || ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Cliente Final</span>
                    <span className="label">{data.nombre_cliente_final || ""}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="form-label mb-0">Email Solicitante</span>
                    <span className="label">{data.email_solicitante || ""}</span>
                  </li>
                </ul>
              </div>
            </div>
            <h5 className="mb-3">Detalle Reports</h5>
            <div className="table-responsive border rounded mb-4">
              <table className="table text-center table-sm mb-0">
                <thead>
                  <tr className="border-bottom">
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col" className="border-end"></th>
                    <th scope="col" className="border-end" colSpan={3}>Mañana</th>
                    <th scope="col" className="border-end" colSpan={3}>Tarde</th>
                    <th scope="col"></th>
                  </tr>
                  <tr className="table-active border-bottom">
                    <th scope="col">N° Report</th>
                    <th scope="col">Fecha</th>
                    <th scope="col">Día</th>
                    <th scope="col">Mes</th>
                    <th scope="col">Hora Inicio</th>
                    <th scope="col">Hora Término</th>
                    <th scope="col">Horas Mañana</th>
                    <th scope="col">Hora Inicio</th>
                    <th scope="col">Hora Término</th>
                    <th scope="col">Horas Tarde</th>
                    <th scope="col">Total Horas</th>
                  </tr>
                </thead>
                <tbody>
                {estadoPagoReportsGT ? (
                  estadoPagoReportsGT.map((reportGT, index) => (
                    <tr key={index}>
                      <td>{reportGT.id_report_gt}</td>
                      <td>{reportGT.fecha_report}</td>
                      <td>{dataFechaReports.dia}</td>
                      <td>{dataFechaReports.mes}</td>
                      <td>{reportGT.hora_inicio_manana.substring(0, 5)}</td>
                      <td>{reportGT.hora_termino_manana.substring(0, 5)}</td>
                      <td>{reportGT.total_horas_manana.substring(0, 5)}</td>
                      <td>{reportGT.hora_inicio_tarde.substring(0, 5)}</td>
                      <td>{reportGT.hora_termino_tarde.substring(0, 5)}</td>
                      <td>{reportGT.total_horas_tarde.substring(0, 5)}</td>
                      <td>{reportGT.total_horas.substring(0, 5)}</td>
                    </tr>
                  ))
                ) : (
                    <tr>
                      <td className="text-center ps-1" colSpan={12}>No se encontraron reports asociados</td>
                    </tr>
                )}
                </tbody>
              </table>
            </div>
            <div className="row row-cols-lg-2">
              <div className="col">
                <div className="d-flex justify-content-lg-between mb-3">
                  <h5 className="mb-0">Detalle Operador</h5>
                  <span className="d-inline-flex align-items-center">
                    <label className="form-label mb-0 me-2">Horas Trabajadas:</label>
                    <span className="fw-bold text-orange">{data.horas_trabajadas_cantidad}</span>
                  </span>
                </div>
                <div className="table-responsive border rounded mb-4">
                  <table className="table table-hover table-sm mb-0">
                    <thead>
                      <tr className="table-active border-bottom">
                        <th className="col-6 ps-2">Descripción</th>
                        <th className="col-2 pe-2">Detalle</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="ps-2">Horas Incluidas Operador</td>
                        <td className="pe-2">{data.horas_incluidas_operador}</td>
                      </tr>
                      <tr>
                        <td className="ps-2">Horas Adicionales</td>
                        <td className="pe-2">{data.horas_adicionales_operador}</td>
                      </tr>
                      <tr>
                        <td className="ps-2">Valor Horas Adicional (UF)</td>
                        <td className="pe-2">{data.valor_hora_adicional_uf_operador}</td>
                      </tr>
                      <tr>
                        <td className="ps-2">Horas Adicionales Domingos y Festivos</td>
                        <td className="pe-2">{data.horas_adicionales_domingos_festivos_operador}</td>
                      </tr>
                      <tr>
                        <td className="ps-2">Valor Horas Adicional (UF)</td>
                        <td className="pe-2">{data.valor_hora_adicional_domingos_festivos_uf_operador}</td>
                      </tr>
                      <tr>
                        <td className="ps-2">Valor UF a Fecha de Facturación</td>
                        <td className="pe-2"><small className="text-secondary">$</small> {Number(data.valor_uf_fecha_facturacion).toLocaleString('es-ES', { useGrouping: true })}</td>
                      </tr>
                      <tr>
                        <td className="ps-2">Valor Total Horas Adicionales</td>
                        <td className="pe-2"><small className="text-secondary">$</small> <span className="text-orange">{Number(data.valor_total_horas_adicionales_operador).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.')}</span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col">
                <h5 className="mb-3">Detalle Grúa</h5>
                <div className="table-responsive border rounded">
                  <table className="table table-hover table-sm mb-0">
                    <thead>
                      <tr className="table-active border-bottom">
                        <th className="col-6 ps-2">Descripción</th>
                        <th className="col-2 pe-2">Detalle</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="ps-2">Horas Incluidas Grúa</td>
                        <td>{data.horas_incluidas_grua}</td>
                      </tr>
                      <tr>
                        <td className="ps-2">Horas Adicionales</td>
                        <td>{data.horas_adicionales_grua}</td>
                      </tr>
                      <tr>
                        <td className="ps-2">Valor Horas Adicional (UF)</td>
                        <td>{data.valor_hora_adicional_uf_grua}</td>
                      </tr>
                      <tr>
                        <td className="ps-2">Valor UF a Fecha de Facturación</td>
                        <td><small className="text-secondary">$</small> {Number(data.valor_uf_fecha_facturacion).toLocaleString('es-ES', { useGrouping: true })}</td>
                      </tr>
                      <tr>
                        <td className="ps-2">Valor Total Horas Adicionales</td>
                        <td><small className="text-secondary">$</small> <span className="text-orange">{Number(data.valor_total_horas_adicionales_grua).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.')}</span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <ul className="nav nav-tabs card-header-tabs mx-auto">
                  <li className="nav-item">
                    <a className={`nav-link ${activeTab === 'valores' ? 'nav-link active' : 'nav-link'}`} onClick={() => handleTabClick('valores')}>Valores</a>
                  </li>
                  <li className="nav-item">
                    <a className={`nav-link ${activeTab === 'documentos' ? 'nav-link active' : 'nav-link'}`} onClick={() => handleTabClick('documentos')}>Documentos</a>
                  </li>
                </ul>
              </div>
              <div className="card-body p-0">
                <div className="tab-content">
                  <div className={`tab-pane p-3 ${activeTab === 'valores' ? 'active' : ''}`}>
                    <div className="row">
                      <div className="col-lg-8">
                        <h5 className="mb-3">Detalle</h5>
                        <ul className="list-group">
                          {estadoPagoServiciosGT && estadoPagoServiciosGT.length > 0 && estadoPagoServiciosGT.map((servicioGT, index) => (
                            <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                              <span>
                                <input 
                                  className="form-check-input me-2" 
                                  type="checkbox" 
                                  id={"check_" + index}
                                  checked={checkboxStates[index] || false}
                                  onChange={(e) => toggleCheckbox(
                                      index, 
                                      servicioGT.id_servicio, 
                                      servicioGT.glosa_servicio !== null && servicioGT.glosa_servicio !== '' ? (
                                        servicioGT.glosa_servicio
                                      ) : (
                                        servicioGT.id_equipo === 0 ? (
                                          servicioGT.nombre_maquinaria + " - " + servicioGT.tipo_arriendo
                                        ) : (
                                          servicioGT.nombre_maquinaria + " - " + servicioGT.nombre_equipo + " - " + servicioGT.tipo_arriendo
                                        )
                                      ),
                                      Number(servicioGT.valor_total).toLocaleString('es-ES', { useGrouping: true }).replace(/[\.,]/g, ''))
                                    }
                                />
                                <label className="form-label mb-0" htmlFor={"check_" + index}>
                                  {
                                    servicioGT.glosa_servicio !== null && servicioGT.glosa_servicio !== '' ? (
                                      servicioGT.glosa_servicio
                                    ) : (
                                      servicioGT.id_equipo === 0 ? (
                                        servicioGT.nombre_maquinaria + " - " + servicioGT.tipo_arriendo
                                      ) : (
                                        servicioGT.nombre_maquinaria + " - " + servicioGT.nombre_equipo + " - " + servicioGT.tipo_arriendo
                                      )
                                    )
                                  }&nbsp;
                                  {/* (Valor UF: <span className="fw-bold text-dark">{Number(servicioGT.valor_unitario_neto).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.')}</span>) */}
                                  (Valor UF: <span className="fw-bold text-dark">{servicioGT.valor_unitario_neto}</span>)
                                </label>
                              </span>
                              <span className="label"><small className="text-secondary">$ </small>{Number(servicioGT.valor_total).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.')}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="col-lg-4">
                        <h5 className="mb-3">Totales</h5>
                        <ul className="list-group">
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            <span className="form-label mb-0">Total Neto</span>
                            <span className="label"><small className="text-secondary">$ </small>{Number(dataTotales.total_neto).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.') || ""}</span>
                          </li>
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            <span className="form-label mb-0">IVA</span>
                            <span className="label"><small className="text-secondary">$ </small>{Number(dataTotales.iva).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.') || ""}</span>
                          </li>
                          <li className="list-group-item">
                            <div className="d-flex justify-content-between align-items-center mb-2">
                              <span className="form-label mb-0">Descuentos</span>
                              <span className="label"><small className="text-secondary"> </small><span className="text-red">- $ {Number(dataTotales.otros_descuentos).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.') || ""}</span></span>
                            </div>
                            <div className="border rounded table-active bg-body-secondary py-1 px-2">
                              <small>{data.detalle_otros_descuentos || ""}</small>
                            </div>
                          </li>
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            <span className="form-label mb-0"><span className="fw-bold text-dark">Total Facturado</span></span>
                            <span className="label"><small className="text-secondary">$ </small><span className="fs-5 text-dark">{Number(dataTotales.total).toLocaleString('es-ES', { useGrouping: true }).replace(/,/g, '.') || ""}</span></span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className={`tab-pane ${activeTab === 'documentos' ? 'active' : ''}`}>
                    <div className="d-flex justify-content-lg-between align-items-center p-3">
                      <h5 className="mb-0">Documentos Asociados</h5>
                      <div className="d-inline-flex gap-2">
                        <button type="button" className="btn btn-outline-dark btn-sm" onClick={handleAgregarFila}>
                          <i className="bi bi-plus-circle"></i> Agregar
                        </button>
                        <button type="button" className="btn btn-dark btn-sm" onClick={(e) => handleConfirmaDocumentos(e, filas)}>
                          <i className="bi bi-floppy"></i> Guardar
                        </button>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-sm table-hover mb-0">
                        <thead>
                          <tr className="table-active border-top border-bottom">
                            <th className="ps-3">Tipo Documento</th>
                            <th>Número</th>
                            <th className="pe-3">Fecha</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                        {estadoPagoDocumentosGT.length > 0 ? (
                          estadoPagoDocumentosGT.map((documento, index) => (
                            <tr key={index}>
                              <td className="ps-3">{documento.tipo_documento}</td>
                              <td>{documento.detalle}</td>
                              <td>{documento.fecha_detalle}</td>
                              <td className="pe-3"></td>
                            </tr>
                          ))
                        ) : (
                          <tr></tr>
                        )}
                        {filas.map((fila) => (
                          <tr key={fila.id}>
                            <td className="ps-3">
                              <select className="form-select form-select-sm" value={fila.id_tipo_documento} onChange={(e) => handleInputChange(fila.id, 'id_tipo_documento', e.target.value)}>
                                <option value="">Seleccione Tipo de Documento</option>
                                {tiposDocumentos.map((tipo_documento, index) => (
                                  <option key={index} value={tipo_documento.id_tipo_documento}>{tipo_documento.tipo_documento}</option>
                                ))}
                              </select>
                            </td>
                            <td>
                              <input
                                className="form-control form-control-sm"
                                type="text"
                                value={fila.detalle}
                                onChange={(e) =>
                                  handleInputChange(fila.id, 'detalle', e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <input
                                className="form-control form-control-sm"
                                type="date"
                                value={fila.fecha}
                                onChange={(e) =>
                                  handleInputChange(fila.id, 'fecha', e.target.value)
                                }
                              />
                            </td>
                            <td className="pe-3">
                              <button className="btn btn-outline-danger btn-sm" onClick={() => handleEliminarFila(fila.id)}>
                                <i className="bi bi-trash3"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {dataLogin.id_perfil === 1 && (
              <div className="d-inline-flex flex-lg-row gap-2">
                <button className="btn btn-outline-danger" onClick={(event) => handleModificaEstado(data, '2')}><i className="bi bi-dash-circle"></i> Anular</button>
                <button className="btn btn-yellow" onClick={(event) => handleModificaEstado(data, '3')}><i className="bi bi-check-circle"></i> Facturar</button>
              </div>
            )}
            {/* {dataLogin.id_perfil === 1 && (
              <select className="form-select form-select-width" name="id_estado_pago_estado" value={estadoPagoEstado} onChange={(event) => handleModificaEstado(data, event.target.value)}>
                {estadosPagoEstado.map((estado) => (
                  <option key={estado.id_estado_pago_estado} value={estado.id_estado_pago_estado}>{estado.estado_pago_estado}</option>
                ))}
              </select>
            )} */}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="d-flex" style={styles.mainContainer}>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={modalStyles}
        contentLabel="Modal"
        className="modal"
      >
        {modalContent}
      </Modal>
      <Modal
        isOpen={isSecondModalOpen}
        onRequestClose={closeSecondModal}
        style={modalStyles}
        contentLabel="Modal"
        className="modal"
      >
        {secondModalContent}
      </Modal>
      <SideMenu dataLogin={dataLogin} />
      <div className="w-100">
        <Header dataLogin={dataLogin} />
        <div className="container-fluid">
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
            {data.map((columnData, index) => (
              <div className="col" key={index}>
                <div className="card">
                  <div className="card-header d-flex justify-content-between align-items-center border-bottom-0 p-3 bg-white">
                    <h4>{columnData.title}</h4>
                    {columnData.title === "Estado de pago" ? (
                      <div className="btn-group">
                        <button type="button" className="btn btn-yellow dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                          <i className="bi-plus-circle"></i> Agregar
                        </button>
                        <ul className="dropdown-menu dropdown-menu-end">
                          <li>
                            <button className="dropdown-item" type="button" onClick={() => handleAddButtonClick("Estado de Pago BH")}>Bomba Hormigón</button>
                            </li>
                          <li>
                            <button className="dropdown-item" type="button" onClick={() => handleAddButtonClick("Estado de Pago GH")}>Grúa Hidráulica</button>
                          </li>
                          <li>
                            <button className="dropdown-item" type="button" onClick={() => handleAddButtonClick("Estado de Pago GT")}>Grúa Torre</button>
                          </li>
                        </ul>
                      </div>
                    ) : (
                      <button className="btn btn-yellow" key={index} onClick={() => handleAddButtonClick(columnData.title)}>
                        <i className="bi-plus-circle"></i> Agregar
                      </button>
                    )}
                  </div>
                  <div className="card-search px-3 pb-3">
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="bi bi-search"></i>
                      </span>
                      <input type="text" className="form-control" placeholder="Cliente..." onChange={(e) => handleFiltrosDashboard(e, columnData.title)}/>
                    </div>
                  </div>
                  <div className="card-body card-body-fh pt-0">
                  {columnData.cards.length > 0 ? (
                    columnData.cards.map((cardData, cardIndex) => (
                      columnData.title === "Cotización" ? (
                        <div
                          className="card-inside c-pointer"
                          key={cardIndex}
                          onClick={() => handleCardClick(columnData.title, cardData)}
                        >
                          <div className="card-title border-bottom bg-light p-2 mb-1">
                            <span className="label">{cardData.nombre_cliente}</span>
                          </div>
                          <div className="d-flex justify-content-between align-items-center px-2 mb-1">
                            <label className="form-label mb-0">Rut</label>
                            <span className="label">{`${formatearRut(cardData.rut_cliente)}`}</span>
                          </div>
                          <div className="d-flex justify-content-between align-items-center px-2 mb-1">
                            <label className="form-label mb-0">Folio</label>
                            <span className="label">{cardData.folio}</span>
                          </div>
                          <div className="d-flex justify-content-between align-items-center px-2 mb-1">
                            <label className="form-label mb-0">Obra</label>
                            <span className="label">{cardData.obra}</span>
                          </div>
                          <div className="d-flex justify-content-between align-items-center px-2 pb-1">
                            <label className="form-label mb-0">Fecha Cotización</label>
                            <span className="label">{cardData.fecha_cotizacion}</span>
                          </div>
                        </div>
                      ) : columnData.title === "Requerimientos" ? (
                        <div
                          className="card-inside c-pointer"
                          key={cardIndex}
                          onClick={() => handleCardClick(columnData.title, cardData)}
                        >
                          <div className="card-title border-bottom bg-light p-2 mb-1">
                            <span className="label">{cardData.nombre_cliente}</span>
                          </div>
                          <div className="d-flex justify-content-between align-items-center px-2 mb-1">
                            <label className="form-label mb-0">Rut</label>
                            <span className="label">{`${formatearRut(cardData.rut_cliente)}`}</span>
                          </div>
                          <div className="d-flex justify-content-between align-items-center px-2 mb-1">
                            <label className="form-label mb-0">Obra</label>
                            <span className="label">{cardData.nombre_obra}</span>
                          </div>
                          <div className="d-flex justify-content-between align-items-center px-2 mb-1">
                            <label className="form-label mb-0">Solicitante</label>
                            <span className="label">{cardData.nombre_contacto}</span>
                          </div>
                          <div className="d-flex justify-content-between align-items-center px-2 mb-1">
                            <label className="form-label mb-0">Fecha Requerimiento</label>
                            <span className="label">{cardData.fecha_requerimiento}</span>
                          </div>
                        </div>
                      ) : (
                        <div key={cardIndex}>
                          {cardData.bh && cardData.bh.length > 0 ? (
                            <div>
                              {cardData.bh.map((bhItem, bhIndex) => (
                                <div
                                  className="card-inside c-pointer"
                                  key={bhIndex}
                                  onClick={() => handleCardClick(columnData.title + " BH", bhItem)}
                                >
                                  <div className="card-title d-inline-flex justify-content-lg-between border-bottom bg-light p-2 mb-1">
                                    <span className="label">{bhItem.nombre_cliente}</span>
                                    <span className="label">BH-{bhItem.id_estado_pago_bh}</span>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center px-2 mb-1">
                                    <label className="form-label mb-0">Rut</label>
                                    <span className="label">{`${formatearRut(bhItem.rut_cliente)}`}</span>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center px-2 mb-1">
                                    <label className="form-label mb-0">Folio</label>
                                    <span className="label">{bhItem.folio}</span>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center px-2 mb-1">
                                    <label className="form-label mb-0">Obra</label>
                                    <span className="label">{bhItem.nombre_obra}</span>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center px-2 pb-1">
                                    <label className="form-label mb-0">Fecha Estado Pago</label>
                                    <span className="label">{bhItem.fecha_estado_pago}</span>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="no-data-message"></div>
                          )}
                          
                          {cardData.gh && cardData.gh.length > 0 ? (
                            <div className="mt-3">
                              {cardData.gh.map((ghItem, ghIndex) => (
                                <div
                                  className="card-inside c-pointer"
                                  key={ghIndex}
                                  onClick={() => handleCardClick(columnData.title + " GH", ghItem)}
                                >
                                  <div className="card-title d-inline-flex justify-content-lg-between border-bottom bg-light p-2 mb-1">
                                    <span className="label">{ghItem.nombre_cliente}</span>
                                    <span className="label">GH-{ghItem.id_estado_pago_gh}</span>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center px-2 mb-1">
                                    <label className="form-label mb-0">Rut</label>
                                    <span className="label">{`${formatearRut(ghItem.rut_cliente)}`}</span>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center px-2 mb-1">
                                    <label className="form-label mb-0">Folio</label>
                                    <span className="label">{ghItem.folio}</span>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center px-2 mb-1">
                                    <label className="form-label mb-0">Obra</label>
                                    <span className="label">{ghItem.nombre_obra}</span>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center px-2 pb-1">
                                    <label className="form-label mb-0">Fecha Estado Pago</label>
                                    <span className="label">{ghItem.fecha_estado_pago}</span>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="no-data-message"></div>
                          )}
                          
                          {cardData.gt && cardData.gt.length > 0 ? (
                            <div className="mt-3">
                              {cardData.gt.map((gtItem, gtIndex) => (
                                <div
                                  className="card-inside c-pointer"
                                  key={gtIndex}
                                  onClick={() => handleCardClick(columnData.title + " GT", gtItem)}
                                >
                                  <div className="card-title d-inline-flex justify-content-lg-between border-bottom bg-light p-2 mb-1">
                                    <span className="label">{gtItem.nombre_cliente}</span>
                                    <span className="label">GT-{gtItem.id_estado_pago_gt}</span>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center px-2 mb-1">
                                    <label className="form-label mb-0">Rut</label>
                                    <span className="label">{`${formatearRut(gtItem.rut_cliente)}`}</span>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center px-2 mb-1">
                                    <label className="form-label mb-0">Folio</label>
                                    <span className="label">{gtItem.folio}</span>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center px-2 mb-1">
                                    <label className="form-label mb-0">Obra</label>
                                    <span className="label">{gtItem.nombre_obra}</span>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center px-2 pb-1">
                                    <label className="form-label mb-0">Fecha Estado Pago</label>
                                    <span className="label">{gtItem.fecha_estado_pago}</span>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="no-data-message"></div>
                          )}
                        </div>
                      )
                    ))
                  ) : (
                    <div className="alert alert-warning" role="alert">
                      <i className="bi bi-exclamation-triangle me-2"></i>
                      No se ha encontrado información.
                    </div>
                  )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const modalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

const styles = {
  mainContainer: {
    height: "100vh",
  },
};

export default Dashboard;
